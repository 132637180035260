<script>
  import GoogleTagManagerService from '../services/tag-manager.js';
  import LineruTagsService from '../services/lineru-tags.js';
  import UsefulInformationService from '../services/useful-information.js';
  import Images from './Images.svelte';
  import { environment } from '../environment.js';
  import { page } from '@roxi/routify';
  import { onMount } from 'svelte';

  export let pageName;

  const usefulInformationService = new UsefulInformationService();

  let btns = [
    {
      label: 'Si',
      response: 'yes',
      disabled: false,
      selected: false
    },
    {
      label: 'No',
      response: 'no',
      disabled: false,
      selected: false
    }
  ];
  let responseSetted = false;

  function acceptance(btn) {
    const tag = LineruTagsService.usefulInformation(pageName, btn.response);

    usefulInformationService.response(pageName, btn.response);
    GoogleTagManagerService.setTag(tag);

    btn.selected = true;
    btns = btns.map(btn => ({
      ...btn,
      disabled: true
    }));

    responseSetted = true;
  }

  onMount(() => {
    const responses = usefulInformationService.getResponses();
    const didResponse = responses !== null ? responses.filter(v => v.pageName === $page.title) : [];

    if(didResponse.length > 0) {
      responseSetted = true;
      btns = btns.map(btn => ({
        ...btn,
        selected: btn.response === didResponse[0].response,
        disabled: true
      }));
    }
  });
</script>

<div class="pb-14 pb-md-16 pt-14 pt-md-26">
  <div class="container">
    <div class="d-flex justify-content-center align-items-center flex-col flex-md-row">
      <h3 class="mr-0 mr-md-18 mb-2 mb-md-0">
        ¿Te fue útil la información?
      </h3>
      <div class="d-flex">
        {#each btns as btn}
          <div
            class="btn-container mt-md-0"
            class:mt-14={responseSetted}
            class:mt-4={!responseSetted}
          >
            <button
              on:click="{() => acceptance(btn)}"
              class="btn py-3 bdr-1 bdr-solid px-6 t-weight-light bdr-radius-1 mx-2 bgr-primary-500:hover bgr-primary-500:active t-white:hover"
              class:t-white={btn.selected}
              class:bgr-primary-500={btn.selected}
              class:bdr-primary-500={btn.selected}
              class:bgr-transparent={!btn.selected}
              class:bdr-primary-500:hover={!btn.selected}
              class:disabled={btn.disabled}
              aria-labelledby
            >
              {btn.label}
            </button>
            <div
              class="btn-container__icon t-center"
              class:btn-container__icon--selected={btn.selected}
              class:btn-container__icon--yes={btn.response === 'yes'}
              class:btn-container__icon--no={btn.response === 'no'}
            >
              {#if btn.response === 'yes' && btn.selected}
                <Images src='/images/iconos/otros/corazon.svg' alt=""/>
              {:else if btn.selected}
                <Images src='/images/iconos/otros/triste.svg' alt=""/>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    </div>
    <p
      class="t-size-2 t-center mb-0 mt-4 copy"
      class:copy--visible={responseSetted}
    >
      Tu respuesta es muy importante gracias por darnos tu opinión.
    </p>
  </div>
</div>

<style>
  .btn-container {
    position: relative;
    transition: margin-top .3s ease-out;
  }

  .btn-container__icon {
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: bottom .3s ease-out;
    left: 50%;
  }

  .btn-container__icon--selected {
    opacity: 1;
    bottom: 54px;
  }

  .btn-container__icon--yes {
    margin-left: -20px;
  }

  .btn-container__icon--no {
    margin-left: -12px;
  }

  .copy {
    opacity: 0;
    transition: opacity .3s ease-out;
  }

  .copy--visible {
    opacity: 1;
  }</style>
