<script>
  import { onMount, onDestroy } from 'svelte';
  import { environment } from '../environment.js';

  export let sections = [];
  export let rtl;

  let lineWidth = 0;

  function validateScroll() {
    lineWidth = window.scrollY * 100 / (document.body.offsetHeight - window.innerHeight);

    for (let i = 0; i < sections.length; i++) {
      sections[i].active = window.scrollY >= sections[i].topPos && window.scrollY <= (sections[i].targetHeight + sections[i].topPos);
    }
  }

  onMount(() => {
    setTimeout(() => {
      sections = sections.map((section, i) => {
          const target = document.getElementById('time-line-item-' + i) || document.body;
          const topPos = target.getBoundingClientRect().top + window.pageYOffset - 450;

          return {
            ...section,
            active: false,
            topPos: topPos,
            targetHeight: target.offsetHeight
          }
        });
    }, 2000);
  });

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<svelte:window on:scroll={validateScroll} />

<ul class="time-line">
  {#each sections as section, i (i)}
    <li
      id={'time-line-item-' + i}
      class="time-line__item mb-26"
      class:time-line__item--ref-left={(i + (rtl ? 0 : 1)) % 2 !== 0}
      class:active={section.active}
    >
      {#if (i + (rtl ? 0 : 1)) % 2 === 0}
        <div class="time-line__item__img px-4 my-lg-14 pb-6 pb-lg-0">
          <img src={section.imgSrc} alt="image for {section.title}" />
        </div>
      {/if}
      <div class="time-line__item__text">
        <h3 class="time-line__item__text__h pt-lg-14">{section.title}</h3>
        <h3 class="time-line__item__text__h">{section.subtitle}</h3>
        <div class="time-line__item__text__p">{@html section.text}</div>
      </div>
      {#if (i + (rtl ? 0 : 1)) % 2 !== 0}
        <div class="time-line__item__img">
          <img src={section.imgSrc} alt="image for {section.title}" />
        </div>
      {/if}
      <div
        class="time-line__item__background d-none d-md-block h-100"
        class:time-line__item__background--left={(i + (rtl ? 0 : 1)) % 2 !== 0}
        style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
      ></div>
    </li>
  {/each}
</ul>

<style>
  .time-line .time-line__item__background {
    width: 50%;
    top: 0;
    position: absolute;
    left: -164px;
    z-index: -1;
  }

  .time-line .time-line__item__background--left {
    left: auto;
    right: -200px;
    height: 110%;
    top: -17px;
  }

  .time-line__item__img img {
    width: auto;
    max-width: 100%;
  }</style>
