<script>
  import { onMount, createEventDispatcher } from 'svelte';

  export let buttons = [];
  let lineWidth = 0;
  const dispatch = createEventDispatcher();

  function validateScroll() {
    lineWidth = window.scrollY * 100 / (document.body.offsetHeight - window.innerHeight);

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].active = window.scrollY >= buttons[i].topPos && window.scrollY <= (buttons[i].targetHeight + buttons[i].topPos);
    }
  }

  function navigateTo(topPos, index, sectionName) {
    scroll({
      top: topPos + 5,
      behavior: 'smooth'
    });

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].active = i == index;
    }

    dispatch('section-name', {
      sectionName
    });
  }

  function sanitize(name){
    return name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  onMount(() => {
    setTimeout(() => {
      calculateButtonsPos();
    }, 1000);
  });

  function calculateButtonsPos() {
    buttons = buttons.map(btn => {
      const target = document.getElementById(btn.idTarget) || document.body;
      const topPos = target.getBoundingClientRect().top + window.pageYOffset - 100;

      return {
        ...btn,
        active: false,
        topPos: topPos,
        targetHeight: target.offsetHeight
      }
    });
  }
</script>

<svelte:window on:scroll={validateScroll} />

<div class="vertical-navbar d-none d-md-block bgr-white">
  <div class="w-100 h-2 bgr-gray-100 relative">
    <div class="h-100 absolute bgr-brand-2" style="width: {lineWidth}%"></div>
  </div>
  <div class="container d-flex">
    {#each buttons as button, index }
      <button
        on:click = {navigateTo(button.topPos, index, sanitize(button.content))}
        class = "btn bdr-gray-100 bgr-brand-4:hover w-100 t-primary-500"
        class:bgr-white = {!button.active}
        class:t-weight-semibold = {button.active}
        class:bgr-brand-4 = {button.active}
        aria-labelledby
      >
        {button.content}
      </button>
    {/each}
  </div>
</div>

<style>
  .vertical-navbar {
    z-index: 1;
    position: sticky;
    border-bottom: 1px solid rgb(var(--gray-100));
    top: 61px;
  }

  @media (min-width: 992px) {
    .vertical-navbar {
      top: 95px;
    }
  }</style>
