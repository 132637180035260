<script>
  export let valid = "true";
  export const warning = "false";
  export let value = "";
  export let id = "input-1";
  export let label = "";
  export let errormessage = "";
  export let helpmessage = "";
  export let placeholder = "";
  export let maxlength = "";
  export let darktheme = false;
  export let touched = false;
</script>

<div class="input-text-container" class:input-text-container--dark={darktheme}>
  <input
    class:invalid={!valid && touched}
    type="text"
    {placeholder}
    {maxlength}
    bind:value
    on:input
    required
    {id}
    on:blur={() => (touched = true)}
  />
  <span class="bar" />
  <label for={id}>{label}</label>
  {#if !valid}
    <svg class="invalid-icon" fill="currentColor" viewBox="0 0 24 24"
      ><path
        d="M13 13h-2V7h2m0 10h-2v-2h2M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z"
      /></svg
    >
  {/if}
  <div class="msg">
    {#if helpmessage}
      <span class="help-text">{helpmessage}</span>
    {/if}
    {#if errormessage && !valid && touched}
      <span class="error-msg">{errormessage}</span>
    {/if}
  </div>
</div>

<style lang="css">
 .input-text-container {
  position: relative;
  padding-top: 23px;
}
.input-text-container input {
  font-size: var(--input-text-font-size);
  padding: 15px 10px 15px 10px;
  display: block;
  width: 100%;
  border: 0;
  font-family: var(--input-text-font-family);
  border-bottom: 1px solid var(--input-text-color);
  background-color: var(--input-text-background-color);
  outline: none;
  color: var(--input-text-color);
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 0;
  margin: 0;
}
.input-text-container input::placeholder {
  color: var(--input-text-placeholder-color);
}
.input-text-container input:invalid ~ label {
  color: var(--input-text-color);
}
.input-text-container input:focus ~ label, .input-text-container input:valid ~ label {
  color: var(--input-text-focus-color);
}
.input-text-container input.invalid {
  padding-right: 29px;
}
.input-text-container input.valid ~ label {
  color: var(--input-text-valid-color);
}
.input-text-container input.warning ~ label {
  color: var(--input-text-warning-color);
}
.input-text-container input.invalid ~ label {
  color: var(--input-text-invalid-color);
}
.input-text-container input.disabled, .input-text-container input:disabled, .input-text-container input[readonly] {
  pointer-events: none;
  color: var(--input-text-disabled-color);
}
.input-text-container input.disabled ~ label, .input-text-container input:disabled ~ label, .input-text-container input[readonly] ~ label, .input-text-container input.disabled ~ .msg .help-text, .input-text-container input:disabled ~ .msg .help-text, .input-text-container input[readonly] ~ .msg .help-text {
  color: var(--input-text-disabled-color);
}
.input-text-container label {
  position: absolute;
  font-size: var(--input-text-label-font-size);
  font-family: var(--input-text-font-family);
  transition: 0.2s ease color;
  left: 0;
  top: 0;
  display: inline-block;
}
.input-text-container .bar {
  position: relative;
  display: block;
  width: 100%;
}
.input-text-container .bar:before, .input-text-container .bar:after {
  content: '';
  height: 1px;
  bottom: 0;
  width: 0;
  position: absolute;
  background: var(--input-text-focus-color);
  transition: 0.2s ease width;
}
.input-text-container .bar:before {
  left: 50%;
}
.input-text-container .bar:after {
  right: 50%;
}
.input-text-container input:focus ~ .bar:before, .input-text-container input:focus ~ .bar:after, .input-text-container input:valid ~ .bar:before, .input-text-container input:valid ~ .bar:after {
  width: 50%;
}
.input-text-container input.valid ~ .bar:before, .input-text-container input.valid ~ .bar:after {
  width: 50%;
  background-color: var(--input-text-valid-color);
}
.input-text-container input.warning ~ .bar:before, .input-text-container input.warning ~ .bar:after {
  width: 50%;
  background-color: var(--input-text-warning-color);
}
.input-text-container input.invalid ~ .bar:before, .input-text-container input.invalid ~ .bar:after {
  width: 50%;
  background-color: var(--input-text-invalid-color);
}
.input-text-container .msg {
  height: auto;
  min-height: 1.5rem;
}
.input-text-container .error-msg, .input-text-container .help-text {
  display: inline-block;
  padding-top: 5px;
  font-family: var(--input-text-font-family);
  font-size: var(--input-text-helper-font-size);
}
.input-text-container .help-text {
  color: var(--input-text-color);
}
.input-text-container input.invalid ~ .msg .help-text {
  display: none;
}
.input-text-container input.warning ~ .msg .help-text {
  display: none;
}
.input-text-container input.valid ~ .msg .help-text {
  color: var(--input-text-valid-color);
}
.input-text-container .error-msg {
  display: none;
}
.input-text-container input.invalid ~ .msg .error-msg {
  display: inline-block;
  color: var(--input-text-invalid-color);
}
.input-text-container input.invalid ~ .invalid-icon {
  display: block;
}
.input-text-container input.warning ~ .msg .error-msg {
  display: inline-block;
  color: var(--input-text-warning-color);
}
.input-text-container--dark input {
  background-color: var(--input-text-dark-background-color);
  border-bottom: 1px solid var(--input-text-dark-color);
  color: var(--input-text-dark-color);
}
.input-text-container--dark input:invalid ~ label {
  color: var(--input-text-dark-color);
}
.input-text-container--dark input:focus ~ label, .input-text-container--dark input:valid ~ label {
  color: var(--input-text-dark-focus-color);
}
.input-text-container--dark input.valid ~ label {
  color: var(--input-text-valid-color);
}
.input-text-container--dark input.warning ~ label {
  color: var(--input-text-warning-color);
}
.input-text-container--dark input.invalid ~ label {
  color: var(--input-text-invalid-color);
}
.input-text-container--dark .bar:before, .input-text-container--dark .bar:after {
  background: var(--input-text-dark-focus-color);
}
.input-text-container--dark .help-text {
  color: var(--input-text-dark-color);
}
.input-text-container .invalid-icon {
  display: none;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 38px;
  right: 6px;
  color: var(--input-text-invalid-color);
}</style>
