<script>
  import { mdiChevronRight } from '@mdi/js';
  import { goto } from '@roxi/routify';
  import GoogleTagManagerService from '../../../services/tag-manager.js';
  import LineruTagsService from '../../../services/lineru-tags.js';
  import { environment } from '../../../environment.js';

  function goToLineruWorld() {
    $goto('/iniciar-sesion');
    GoogleTagManagerService.setTag(LineruTagsService.lineruWorld().start);
  }

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-2">
  <div class="container fold-2__container py-10 py-md-26 d-grid grid-12 gap-6 align-items-center">
    <div class="col-12 col-md-6" data-aos="fade-right">
      <picture aria-hidden="true">
        <source srcset="/images/mundo-lineru/chica-1.webp" type="image/webp">
        <img loading="lazy" class="img-fluid d-block lazyload" data-src={resolveRoute('/images/mundo-lineru/chica-1.png')} alt="">
      </picture>
    </div>
    <div class="col-12 col-md-6">
      <p class="mb-12">
        <strong>
          Créditos
        </strong>
      </p>
      <h3 class="t-h2 t-gold mb-6">
        Ten un crédito a la mano
      </h3>
      <p class="t-size-5 mb-5">
        Tu cupo de crédito es la llave que te abre nuestros beneficios. Es rápido, simple y siempre disponible.
      </p>
      <a
        class="btn bgr-white t-primary-500 t-brand-2:hover pl-0 pr-5 t-left align-self-start"
        href="/"
        on:click|preventDefault={goToLineruWorld}
        role="button"
      >
        Comenzar en Mundo Lineru
        <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
          <path fill="currentColor" d={mdiChevronRight} />
        </svg>
      </a>
    </div>
  </div>

  <div
    class="fold-2__background"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
  <div
    class="fold-2__shape-1 d-none d-md-block"
    style="background-image: url('{resolveRoute("/images/formas/ola-circulo.svg")}')"
  ></div>
  <div
    class="fold-2__shape-2 d-none d-md-block"
    style="background-image: url('{resolveRoute("/images/formas/circulos.svg")}')"
  ></div>
</div>

<style>
  .fold-2 {
    position: relative;
  }

  .fold-2__container {
    position: relative;
    z-index: 1;
  }

  .fold-2__background {
    position: absolute;
    top: 21%;
    left: 0;
    width: 39%;
    height: 58%;
    z-index: 0;
  }

  .fold-2__shape-1,
  .fold-2__shape-2 {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
  }

  .fold-2__shape-1 {
    transform:scaleX(-1);
    top: 0;
    right: 11%;
    width: 97px;
    height: 81px;
  }

  .fold-2__shape-2 {
    top: -34%;
    right: 0;
    width: 453px;
    height: 903px;
  }

  @media (min-width: 992px) {
    .fold-2__shape-1 {
      top: 5%;
    }
  }</style>
