<script>
	import Images from './../../../../components/Images.svelte';
    import { mdiCheck } from '@mdi/js';
    import { fade } from 'svelte/transition';
    import Icon from '../../../../components/Icon.svelte';
    import lineruIcons from '../../../../constants/lineru-icons.js';
    import { goto } from '@roxi/routify';
    import GoogleTagManagerService from '../../../../services/tag-manager';
    import LineruTagsService from '../../../../services/lineru-tags';

    function goToComboPlantWantThis() {
        $goto('/iniciar-sesion');
        GoogleTagManagerService.setTag(LineruTagsService.insurances().comboPlanWantThis);
    }

</script>

<style>
    .icon {
        width: 16px;
        height: 16px;
    }</style>
<section in:fade="{{duration: 800 }}">
    <h3>
        Plan Accidentes + Vida
    </h3>
    <br>
    <h3>
        Protección de accidentes
    </h3>
    <p>
        Sura te pagará <strong>$150.000 pesos</strong>.
    </p>
    <p>
        En caso de que por <strong>accidente o enfermedad (no previa al momento de comprar el seguro) te hospitalicen en una clínica u hospital</strong> por más de <strong>48 horas</strong>. Se cubre <strong>un evento durante la vigencia</strong>.
    </p>
    <br>
    <h3>
        Protección de servicios médicos
    </h3>
    <p>
        Sura te pagará <strong>$200.000 pesos</strong>.
    </p>
    <p>
        En caso de que <strong>por accidente (no previo al momento de comprar el seguro) requieras atención de emergencia</strong> en una institución médica, para prestarte <strong>alguno de los siguientes servicios</strong>:
    </p>
    <div class="d-flex flex-col">
        <div class="d-flex flex-row ">
            <svg class="t-success-300 icon mt-1" viewBox="0 0 24 24">
                <path fill="currentColor" d="{mdiCheck}" />
            </svg>
            <p class="ml-2 flex-1 mb-1"><strong>Exámenes de laboratorio.</strong></p>
        </div>
        <div class="d-flex flex-row ">
            <svg class="t-success-300 icon mt-1" viewBox="0 0 24 24">
                <path fill="currentColor" d="{mdiCheck}" />
            </svg>
            <p class="ml-2 flex-1 mb-1">
                <strong>Ayudas diagnósticas</strong> (Rayos X, ecografías, escanografías,
                resonancias magnéticas).
            </p>
        </div>
        <div class="d-flex flex-row ">
            <svg class="t-success-300 icon mt-1" viewBox="0 0 24 24">
                <path fill="currentColor" d="{mdiCheck}" />
            </svg>
            <p class="ml-2 flex-1 mb-1">
                <strong>Elementos de curación</strong> (vendajes, vendas de yeso, cabestrillos, collares cervicales, férulas, tobilleras, rodilleras, muñequeras).
            </p>
        </div>
        <div class="d-flex flex-row ">
            <svg class="t-success-300 icon mt-1" viewBox="0 0 24 24">
                <path fill="currentColor" d="{mdiCheck}" />
            </svg>
            <p class="ml-2 flex-1 mb-1">
                <strong>Medicamentos</strong>, derechos de sala de cirugía y anestesia,
                habitación y alimentación en clínica u hospital.
            </p>
        </div>
        <div class="d-flex flex-row ">
            <svg class="t-success-300 icon mt-1" viewBox="0 0 24 24">
                <path fill="currentColor" d="{mdiCheck}" />
            </svg>
            <p class="ml-2 flex-1 mb-1">
                <strong>Fisioterapia</strong> y honorarios médicos u odontológicos.
            </p>
        </div>
    </div>
    <p class="my-3 flex-1">
        Se cubre <strong>un evento durante la vigencia</strong>.
    </p>
    <br>
    <h3>
        Protección de vida
    </h3>
    <p>
        Sura le pagará $3.500.000 pesos a tus familiares o beneficiarios de ley en caso de tu fallecimiento.
    </p>
    <h3>
        Vigencia
    </h3>
    <p>
        Mensual o semestral.
    </p>
    <br>

    <div class="d-grid grid-1 grid-md-2 gap-3 mb-12">
        <div class="bgr-white p-6 bdr-radius-1 shadow-3 d-flex flex-col">
            <Images imgClass="w-8 h-8 d-block mb-6 lazyload"
                src='/images/iconos/oscuro/credit-card.svg' alt=""/>
            <h6 class="t-weight-semibold t-gold">
                Costo mensual
            </h6>
            <p class="mb-6 flex-grow">
                $9.500 pesos o lo equivalente a $317 pesos al día.
            </p>
            <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' alt=""/>
        </div>
        <div class="bgr-white p-6 bdr-radius-1 shadow-3 d-flex flex-col">
            <Images imgClass="w-8 h-auto d-block mb-6 lazyload"
                src='/images/iconos/oscuro/credit-card.svg' alt=""/>
            <h6 class="t-weight-semibold t-gold">
                Costo semestral
            </h6>
            <p class="mb-6 flex-grow">
                $30.000 pesos o lo equivalente a $167 pesos al día.
            </p>
            <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' alt=""/>
        </div>
    </div>
    <a
        class="d-inline-block btn btn-lg btn--shadow bgr-primary-500 t-white:hover bgr-brand-2:active t-white"
        on:click|preventDefault={goToComboPlantWantThis}
        href="/"
        role="button"
    >
        Quiero este seguro
        <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
    </a>
</section>
