<script>
	import Images from './../../../../components/Images.svelte';
  import {
    mdiChevronRight,
    mdiAlertCircle,
    mdiCheckCircle
  } from '@mdi/js';
  import { goto } from '@roxi/routify';
  import LineruTagsService from '../../../../services/lineru-tags';
  import GoogleTagManagerService from '../../../../services/tag-manager';
  import { environment } from '../../../../environment.js';

  function goToVideo() {
    $goto('/acerca-de-tu-credito/nuestros-requisitos/ventajas-de-la-cuenta');
    GoogleTagManagerService.setTag(LineruTagsService.ourRequisits().advantagesOfBankAccount);
  }

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-4">
  <div class="container fold-4__container pt-10 pt-md-16 pt-lg-26">
    <h2 class="mb-4 mb-md-8 t-center">
      Sobre tu cuenta bancaria
    </h2>

    <p class="mb-10 mb-md-14 t-center t-size-5">
      Para recibir un desembolso la cuenta que uses debe estar a tu nombre.
      <br class="d-none d-md-inline">
      Solo te la pediremos la primera vez.
    </p>

    <div class="d-grid grid-12 gap-6 align-items-start">
      <div class="col-12 col-md-4 bgr-gray-100 py-8 px-6 bdr-radius-1 t-left t-size-4 d-flex flex-col h-100">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <strong>
            Cuentas que sí usamos
          </strong>
          <Images imgClass="w-10 h-auto -mr-8 -mr-sm-10 lazyload" src='/images/formas/olas.svg' aria-hidden="true" alt=""/>
        </div>
        <p class="mb-4 flex-grow">
          Usamos la mayoría. Lo importante es que esté a tu nombre.
        </p>
        <ul class="mb-0 pl-0">
          <li class="d-flex align-items-center" data-aos="fade-left" data-aos-duration="500">
            <svg class="w-4 h-auto mr-3 t-success-500" height="100%" viewBox="0 0 24 24" >
              <path fill="currentColor" d={mdiCheckCircle} />
            </svg>
            <span>
              Cuentas de ahorro
            </span>
          </li>
          <li class="d-flex align-items-center mb-0" data-aos="fade-left" data-aos-duration="800">
            <svg class="w-4 h-auto mr-3 t-success-500" height="100%" viewBox="0 0 24 24" >
              <path fill="currentColor" d={mdiCheckCircle} />
            </svg>
            <span>
              Cuentas corrientes
            </span>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-4 bgr-gray-100 py-8 px-6 bdr-radius-1 t-left t-size-4 d-flex flex-col h-100">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <strong>
            Cuentas que no usamos
          </strong>
          <Images imgClass="w-10 h-auto -mr-8 -mr-sm-10 lazyload" src='/images/formas/olas.svg' aria-hidden="true" alt=""/>
        </div>
        <p class="mb-4 flex-grow">
          Por la seguridad de tu dinero hay cuentas con las que no trabajamos:
        </p>
        <ul class="mb-0 pl-0">
          <li class="d-flex align-items-center" data-aos="fade-left" data-aos-duration="500">
            <svg class="w-4 h-auto mr-3 t-error-300" height="100%" viewBox="0 0 24 24" >
              <path fill="currentColor" d={mdiAlertCircle} />
            </svg>
            <span>
              Billeteras virtuales
            </span>
          </li>
          <li class="d-flex align-items-center mb-0" data-aos="fade-left" data-aos-duration="800">
            <svg class="w-4 h-auto mr-3 t-error-300" height="100%" viewBox="0 0 24 24"  >
              <path fill="currentColor" d={mdiAlertCircle} />
            </svg>
            <span>
              Cuentas de pensiones
            </span>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-4 bgr-gray-100 pt-8 pb-4 px-6 bdr-radius-1 t-left t-size-4 d-flex flex-col h-100">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <strong>
            ¿No tienes una cuenta?
          </strong>
          <Images imgClass="w-10 h-auto -mr-8 -mr-sm-10 lazyload" src='/images/formas/olas.svg' aria-hidden="true" alt=""/>
        </div>
        <p class="mb-2 flex-grow">
          No hay ningún problema. Cuando pidas tu cupo de crédito, puedes abrir una gratis con el Banco de Bogotá que es nuestro aliado.
        </p>
        <a
          class="btn t-primary-500 t-brand-2:hover bgr-transparent pl-0 pr-5 t-left align-self-start"
          on:click|preventDefault={goToVideo}
          href="/"
          role="button"
        >
          Ver ventajas de la cuenta
          <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiChevronRight} />
          </svg>
        </a>
      </div>
    </div>
  </div>
  <div class="fold-4__bg-patron" style="background-image: url({resolveRoute("/images/patrones/patron-marca.png")})"></div>
</div>

<style>
  .fold-4 {
    position: relative;
    z-index: 0;
  }

  .fold-4__container {
    position: relative;
    z-index: 1;
  }

  .fold-4__bg-patron {
    width: 20%;
    height: 121%;
    top: 6%;
    position: absolute;
    left: 0;
  }</style>
