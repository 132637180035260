<script>
  import Icon from '../../../components/Icon.svelte';
  import lineruIcons from '../../../constants/lineru-icons.js';

  export let content;
  export let headerImageUrl;
</script>

<div class="container d-grid grid-12 gap-6 py-8">
  <div class="col-12 col-lg-8">
    <picture aria-hidden="true">
      <source srcset="{headerImageUrl}.webp" type="image/webp">
      <img loading="lazy" class="d-block img-fluid lazyload mb-5" src="{headerImageUrl}.jpg" alt="">
    </picture>

    {@html content}
  </div>
  <div class="d-none d-lg-block col-4">
    <div class="sticky bgr-gray-100 seo-card p-5">
      <h3 class="t-primary-500 mb-4">
        Créditos online para lo que quieras
      </h3>
      <p class="t-weight-semibold t-size-4">
        Solicita tu cupo de crédito en solo 15 minutos y recibe el dinero en máximo un día hábil.
      </p>
      <p class="mb-5">
        Sin trámites, filas, codeudores, anticipos y cargos ocultos.
      </p>
      <a
        class="btn btn-lg btn--shadow bgr-primary-500 bgr-brand-2:active t-white t-white:hover w-100 t-left d-flex align-items-center"
        href="/calcula-tu-credito"
        role="button"
      >
        <span class="flex-grow">
          Calcula tu crédito
        </span>
        <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
      </a>
    </div>
  </div>
</div>

<style>
  .seo-card {
    top: 95px
  }</style>
