<script>
    import { onMount, tick } from 'svelte';
    import { beforeUpdate } from 'svelte';
    import { fly, fade } from 'svelte/transition';
    import { createEventDispatcher } from 'svelte';
    import { mdiCheck  } from '@mdi/js';
    import LogoLineru from './logos/Lineru.svelte';
    import LogoLineruMobile from './logos/LineruMobile.svelte';

    export let value = 0; // show number percentage loading
    export let spinner = false; // show spinner loading

    let init = false;
    let complete = false;

    const dispatch = createEventDispatcher();
    const body = document.getElementsByTagName('body')[0];

    const loading = () => body.style.overflow = 'hidden';

    const load = () => {
        body.style.overflow = 'visible';
        init = false;
    }

    onMount(() => {
        init = true;
    });

    beforeUpdate(() => {
        if (value >= 100) {
            complete = true;
            value = 100;
            setTimeout(() => {
                load();
            }, 1000);
        }
    });
</script>

{#if init}
<div
    class="preloader d-block h-100vh w-100vw"
    class:bgr-brand-2={!complete}
    class:bgr-brand-1={complete}
    on:outroend="{()=> dispatch('load')}">

    <div
        class="preloader-content d-flex h-100 w-100 flex-col justify-content-between">
        <div class="d-flex align-items-center" title="Lineru">
            <div class="d-none d-lg-inline-block">
                <LogoLineru fillColorVar="--white" width="128px" height="100%" />
            </div>

            <div class="d-inline-block d-lg-none">
                <LogoLineruMobile fillColorVar="--white" width="25px" height="100%" />
            </div>
        </div>

        <div>
            {#if spinner}
                {#if complete}
                    <svg style="width:60px;height:60px; margin:10px 0" viewBox="0 0 24 24">
                        <path fill="#fff" d="{mdiCheck}" />
                    </svg>
                {:else}
                    <div class="lds-ripple d-inline-block"><div></div><div></div></div>
                {/if}
            {:else}
                <h2 class="t-white">
                    {value}%
                </h2>
            {/if}

            <p class="t-white">
                Más cerca <br>
                de tu mundo.
            </p>
        </div>
    </div>

</div>
{/if}

<span class="d-none qwerty"></span>

<style>
    .preloader {
        position: fixed;
        display: block;
        left: 0;
        top: 0;
        box-sizing: border-box;
        overflow: hidden;
        transition: background-color .5s linear;
        box-sizing: border-box;
        z-index: 60;
    }

    .preloader-content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15vh 32px;
    }

    .lds-ripple {
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }

        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }</style>
