<script>
  import { mdiHome } from '@mdi/js';
  import '@zinobe-co/components/breadcrumb';
  import { environment } from '../../../../environment.js';

  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: '',
      url: '/',
    },
    {
      text: 'Acerca de tu crédito',
      url: '/acerca-de-tu-credito',
    },
    {
      text: 'Cuánto cuesta',
    }
  ];

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div
  class="pt-28 -mt-28 pt-lg-24 -mt-lg-24 fold-1"
  style="background-image: url('{resolveRoute("/images/patrones/patron-7.png")}'), linear-gradient(135deg, #6916E6 0%, #A45AF4 49.72%, #C85AF9 100%);"
>
  <div class="t-size-2 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2 w-11/12 d-none d-md-block mx-auto t-white">
    <znb-breadcrumb data={breadcrumbData} />
  </div>

  <div class="container">
    <div class="d-grid grid-12 gap-5 py-14 py-lg-16 py-xl-30 t-white align-items-center">
      <div class="col-12 col-md-6 align-self-end">
        <h1 class="t-h2 mb-0">
          Las cuentas claras
          <br class="d-none d-sm-inline">
          desde el comienzo hasta
          <br class="d-none d-sm-inline">
          el final
        </h1>
      </div>

      <div class="col-12 col-md-6">
        <p class="t-size-3 t-sm-size-5 mb-0">
          Queremos que estés tranquilo. Estos son los únicos costos que existen en Lineru, explicados de forma transparente. Acá no hay asteriscos, letra pequeña, ni costos ocultos.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  .fold-1 {
    background-repeat: repeat;
  }

  @media (min-width: 576px) {
    .fold-1 {
      background-repeat: no-repeat, repeat;
      background-size: cover;
      background-position-y: -55px, center;
      background-position-x: center;
    }
  }</style>
