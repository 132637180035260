<script>
  import LogoLineruMobile from '../../components/logos/LineruMobile.svelte';
  import LogoLineru from '../../components/logos/Lineru.svelte';
  import Icon from '../../components/Icon.svelte';
  import lineruIcons from '../../constants/lineru-icons.js';
  import { goto } from '@roxi/routify';
  import { environment } from '../../environment';

  let fillLogoVar = '--brand-1';

  function resolveRoute(url) {
    return environment.assets + url;
  }

  function goToLogin() {
    $goto('/iniciar-sesion');
  }

</script>

<div class="d-flex align-items-center p-5 px-lg-8 px-xl-14 py-lg-8">
  <a
    class="d-flex align-items-center"
    href="/"
    title="Lineru">
    <div class="d-none d-lg-inline-block">
      <LogoLineru fillColorVar={fillLogoVar} width="128px" height="100%" />
    </div>

    <div class="d-inline-block d-lg-none">
      <LogoLineruMobile
        fillColorVar={fillLogoVar}
        width="23px"
        height="100%"
      />
    </div>
  </a>
</div>
<div class="container-img d-flex d-flex align-items-center mt-2 mt-lg-0">
  <img
    loading="lazy"
    class="d-block img-mobile lazyload"
    data-src={resolveRoute("/images/session-expired/patrones.png")}
    alt="patrones">
</div>

<div class="mx-lg-26 px-8 px-lg-30 py-lg-12">
  <div class="d-grid grid-12 gap-5">
    <div class="col-12 col-lg-6 pt-6 pt-lg-10">
      <h1 class="mt-lg-4 mb-6 mb-lg-10">
        ¡Hola!
      </h1>

      <p class="col-6 t-size-5">
        Para continuar en Lineru, inicia sesión nuevamente.
      </p>

      <a
        class="btn btn--shadow bgr-primary-500 t-white:hover bgr-brand-2:active t-white btn-lg mt-6 d-none d-lg-inline-block"
        href="/"
        on:click|preventDefault={goToLogin}
        role="button">
        Iniciar sesión
        <Icon
          width="16"
          height="11"
          classList="ml-3 -mr-3 t-yellow"
          path={lineruIcons.arrow}
        />
      </a>
    </div>

    <div class="col-12 col-lg-6 d-flex mt-2 mb-8 justify-content-center">
      <img
        loading="lazy"
        class="img-fluid d-block lazyload"
        data-src={resolveRoute("/images/session-expired/inactividad.png")}
        alt="Inactividad">
    </div>

    <div class="col-12 d-inline-block d-lg-none">
      <button
        class="d-flex btn btn-lg bgr-primary-500 bgr-brand-2:active t-white ml-0 ml-md-6 ml-lg-6 px-6 t-left align-items-center w-100 w-auto"
        on:click|preventDefault={goToLogin}
        role="button"
      >
        <span class="flex-grow">
          Iniciar sesión
        </span>
        <Icon width="23" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
      </button>
    </div>
  </div>
</div>


<style>

.container-img .img-mobile {
  width: 100%;
  height: auto;
}

@media screen and (max-width:768px) {
  .container-img {
    margin-top: 1.5 rem;
    overflow: hidden;
  }

  .container-img .img-mobile {
    width: 1360px;
    height: auto;
    margin-top: -35px;
  }
}</style>
