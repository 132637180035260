<script>
  import {
    mdiChevronRight,
    mdiMinus
  } from '@mdi/js';
  import { environment } from '../../../environment.js';

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-7">
  <div class="container fold-7__container py-10 py-md-26 d-grid grid-12 gap-6 align-items-center">
    <div class="col-12 col-md-6" data-aos="fade-right">
      <picture aria-hidden="true">
        <source srcset="/images/mundo-lineru/chico-5.webp" type="image/webp">
        <img loading="lazy" class="img-fluid d-block lazyload" data-src={resolveRoute('/images/mundo-lineru/chico-5.png')} alt="">
      </picture>
    </div>
    <div class="col-12 col-md-6">
      <p class="mb-12">
        <strong>
          Puntos
        </strong>
      </p>
      <h3 class="t-h2 t-gold mb-6">
        Puntos por beneficios
      </h3>
      <p class="t-size-5">
        La mayoría de acciones en Mundo Lineru te dan puntos. Con ellos subes de nivel y puedes acceder a mejores beneficios.
      </p>
      <ul class="t-size-5 pl-0 mb-5">
        <li class="d-flex align-items-center">
          <svg class="w-4 h-auto mr-3" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiMinus} />
          </svg>
          <span>
            Acumula con cada desembolso
          </span>
        </li>
        <li class="d-flex align-items-center">
          <svg class="w-4 h-auto mr-3" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiMinus} />
          </svg>
          <span>
            Acumula por pagar a tiempo
          </span>
        </li>
        <li class="d-flex align-items-center">
          <svg class="w-4 h-auto mr-3" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiMinus} />
          </svg>
          <span>
            Acumula invitando amigos
          </span>
        </li>
      </ul>
      <a
        class="btn bgr-white t-primary-500 t-brand-2:hover pl-0 pr-5 t-left align-self-start"
        href="/mundo-lineru/puntos"
        role="button"
      >
        Mira los beneficios de acumular
        <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
          <path fill="currentColor" d={mdiChevronRight} />
        </svg>
      </a>
    </div>
  </div>

  <div
    class="fold-7__background"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
  <div
    class="fold-7__shape d-none d-md-block"
    style="background-image: url('{resolveRoute("/images/formas/ola-circulo.svg")}')"
  ></div>
</div>

<style>
  .fold-7 {
    position: relative;
  }

  .fold-7__container {
    position: relative;
    z-index: 1;
  }

  .fold-7__background {
    position: absolute;
    top: 21%;
    left: 0;
    width: 39%;
    height: 58%;
    z-index: 0;
  }

  .fold-7__shape {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    transform:scaleX(-1);
    top: 0;
    right: 11%;
    width: 97px;
    height: 81px;
    z-index: 0;
  }</style>
