<script>
  import { mdiHome } from '@mdi/js';
  import '@zinobe-co/components/breadcrumb';
  import { tick } from 'svelte';
  import { onMount } from 'svelte';
  import { environment } from '../../../environment.js';

  export let title;
  export let description;

  let breadcrumbData = [];

  onMount(async () => {
    await tick();

    breadcrumbData = [
      {
        iconPath: mdiHome,
        text: '',
        url: '/',
      },
      {
        text: title,
      }
    ];
  });

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div
  class="pt-28 -mt-28 pt-lg-24 -mt-lg-24 fold-1"
  style="background-image: url('{resolveRoute("/images/patrones/patron-3.jpg")}')"
>
  <div class="t-size-2 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2 w-11/12 d-none d-md-block mx-auto">
    <znb-breadcrumb data={breadcrumbData} />
  </div>

  <div class="container">
    <div class="d-grid grid-12 gap-5 py-14 py-lg-16 py-xl-30 align-items-center">
      <div class="col-12 col-md-6">
        <h1 class="t-h2 mb-0">
          {title}
        </h1>
      </div>

      <div class="col-12 col-md-6">
        <p class="t-size-3 t-sm-size-5 mb-0">
          {description}
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  .fold-1 {
    background-attachment: fixed;
  }</style>
