<script>
  import {
    mdiHome,
    mdiClose,
    mdiLoading
  } from '@mdi/js';
  import '@zinobe-co/components/password';
  import '@zinobe-co/components/breadcrumb';
  import '@zinobe-co/components/modal';
  import Icon from '../../../components/Icon.svelte';
  import lineruIcons from '../../../constants/lineru-icons.js';
  import NewPassService from '../_services/new-pass.js';
  import { onMount } from 'svelte';
  import { goto } from '@roxi/routify';
  import { environment } from '../../../environment.js';
  import Validate from '../../../services/validate.js';
  import GoogleTagManagerService from '../../../services/tag-manager.js';
  import LineruTagsService from '../../../services/lineru-tags.js';
  import { load as loadRecaptcha } from 'recaptcha-v3';

  export let hash = '';

  const newPassService = new NewPassService();
  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: '',
      url: '/',
    },
    {
      text: 'Nueva contraseña',
    },
  ];
  let passwordFieldIsValid = true;
  let passwordErrorMessage;
  let passwordValue;
  let persentagueValid = 0;
  let touched;
  let showConfirmChangeModal = false;
  let disabledNewPassBtn = false;

  let conditions = [
    { description: '',
      valid: 'false',
      regexp: /^.{1,}$/ },
    {
      description: '',
      valid: 'false',
      regexp: /^(?=.*[\d])(?=.*[a-zA-Z]).+$/,
    },
    {
      description: '',
      valid: 'false',
      regexp: /([-+=_!@#$%^&*.,;:'\"<>/?`~\[\]\(\)\{\}\\\|\s])/,
    },
    {
      description: '',
      valid: 'false',
      regexp: /^(?=.*[A-Z])(?=.*[a-z]).+$/,
    },
  ];

  let rules = [
    {
      description: 'Mínimo 10 caracteres',
      regexp: /^.{10,}$/,
      valid: false
    },
    {
      description: 'Mínimo 6 caracteres alfabéticos',
      regexp: /.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*/,
      valid: false
    },
    {
      description:  'Mínimo 2 caracteres numéricos',
      regexp: /.*\d.*\d.*/,
      valid: false
    },
    {
      description: 'Mínimo 2 caracteres especiales como: *_ / .',
      regexp: /.*[!@#$%^&*()_+|~={}:'<>?,./\[\]-].*[!@#$%^&*()_+|~={}:'<>?,./\[\]-]/,
      valid: false
    },
    {
      description: 'Minúsculas y mayúsculas',
      regexp: /(?=.*[a-z])(?=.*[A-Z])|(?=.*[A-Z])(?=.*[a-z])/,
      valid: false
    }
  ];

  function handlerPasswordField(e) {
    let result = conditions.map(condition => {
      condition.valid = condition.regexp.test(e.target.value).toString();
      return condition;
    });

    passwordValue = e.target.value;

    if (!Validate.required(passwordValue)) {
      passwordFieldIsValid = false;
      passwordErrorMessage = 'Ingresa tu contraseña';
    } else {
      conditions = result;
      persentagueValid = (conditions.filter(c => c.valid === 'true').length * 100) / 4;
      passwordFieldIsValid = true;
    }
  }

  function handlerSendNewPass() {
    touched = true;

    const data = {
      hash,
      password: passwordValue,
      recaptcha_token: '',
      recaptcha_event: "new_password_event"
    }

    disabledNewPassBtn = true;

    handlerPasswordField({ target: { value: passwordValue } });

    if (passwordFieldIsValid) {
      loadRecaptcha(environment.recaptchaSiteKey).then((recaptcha) => {
        recaptcha.execute('submit').then((token) => {
          newPassService.newPass({
            ...data,
            recaptcha_token: token
          })
            .then(() => {
              manageConfirmModal(true);

              GoogleTagManagerService.setTag(LineruTagsService.newPass().submitForm);
              disabledNewPassBtn = false;
            })
            .catch(() => {
              disabledNewPassBtn = false;
              // console.log('Snackbar message');
            });
        });
      });
    }
  }

  onMount(() => {
    if (hash !== '') {
      newPassService.validateHash({
        hash
      })
        .then((resp) => {
          const isValid = resp;
          if (!isValid) {
            $goto('/cambiar-contrasena');
          }
        });
    } else {
      $goto('/olvide-contrasena');
    }

    document.addEventListener('keypress', e => {
      if(e.charCode === 13) {
        handlerSendNewPass();
      }
    });

    loadRecaptcha(environment.recaptchaSiteKey, {
      explicitRenderParameters: {
        badge: 'bottomleft'
      }
    });
  });

  function manageConfirmModal(state) {
    showConfirmChangeModal = undefined;
    showConfirmChangeModal = state;
  }
</script>

<style>
  .new-pass__breadcrumbs {
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .loading-icon {
    animation: mdi-spin 1s infinite linear;
  }

  @keyframes mdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }</style>

<div
  class="new-pass__breadcrumbs w-11/12 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2 t-size-1
  d-none d-md-block">
  <znb-breadcrumb data={breadcrumbData} />
</div>

<div class="pt-8 pt-md-2 pb-8 flex-grow d-flex align-items-center">
  <div class="container d-grid grid-12">
    <div
      class="col-start-1 col-sm-start-2 col-md-start-3 col-lg-start-4 col-12
      col-sm-10 col-md-8 col-lg-6">
      <h1 class="t-h2 mb-12">Nueva contraseña</h1>

      <znb-password
        id="new-password"
        oninput={handlerPasswordField}
        label="Crea tu contraseña"
        valid={passwordFieldIsValid}
        errormessage={passwordErrorMessage}
        helpmessage=""
        placeholder="Escribe tu nueva contraseña"
        type="set"
        {touched}
        rules={rules}
        class="d-block mb-8" />
      <button
        class="btn btn--shadow bgr-primary-500 bgr-brand-2:active t-white btn-lg w-100 w-sm-auto d-flex t-left align-items-center"
        role="button"
        class:disabled={disabledNewPassBtn}
        on:click={handlerSendNewPass}
        aria-labelledby>
        <span class="flex-grow">Guardar contraseña</span>
        {#if !disabledNewPassBtn}
          <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
        {:else}
          <svg class="w-6 h-auto t-yellow ml-3 -mr-3 loading-icon" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiLoading} />
          </svg>
        {/if}
      </button>
    </div>
  </div>
</div>

<znb-modal show={showConfirmChangeModal} dismissible="true" size="sm">
  <div class="d-flex align-items-start">
    <h3 class="t-center mb-6 flex-grow-1 w-100 pr-8 -mr-6">
      Cambio exitoso de contraseña
    </h3>
    <button
      on:click="{() => manageConfirmModal(false)}"
      class="p-0 bgr-transparent bdr-none -mr-6 t-primary-500 t-primary-500:hover"
      aria-label="Cerrar modal"

    >
      <svg class="w-6 h-auto" viewBox="0 0 24 24">
        <path fill="currentColor" height="100%" d={mdiClose} />
      </svg>
    </button>
  </div>
  <p class="t-center mb-8 t-size-4">
    Tu nueva contraseña se ha guardado satisfactoriamente.
  </p>
  <div class="t-center d-flex justify-content-center">
    <a
      class="d-inline-block btn btn-lg btn--shadow bgr-primary-500 bgr-brand-2:active t-white t-white:hover w-100 w-sm-auto d-flex t-left align-items-center"
      role="button"
      href="/iniciar-sesion"
      aria-labelledby>
      <span class="flex-grow">
        Iniciar sesión
      </span>
      <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
    </a>
  </div>
</znb-modal>
