<script>
  import Icon from '../../../../components/Icon.svelte';
  import Images from '../../../../components/Images.svelte';
  import lineruIcons from '../../../../constants/lineru-icons.js';
  import { environment } from '../../../../environment.js';

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="relative">
  <div class="container fold-2-container pt-10 pb-2 pt-md-14 pb-md-4 pt-lg-24 pb-lg-6">
    <h2 class="mb-8 t-left t-sm-center">
      Recibe ideas exclusivas de Mundo Lineru
    </h2>

    <p class="t-center mb-12 t-size-4 t-left t-sm-center">
      Todo nuestro contenido tiene un objetivo: ayudarte a construir un mejor perfil de crédito y mejorar
      <br class="d-none d-lg-inline">
      tus finanzas personales.
    </p>

    <div class="d-grid grid-12 gap-6">
      <div class="col-12 col-sm-6 col-lg-3 bgr-white shadow-3 p-8" data-aos="fade-right" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/informacion.svg' alt="Explicaciones financieras simples"/>
        <h3 class="t-h5 mb-0">
          Explicaciones financieras simples
        </h3>
      </div>

      <div class="col-12 col-sm-6 col-lg-3 bgr-white shadow-3 p-8" data-aos="fade-right" data-aos-duration="500">
        <Images lass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/calculadora.svg' alt="Asesorías para cumplir tus metas"/>
        <h3 class="t-h5 mb-0">
          Asesorías para cumplir tus metas
        </h3>
      </div>

      <div class="col-12 col-sm-6 col-lg-3 bgr-white shadow-3 p-8" data-aos="fade-left" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/bombillo.svg' alt="Ideas y sugerencias de expertos"/>
        <h3 class="t-h5 mb-0">
          Ideas y sugerencias de expertos
        </h3>
      </div>

      <div class="col-12 col-sm-6 col-lg-3 bgr-white shadow-3 p-8" data-aos="fade-left" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/ticket.svg' alt="Invitaciones a sesiones exclusivas"/>
        <h3 class="t-h5 mb-0">
          Invitaciones a sesiones exclusivas
        </h3>
      </div>

      <div class="col-12 t-center pt-6 pb-10">
        <a
          class="d-inline-block btn btn-lg btn--shadow bgr-primary-500 t-white:hover bgr-brand-2:active t-white"
          href="/iniciar-sesion"
          role="button"
        >
          Lo quiero todo
          <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
        </a>
      </div>
    </div>
  </div>

  <div
    class="fold-2-background"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
</div>

<style>
  .fold-2-background {
    position: absolute;
    top: auto;
    bottom: 20px;
    right: 0;
    width: 100%;
    height: 18%;
    z-index: 0;
  }

  .fold-2-container {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 576px) {
    .fold-2-background {
      width: 50%;
      height: 60%;
      top: 0;
      bottom: auto;
      right: 0;
    }
  }</style>
