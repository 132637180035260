<script>
  import Images from '../../components/Images.svelte';
  import { environment } from '../../environment';

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="bgr-white py-0 py-lg-6">
  <div class="fold-2 t-size-3">
    <div class="container t-center">
      <div class="fold-2__bg-image-1">
        <Images imgClass="d-block w-18 lazyload" src='/images/formas/ola-circulo.svg' alt=""/>
      </div>

      Con Lineru conoces el dinero fácil, rápido y seguro.
      <br class="d-none d-md-inline">
      Si tienes una conexión a Internet no necesitas nada más. Aplicas en 10 minutos. Nosotros te
      <br class="d-none d-md-inline">
      damos una respuesta en 15 minutos. Y recibes tu dinero en un día hábil.
      <br class="d-none d-md-inline">
      ¡Eso es todo! A medida que pagas liberas tu cupo nuevamente.
      <div class="fold-2__bg-image-2">
        <Images imgClass="d-block w-18 lazyload" src='/images/formas/equis.svg' alt=""/>
      </div>
    </div>
  <div class="fold-2__bg-patron" style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"></div>
  </div>
</div>

<style>
  .fold-2 {
    padding: 115px 0 88px;
    position: relative;
  }

  .fold-2__bg-patron {
    width: 45%;
    height: 47%;
    bottom: 0;
    position: absolute;
    left: 0;

  }

  .fold-2__bg-image-1 :global(img )  {
    width: 70px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 40px;
  }

  .fold-2__bg-image-2 :global(img) {
    width: 20px;
    height: auto;
    position: absolute;
    left: 50%;
    bottom: 48px;
  }

  @media (min-width: 992px) {
    .fold-2 {
      font-size: 1.4rem;
    }

    .fold-2__bg-patron {
      width: 34%;
    }
  }

  @media (min-width: 1200px) {
    .fold-2 {
      padding: 180px 0 120px;
      font-size: 1.75rem;
    }

    .fold-2__bg-image-1 :global(img) {
      width: 100px;
      top: 60px;
    }

    .fold-2__bg-image-2 :global(img){
      width: 30px;
      bottom: 55px;
    }
  }</style>
