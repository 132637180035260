<script>
  import { mdiCardsHeart } from '@mdi/js';
  import Icon from '../../components/Icon.svelte';
  import lineruIcons from '../../constants/lineru-icons.js';
  import { goto } from '@roxi/routify';
  import GoogleTagManagerService from '../../services/tag-manager.js';
  import LineruTagsService from '../../services/lineru-tags.js';
  import Images from '../../components/Images.svelte';

  function goToLineruWorld() {
    $goto('/mundo-lineru');
    GoogleTagManagerService.setTag(LineruTagsService.home().selectLineruWorld);
  }

</script>

<div id="aboutLineruWorld" class="d-flex justify-content-center overflow-hidden py-6 py-md-8">
  <div class="fold-3 w-12/12 w-sm-11/12">
    <div class="container pt-10 pb-10 pb-md-16 t-white t-center">
      <div class="d-inline-block bgr-yellow t-size-2 bdr-radius-1 px-3 py-1 mb-6 t-brand-5">
        <div class="d-flex w-100 align-items-center">
          <svg class="w-4 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiCardsHeart} />
          </svg>
          <span>
            Nuevo en Lineru
          </span>
        </div>
      </div>

      <h2 class="mb-5 d-none d-md-block">
        Mundo Lineru
        <br>
        Acelera tu vida financiera
      </h2>
      <!-- mobile -->
      <h2 class="mb-5 d-block d-md-none">
        Descubre Mundo Lineru
      </h2>
      <p class="mb-10 d-none d-md-block">
        En Mundo Lineru te prestamos dinero rápido. Y te ayudamos a mejorar tu perfil de crédito, para que
        <br class="d-none d-md-inline">
        puedas acceder a créditos más grandes y cumplir todas tus metas.
      </p>
      <p class="mb-10 d-block d-md-none">
        Estas son todas las puertas que te abre tener tu cuenta y tu cupo de crédito en Lineru.
      </p>
      <div class="d-grid grid-12 gap-6 t-left t-brand-5 mb-10">
        <div class="col-12 col-sm-6 col-lg-4 bgr-white p-6 bdr-radius-1 d-flex flex-col" data-aos="fade-right">
          <div class="d-inline-block mb-4">
            <Images imgClass="w-6 h-6 d-block lazyload" width="24" height="24" src='/images/iconos/oscuro/credit-card.svg' alt=""/>
          </div>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-none d-md-block">
            Ten tu crédito a la mano.
          </h3>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-block d-md-none">
            Tienes dinero a la mano
          </h3>
          <p class="mb-6 flex-grow">
            Este el cupo de crédito rápido, simple y siempre disponible que ya conoces. Con él tienes acceso a todos los beneficios de Mundo Lineru.
          </p>
          <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' aria-hidden="true" alt=""/>
        </div>

        <!-- <div class="col-12 col-sm-6 col-lg-4 bgr-white p-6 bdr-radius-1 d-flex flex-col" data-aos="fade-down">
          <div class="d-inline-block mb-4">
            <Images imgClass="w-6 h-6 d-block lazyload" src='/images/iconos/oscuro/celular.svg' width="24" height="24" alt="24"/>
          </div>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-none d-md-block">
            Recarga tu celular y TV.
          </h3>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-block d-md-none">
            Recargas tu celular y TV
          </h3>
          <p class="mb-6 flex-grow">
            Haz todas tus recargas con cualquier operador a través de Mundo Lineru. Nosotros te prestamos, siempre con 0% de interés.
          </p>
          <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' aria-hidden="true" alt=""/>
        </div> -->

        <!-- <div class="col-12 col-sm-6 col-lg-4 bgr-white p-6 bdr-radius-1 d-flex flex-col" data-aos="fade-left">
          <div class="d-inline-block mb-4">
            <Images imgClass="w-8 h-auto d-block lazyload" width="32" height="28" src='/images/iconos/oscuro/alcancia.svg' alt=""/>
          </div>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-none d-md-block">
            Asegura tus ingresos.
          </h3>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-block d-md-none">
            Aseguras tus ingresos
          </h3>
          <p class="mb-6 flex-grow">
            Si un día no puedes trabajar por accidente o enfermedad, junto a Sura te hacemos un pago diario. Y tú pagas menos de $167 pesos al día.
          </p>
          <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' aria-hidden="true" alt=""/>
        </div> -->

        <div class="col-12 col-sm-6 col-lg-4 bgr-white p-6 bdr-radius-1 d-flex flex-col" data-aos="fade-right">
          <div class="d-inline-block mb-4">
            <Images imgClass="w-6 h-6 d-block lazyload" width="24" height="24" src='/images/iconos/oscuro/perfil.svg' alt=""/>
          </div>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-none d-md-block">
            Domina tus finanzas.
          </h3>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-block d-md-none">
            Dominas tus finanzas
          </h3>
          <p class="mb-6 flex-grow">
            Cambia hoy tu vida financiera. Nosotros vamos a mostrarte cómo tener más dinero y cómo lograr que te aprueben los créditos que quieras.
          </p>
          <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' aria-hidden="true" alt=""/>
        </div>

        <!-- <div class="col-12 col-sm-6 col-lg-4 bgr-white p-6 bdr-radius-1 d-flex flex-col" data-aos="fade-up">
          <div class="d-inline-block mb-4">
            <Images imgClass="w-6 h-6 d-block lazyload" width="24" height="24" src='/images/iconos/oscuro/ticket.svg' alt=""/>
          </div>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-none d-md-block">
            Recibe cupones exclusivos.
          </h3>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-block d-md-none">
            Recibes cupones exclusivos
          </h3>
          <p class="mb-6 flex-grow">
            Disfruta todos los meses de nuevas ofertas y descuentos para viajar, comprar ropa y salir a comer. Son más de 10 marcas aliadas.
          </p>
          <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' aria-hidden="true" alt=""/>
        </div> -->

        <div class="col-12 col-sm-6 col-lg-4 bgr-white p-6 bdr-radius-1 d-flex flex-col" data-aos="fade-left">
          <div class="d-inline-block mb-4">
            <Images imgClass="w-6 h-6 d-block lazyload" width="24" height="24" src='/images/iconos/oscuro/puntos.svg' alt=""/>
          </div>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-none d-md-block">
            Acumula puntos Lineru.
          </h3>
          <h3 class="t-h6 t-brand-5 t-weight-semibold d-block d-md-none">
            Acumulas Puntos Lineru
          </h3>
          <p class="mb-6 flex-grow">
            Tus movimientos en Lineru te dan puntos. Y tú los cambias por aumentos de cupo, descuentos en tus créditos y más plazo para pagar.
          </p>
          <Images imgClass="w-8 h-auto lazyload" width="32" height="19" src='/images/formas/zigzag-5.svg' aria-hidden="true" alt=""/>
        </div>
      </div>
      <div class="t-center">
        <a
          class="d-inline-block btn btn-lg btn--shadow bgr-white t-brand-5:hover bgr-gray-200:active t-brand-5"
          href="/"
          on:click|preventDefault={goToLineruWorld}
          role="button"
        >
          Ir a Mundo Lineru
          <Icon width="16" height="11" classList="ml-3 -mr-3 t-brand-2" path={lineruIcons.arrow}></Icon>
        </a>
      </div>
    </div>
  </div>
</div>


<style>
  .fold-3 {
    background: radial-gradient(circle, #857D7B 0%, #322D2D 100%);
  }</style>
