<script>
  import { environment } from '../../../../environment.js';

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-3 mb-0 mb-md-6">
  <img loading="lazy" class="fold-3__bg-image-1 d-none d-lg-block lazyload" data-src={resolveRoute('/images/formas/ola-circulo.svg')} alt="">
  <div class="container fold-3__container">
    <h2 class="mb-4">
      Mira un ejemplo: Dinero sin enredos
    </h2>
    <p class="t-size-3 t-lg-size-5 mb-8">
      Dinero sin enredos es una de nuestras series, en la que te
      <br class="d-none d-lg-inline">
      explicamos conceptos básicos para comenzar, orden e impulsar tu
      <br class="d-none d-lg-inline">
      vida crediticia.
    </p>

    <div class="d-grid grid-12 gap-6">
      <div class="col-12 col-md-9 bgr-gray-300 pb-30"></div>
      <div class="col-3 d-none d-md-block bgr-gray-300 pb-30"></div>
    </div>
  </div>
  <div
    class="fold-3__bg-patron"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
</div>

<style>
  .fold-3 {
    padding: 10px 0 50px;
    position: relative;
  }

  .fold-3__container {
    z-index: 1;
    position: relative;
  }

  .fold-3__bg-patron {
    width: 100%;
    height: 27%;
    bottom: 0;
    position: absolute;
    left: 0;
    z-index: 0;
  }

  .fold-3__bg-image-1 {
    width: 80px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -40px;
  }

  @media (min-width: 576px) {
    .fold-3__bg-patron {
      width: 55%;
    }
  }

  @media (min-width: 992px) {
    .fold-3 {
      font-size: 1.4rem;
      padding: 110px 0 80px;
    }
  }

  @media (min-width: 1200px) {
    .fold-3__bg-image-1 {
      width: 96px;
      margin-left: -48px;
      top: 0;
    }
  }</style>
