<script>
  import { mdiChevronRight } from '@mdi/js';
  import { fly } from 'svelte/transition';
  import { onMount } from 'svelte';
  import { beforeUrlChange } from '@roxi/routify';
  import GoogleTagManagerService from '../../services/tag-manager.js';
  import LineruTagsService from '../../services/lineru-tags.js';
  import { environment } from '../../environment';

  function resolveRoute(url) {
    return environment.assets + url;
  }

  let tab = 1;
  let xBaseSlide = 1;
  let viewWidth = 0;
  let sliderHeight = 0;
  let transDuration = 0;
  let resizeEvent;
  let lazyLoadSupport = false;

  let imagesUrl = {
    dineroLogo: resolveRoute('/images/logos/dinero.png'),
    LaRepublicaLogo: resolveRoute('/images/logos/la-republica.svg'),
    facebookLogo: resolveRoute('/images/logos/facebook.svg'),
    instagramLogo: resolveRoute('/images/logos/instagram.svg'),
    zigzagIllustration: resolveRoute('/images/formas/zigzag-1.svg')
  }

  function selectTab(t) {
    tab = t;

    xBaseSlide = t === 1 ? 1 : -1;

    if (t === 1) {
      GoogleTagManagerService.setTag(LineruTagsService.home().newspaper);
    } else {
      GoogleTagManagerService.setTag(LineruTagsService.home().users);
    }
  };

  function getSliderHeight(viewWidth) {
    if (viewWidth <= 576) {
      sliderHeight = 1100;
    } else if (viewWidth > 576 && viewWidth <= 768) {
      sliderHeight = 895;
    } else if (viewWidth > 768 && viewWidth <= 992) {
      sliderHeight = 422;
    } else if (viewWidth > 992 && viewWidth <= 1200) {
      sliderHeight = 380;
    } else if (viewWidth > 1200) {
      sliderHeight = 327;
    }

    return sliderHeight;
  }

  onMount(() => {
    transDuration = 1000;
    viewWidth = window.innerWidth;
    getSliderHeight(viewWidth);

    resizeEvent = () => {
      viewWidth = window.innerWidth;
      getSliderHeight(viewWidth);
    }

    lazyLoadSupport = 'loading' in HTMLImageElement.prototype;
  });

  $beforeUrlChange(() => {
    transDuration = 0;
    return true;
  });
</script>

<svelte:window on:resize={resizeEvent} />

<div class="fold-7__wrapper z-0 d-flex justify-content-center social-section relative">
  <div class="fold-7 z-10 w-12/12 w-sm-11/12 bgr-gray-100 py-10 py-md-16 relative">
    <div class="container fold-7__container z-10 t-center">
      <h2 class="d-inline-block mb-8 mb-md-10">
        Miles de colombianos ya conocen Lineru
      </h2>
      <div class="t-center mb-8">
        <button
          class="d-inline-block t-primary-500:hover bgr-gray-200:hover bdr-radius-2 bdr-none t-weight-semibold py-1 px-3 mr-2 {tab === 1 ? 't-primary-500 bgr-gray-200' : 't-gray-500 bgr-transparent'}"
          role="button" on:click="{()=>{selectTab(1)}}" aria-labelledby>
          Prensa
        </button>

        <button
          class="d-inline-block t-primary-500:hover bgr-gray-200:hover bdr-radius-2 bdr-none t-weight-semibold py-1 px-3 {tab === 2 ? 't-primary-500 bgr-gray-200' : 't-gray-500 bgr-transparent'}"
          role="button" on:click="{()=>{selectTab(2)}}" aria-labelledby>
          Usuarios
        </button>
      </div>
      <div class="w-100 relative d-flex align-items-center" style="height: {sliderHeight + 'px'};">
        {#if tab === 1}
          <div
            class="col-12 d-grid grid-12 gap-6 absolute h-100"
            in:fly="{{ x: xBaseSlide * -viewWidth, duration: transDuration }}"
            out:fly="{{ x: xBaseSlide * viewWidth, duration: transDuration }}"
          >
            <div class="col-12 col-md-4 bgr-white pt-8 pb-6 px-6 bdr-radius-1 t-left bdr-radius-1 d-flex flex-col">
              <div class="mb-8 t-size-2 t-weight-bold d-flex align-items-center">
                <img loading="lazy" class="w-6 h-6 mr-2 lazyload" width="24" height="24" data-src={imagesUrl.dineroLogo} src={lazyLoadSupport ? imagesUrl.dineroLogo : ''} aria-hidden="true" alt="">
                Dinero
              </div>
              <h3 class="t-h6 mb-3">
                Lineru: la fintech que le presta por internet a estratos 2 y 3
              </h3>
              <p class="mb-3 flex-grow">
                Ampliar el acceso al crédito es una de las banderas de esta plataforma de créditos en línea. Así han llegado a donde otras entidades no llegan.
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <a
                  class="btn bgr-white t-primary-500 t-brand-2:hover pl-0 pr-5 t-left align-self-start"
                  href="https://www.dinero.com/emprendimiento/articulo/como-pedir-prestamos-desde-el-celular-en-colombia/295759"
                  target="_blank"
                  rel="noopener"
                  role="button"
                >
                  Leer artículo
                  <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
                    <path fill="currentColor" d={mdiChevronRight} />
                  </svg>
                </a>
                <img loading="lazy" class="w-10 h-auto -mr-8 -mr-sm-10 lazyload" width="40" height="25" data-src={imagesUrl.zigzagIllustration} src={lazyLoadSupport ? imagesUrl.zigzagIllustration : ''} aria-hidden="true" alt="">
              </div>
            </div>
            <div class="col-12 col-md-4 bgr-white pt-8 pb-6 px-6 bdr-radius-1 t-left bdr-radius-1 d-flex flex-col">
              <div class="mb-8 t-size-2 t-weight-bold d-flex align-items-center">
                <img loading="lazy" class="w-6 h-6 mr-2 lazyload" width="24" height="24" data-src={imagesUrl.LaRepublicaLogo} src={lazyLoadSupport ? imagesUrl.LaRepublicaLogo : ''} aria-hidden="true" alt="">
                La Republica
              </div>
              <h3 class="t-h6 mb-3">
                Lineru: más de un millón y medio de créditos en Colombia
              </h3>
              <p class="mb-3 flex-grow">
                Lineru, cuya operación comenzó en 2012, es la primera fintech que alcanza esta cifra. Sus créditos han llegado a los 32 departamentos del país.
              </p>
              <div class="d-flex justify-content-between">
                <a
                  class="btn bgr-white t-primary-500 t-brand-2:hover pl-0 pr-5 t-left align-self-start"
                  href="https://www.larepublica.co/finanzas/la-fintech-lineru-ya-ha-entregado-mas-de-15-millones-de-creditos-digitales-3031932"
                  target="_blank"
                  rel="noopener"
                  role="button"
                >
                  Leer artículo
                  <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
                    <path fill="currentColor" d={mdiChevronRight} />
                  </svg>
                </a>
                <img loading="lazy" class="w-10 h-auto -mr-8 -mr-sm-10 lazyload" width="40" height="25" data-src={imagesUrl.zigzagIllustration} src={lazyLoadSupport ? imagesUrl.zigzagIllustration : ''} aria-hidden="true" alt="">
              </div>
            </div>
            <div class="col-12 col-md-4 bgr-white pt-8 pb-6 px-6 bdr-radius-1 t-left bdr-radius-1 d-flex flex-col">
              <div class="mb-8 t-size-2 t-weight-bold d-flex align-items-center">
                <img loading="lazy" class="w-6 h-6 mr-2 lazyload" data-src={imagesUrl.dineroLogo} src={lazyLoadSupport ? imagesUrl.dineroLogo : ''} aria-hidden="true" alt="">
                Dinero
              </div>
              <h3 class="t-h6 mb-3">
                Lineru: las ayudas a sus usuarios para afrontar la pandemia
              </h3>
              <p class="mb-3 flex-grow">
                Además de ofrecer acuerdos de pago flexibles, la fintech anunció créditos con 50% de descuento en intereses, incluso para nuevo usuarios.
              </p>
              <div class="d-flex justify-content-between">
                <a
                  class="btn bgr-white t-primary-500 t-brand-2:hover pl-0 pr-5 t-left align-self-start"
                  href="https://www.dinero.com/empresas/articulo/que-medidas-han-tomado-las-fintech-por-coronavirus/283811"
                  target="_blank"
                  rel="noopener"
                  role="button"
                >
                  Leer artículo
                  <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
                    <path fill="currentColor" d={mdiChevronRight} />
                  </svg>
                </a>
                <img loading="lazy" class="w-10 h-auto -mr-8 -mr-sm-10 lazyload" width="40" height="25" data-src={imagesUrl.zigzagIllustration} src={lazyLoadSupport ? imagesUrl.zigzagIllustration : ''} aria-hidden="true" alt="">
              </div>
            </div>
          </div>
        {:else if tab === 2}
          <div
            class="col-12 d-grid grid-12 gap-6 absolute h-100"
            in:fly="{{ x: xBaseSlide * -viewWidth, duration: transDuration }}"
            out:fly="{{ x: xBaseSlide * viewWidth, duration: transDuration }}"
          >
            <div class="col-12 col-md-4 bgr-white pt-8 pb-6 px-6 bdr-radius-1 t-left bdr-radius-1 d-flex flex-col">
              <div class="d-flex justify-content-between align-items-center mb-14">
                <img loading="lazy" class="w-8 h-8 lazyload" data-src={imagesUrl.facebookLogo} src={lazyLoadSupport ? imagesUrl.facebookLogo : ''} aria-hidden="true" alt="">
                <img loading="lazy" class="w-10 h-auto -mr-8 -mr-sm-10 lazyload" width="40" height="25" data-src={imagesUrl.zigzagIllustration} src={lazyLoadSupport ? imagesUrl.zigzagIllustration : ''} aria-hidden="true" alt="">
              </div>
              <p class="mb-5 flex-grow">
                Llevo más de 3 años y nunca me han defraudado. Es la mejor plataforma online: seguro, rápido y fácil.
              </p>
              <h3 class="t-h6">
                Viviana Barón
              </h3>
            </div>
            <div class="col-12 col-md-4 bgr-white pt-8 pb-6 px-6 bdr-radius-1 t-left bdr-radius-1 d-flex flex-col">
              <div class="d-flex justify-content-between align-items-center mb-14">
                <img loading="lazy" class="w-8 h-8 lazyload" data-src={imagesUrl.instagramLogo} src={lazyLoadSupport ? imagesUrl.instagramLogo : ''} aria-hidden="true" alt="">
                <img loading="lazy" class="w-10 h-auto -mr-8 -mr-sm-10 lazyload" width="40" height="25" data-src={imagesUrl.zigzagIllustration} src={lazyLoadSupport ? imagesUrl.zigzagIllustration : ''} aria-hidden="true" alt="">
              </div>
              <p class="mb-5 flex-grow">
                Súper fácil. Estoy reportada y me prestaron. Ya llevo 4 préstamos, mientras otras entidades no me prestaron.
              </p>
              <h3 class="t-h6">
                Amparo Morales
              </h3>
            </div>
            <div class="col-12 col-md-4 bgr-white pt-8 pb-6 px-6 bdr-radius-1 t-left bdr-radius-1 d-flex flex-col">
              <div class="d-flex justify-content-between align-items-center mb-14">
                <img loading="lazy" class="w-8 h-8 lazyload" data-src={imagesUrl.facebookLogo} src={lazyLoadSupport ? imagesUrl.facebookLogo : ''} aria-hidden="true" alt="">
                <img loading="lazy" class="w-10 h-auto -mr-8 -mr-sm-10 lazyload" width="40" height="25" data-src={imagesUrl.zigzagIllustration} src={lazyLoadSupport ? imagesUrl.zigzagIllustration : ''} aria-hidden="true" alt="">
              </div>
              <p class="mb-5 flex-grow">
                Gracias por ayudarme. Aún estando reportado me dieron ese grano de arena que me sacó de apuros.
              </p>
              <h3 class="t-h6">
                Adolfo Castro
              </h3>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
  <div class="fold-7__bg-patron h-100 z-0 d-none d-lg-block" style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}');"></div>
</div>

<style>

  .fold-7__container {
    overflow: hidden;
  }

  .fold-7__bg-patron {
    width: 49.2%;
    bottom: -50%;
    position: absolute;
    left: -4%;
  }</style>
