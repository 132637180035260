<script>
	import Images from './../../../../components/Images.svelte';
  import { environment } from '../../../../environment.js';

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="relative">
  <div class="container fold-2-container pt-10 pb-0 pt-md-14 pt-lg-24">
    <h2 class="mb-8 mb-md-20 t-left t-sm-center">
      Gana puntos en cada movimiento
    </h2>

    <div class="d-grid grid-12 gap-6">
      <div class="col-12 col-sm-6 col-lg-4 pl-lg-12" data-aos="fade-in" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/user.svg' alt="Cuenta"/>
        <h3 class="t-h5 mb-5">
          Cuenta
        </h3>
        <p class="mb-0">
          Creándola arrancas <br> de una con <strong>50 puntos</strong>.
        </p>
      </div>

      <div class="col-12 col-sm-6 col-lg-4 pl-0 pl-lg-26" data-aos="fade-in" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/pago-online.svg' alt="Aprobación"/>
        <h3 class="t-h5 mb-5">
          Pago Online
        </h3>
        <p class="mb-0">
          Pagando por PSE <br>son <strong>40 puntos</strong>.
        </p>
      </div>

      <div class="col-12 col-sm-6 col-lg-4 pl-lg-30 ml-lg-6" data-aos="fade-in" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" width="48" height="48" src='/images/iconos/marca/pagos.svg' alt="Desembolsos"/>
        <h3 class="t-h5 mb-5">
          Desembolsos
        </h3>
        <p class="mb-0">
          Cada vez que recibes <br> dinero ganas <strong>110 puntos</strong>.
        </p>
      </div>

      <div class="col-12 col-sm-6 col-lg-4 pt-0 pt-lg-14 pl-lg-12" data-aos="fade-in" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/billete.svg' alt="Pago a tiempo"/>
        <h3 class="t-h5 mb-5">
          Pago a tiempo
        </h3>
        <p class="mb-0">
          Cuando cumples tu <br>
          fecha tienes <strong>120 puntos</strong>.
        </p>
      </div>

      <div class="col-12 col-sm-6 col-lg-4 pt-0 pt-lg-14 pl-lg-26" data-aos="fade-in" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/calendario.svg' alt="Pago rápido"/>
        <h3 class="t-h5 mb-5">
          Pago rápido
        </h3>
        <p class="mb-0">
          Pagando en los primeros <br>10 días son <strong>40 puntos</strong>.
        </p>
      </div>

      <div class="col-12 col-sm-6 col-lg-4 pt-0 pt-lg-14 pl-lg-30 ml-lg-6" data-aos="fade-in" data-aos-duration="500">
        <Images imgClass="w-12 h-12 d-block mb-6 lazyload" src='/images/iconos/marca/usuarios.svg' alt="Referidos"/>
        <h3 class="t-h5 mb-5">
          Referidos
        </h3>
        <p class="mb-0">
          Cuando tu referido paga <br>
          a tiempo te da <strong>80 puntos</strong>.
        </p>
      </div>
    </div>
  </div>

  <div
    class="fold-2-background d-none d-sm-block"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
</div>

<style>
  .fold-2-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15%;
    z-index: 0;
  }

  .fold-2-container {
    position: relative;
    z-index: 1;
  }</style>
