<script>
  import { mdiHome, mdiLoading } from "@mdi/js";
  import "@zinobe-co/components/input";
  import "@zinobe-co/components/password";
  import "@zinobe-co/components/breadcrumb";
  import "@zinobe-co/components/snackbar";
  import Icon from "../../components/Icon.svelte";
  import lineruIcons from "../../constants/lineru-icons.js";
  import ErrorResponseService from "../../services/error-response.js";
  import LoginService from "./_services/login.js";
  import { environment } from "../../environment.js";
  import Validate from "../../services/validate.js";
  import { onMount, tick } from "svelte";
  import GoogleTagManagerService from "../../services/tag-manager.js";
  import LineruTagsService from "../../services/lineru-tags.js";
  import { goto, metatags, params } from "@roxi/routify";
  import { load as loadRecaptcha } from "recaptcha-v3";
  import CookiesService from "../../services/cookies.js";
  import Input from "./_components/Input.svelte";
  import Password from "./_components/Password.svelte";
  import PixelsService from '../../services/pixels';

  const loginService = new LoginService();
  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: "",
      url: "/",
    },
    {
      text: "Inicio sesión",
    },
  ];

  // const queryParams = $params;

  let touched = false;
  let numDocFieldIsValid = true;
  let numDocErrorMessage;
  let valNumDoc;
  let passwordFieldIsValid = true;
  let passwordErrorMessage;
  let valPassword;
  let loginAlert = {
    show: false,
    type: "error",
    message: "",
  };
  let disabledLoginBtn = false;

  function handlerNumDocField(e) {
    valNumDoc = e.target.value;
    if (!Validate.required(e.target.value)) {
      numDocFieldIsValid = false;
      numDocErrorMessage = "Ingresa tu número de documento";
    } else if (!Validate.maxLength(e.target.value, 15)) {
      numDocFieldIsValid = false;
      numDocErrorMessage = "Sólo se admiten hasta 15 caracteres";
    } else if (!Validate.onlyNumbers(e.target.value)) {
      numDocFieldIsValid = false;
      numDocErrorMessage = "Ingresa sólo números";
    } else {
      numDocFieldIsValid = true;
    }
  }

  function handlerPasswordField(e) {
    valPassword = e.target.value;
    if (!Validate.required(e.target.value)) {
      passwordFieldIsValid = false;
      passwordErrorMessage = "Ingresa tu contraseña";
    } else {
      passwordFieldIsValid = true;
    }
  }

  function transform(val) {
    return { target: { value: val } };
  }

  function validate() {
    handlerNumDocField(transform(valNumDoc));
    handlerPasswordField(transform(valPassword));

    return passwordFieldIsValid && numDocFieldIsValid;
  }

  function getGAClientIdFromCookie() {
    const gaCookie = CookiesService.getItem('_ga');

    if (gaCookie) {
      const gaParts = gaCookie.split('.');
      const clientId = gaParts[2] + '.' + gaParts[3];
      return clientId;
    } else {
      return null;
    }
  }

  function handlerSendLogIn() {
    touched = true;
    let validateRtl = validate();

    if (!validateRtl) {
      return;
    }

    disabledLoginBtn = true;

    const data = {
      document: valNumDoc,
      password: valPassword,
      recaptcha_token: "",
      recaptcha_event: "login_event",
      client_id: getGAClientIdFromCookie()
    };

    GoogleTagManagerService.setTag(
      LineruTagsService.initializeSesion().initSesion
    );

    loadRecaptcha(environment.recaptchaSiteKey).then((recaptcha) => {
      recaptcha.execute("submit").then((token) => {
        const cleverToken = CookiesService.getItem("WZRK_G");
        loginService
          .login({
            ...data,
            recaptcha_token: token,
            clever_token: cleverToken,
          })
          .then(() => {
            GoogleTagManagerService.setTag(
              LineruTagsService.initializeSesion(data.document).initSesionSuccess
            );
            if (window.clevertap) {
              clevertap.onUserLogin.push({
                Site: {
                  Identity: data.document,
                },
              });
            }
            disabledLoginBtn = false;

            /* if (queryParams.redirect) {
              document.location.href = queryParams.redirect;
            } else {
              document.location.href = "/mi-cuenta";
            } */

            document.location.href = "/mi-cuenta";
          })
          .catch((err) => {
            const slug = err.message;
            loginAlert.show = true;
            loginAlert.message = ErrorResponseService.login(slug);
            disabledLoginBtn = false;
          });
      });
    });
  }

  function goToForgotPass() {
    $goto("/olvide-contrasena");
    GoogleTagManagerService.setTag(
      LineruTagsService.initializeSesion().forgotPass
    );
  }
  function goToRegister() {
    $goto("/registro");
    GoogleTagManagerService.setTag(LineruTagsService.initializeSesion().register);
  }

  onMount(async () => {
    const queryParams = Object.keys($params).length > 0 ? $params : null;
    PixelsService.saveUtmParams(queryParams);

    document.addEventListener("keypress", (e) => {
      if (e.charCode === 13) {
        handlerSendLogIn();
      }
    });

    loadRecaptcha(environment.recaptchaSiteKey, {
      explicitRenderParameters: {
        badge: "bottomleft",
      },
    });

    await tick();
    metatags.title = "Iniciar Sesión | Lineru";
    metatags.description =
      "Bienvenido a tu cuenta Lineru. Desde acá puedes pedir y pagar todos tus desembolsos, comprar seguros, hacer recargas y recibir descuentos.";
  });

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div
  class="login z-0 pt-28 -mt-28 flex-grow d-flex align-items-center d-md-block"
>
  <div
    class="login__breadcrumbs z-10 w-11/12 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2 t-size-1
    t-black d-none d-md-block"
  >
    <znb-breadcrumb data={breadcrumbData} />
  </div>

  <div class="login__background w-100 h-100 d-none d-sm-block">
    <div
      class="d-flex justify-content-end d-none px-4 h-100 px-lg-5 login__background__left pt-28"
      style="background: url('{resolveRoute(
        '/images/patrones/patron-login-registro.png'
      )}') repeat, linear-gradient(90deg, #abd4f4 30%, #a8e1ee 100%);"
    >
      <div class="login__background__left__content t-black mt-26">
        <h3 class="mb-10 mb-xl-14">Bienvenido a tu nueva vida financiera.</h3>
        <ul class="mb-0 t-size-4 t-primary-500 pl-7 ml-0">
          <li class="pl-2 mb-6">
            <span class="t-brand-5">
              Cada desembolso y cada pago a tiempo que hagas va a mejorar tu
              perfil crediticio.
            </span>
          </li>
          <li class="pl-2 mb-6">
            <span class="t-brand-5">
              Está pilas de los descuentos y beneficios por redes sociales,
              email y mensaje de texto.
            </span>
          </li>
          <li class="pl-2">
            <span class="t-brand-5">
              Acumula puntos y referidos para aumentar tu
              <br class="d-none d-lg-inline" />
              cupo y tener descuentos en tus créditos.
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="login__background__right w-100 h-100" />
  </div>

  <div class="container login__container z-10 d-grid grid-12 gap-6">
    <div
      class="col-start-1 col-md-start-7 col-12 col-md-6 py-8 pt-md-16 pb-md-30"
    >
      <h1 class="t-h2 mb-14">Inicia sesión en Lineru</h1>

      <form on:submit|preventDefault={handlerSendLogIn}>
        <div class="d-block mb-6">
          <Input
            id="num-doc"
            on:input={handlerNumDocField}
            label="Número de documento"
            valid={numDocFieldIsValid}
            errormessage={numDocErrorMessage}
            helpmessage=""
            {touched}
            placeholder="Escribe tu número de documento"
            autocomplete="username"
          />
        </div>

        <!-- <znb-input
        id="num-doc"
        oninput={handlerNumDocField}
        label="Número de documento"
        valid={numDocFieldIsValid}
        errormessage={numDocErrorMessage}
        helpmessage="
        "
        {touched}
        placeholder="Escribe tu número de documento"
        class="d-block mb-6"
        autocomplete="username"/> -->
        <div class="d-block mb-6">
          <Password
            id="password"
            on:input={handlerPasswordField}
            label="Contraseña"
            valid={passwordFieldIsValid}
            errormessage={passwordErrorMessage}
            helpmessage=""
            {touched}
            placeholder="Escribe tu contraseña"
            type="get"
            class="d-block mb-6"
            autocomplete="current-password"
          />
        </div>
        <!-- <znb-password
        id="password"
        oninput={handlerPasswordField}
        label="Contraseña"
        valid={passwordFieldIsValid}
        errormessage={passwordErrorMessage}
        helpmessage=""
        {touched}
        placeholder="Escribe tu contraseña"
        type="get"
        class="d-block mb-6"
        autocomplete="current-password"
      /> -->

        <button
          class="btn btn--shadow bgr-primary-500 bgr-brand-2:active t-white btn-lg mb-6 w-100 w-sm-auto d-flex t-left align-items-center"
          class:disabled={disabledLoginBtn}
          role="button"
          type="submit"
          aria-labelledby
        >
          <span class="flex-grow">Iniciar sesión</span>
          {#if !disabledLoginBtn}
            <Icon
              width="16"
              height="11"
              classList="ml-3 -mr-3 t-yellow"
              path={lineruIcons.arrow}
            />
          {:else}
            <svg
              class="w-6 h-auto t-yellow ml-3 -mr-3 loading-icon"
              height="100%"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d={mdiLoading} />
            </svg>
          {/if}
        </button>
      </form>
      <a
        class="t-weight-bold t-size-4"
        href="/"
        on:click|preventDefault={goToForgotPass}
      >
        Olvidé mi contraseña
      </a>

      <div class="d-flex mt-4 d-block d-md-none">
        <p class="pt-1">¿No tienes una cuenta?</p>
        <a
          class="d-block btn btn-sm bgr-white t-primary-500 t-brand-2:hover mx-2 px-1 d-inline-block d-lg-none"
          on:click|preventDefault={goToRegister}
          href="/"
          role="button"
        >
          Regístrate
        </a>
      </div>

      <znb-snackbar
        class="mt-12 mt-md-20 d-block"
        dismissible="true"
        show={loginAlert.show}
        type={loginAlert.type}
        on:close={() => (loginAlert.show = false)}
      >
        {loginAlert.message}
      </znb-snackbar>
    </div>
  </div>
</div>

<style>
  .login {
    position: relative;
  }

  .login__breadcrumbs {
    margin: 0 auto;
    position: relative;
  }

  .login__background {
    position: absolute;
    left: 0;
    top: 0;
  }

  .login__container {
    position: relative;
  }

  .login__background__left {
    position: absolute;
    width: calc(50% - 95px);
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position-x: 50%, left;
    background-position-y: 38%, top;
    background-size: 76.5%, 100%;
  }

  .login__background__left__content {
    width: 257px;
  }

  .login__background__right {
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (min-width: 768px) {
    .login__background__right {
      width: calc(50% + 71px);
      left: calc(50% - 71px);
    }

    .login__background__left {
      display: flex;
      width: calc(50% - 71px);
    }
  }

  @media (min-width: 992px) {
    .login__background__right {
      width: calc(50% + 91px);
      left: calc(50% - 91px);
    }

    .login__background__left {
      width: calc(50% - 91px);
    }

    .login__background__left__content {
      width: 353px;
    }
  }

  @media (min-width: 1200px) {
    .login__background__right {
      width: calc(50% + 105px);
      left: calc(50% - 105px);
    }

    .login__background__left {
      width: calc(50% - 105px);
    }

    .login__background__left__content {
      width: 430px;
    }
  }

  .loading-icon {
    animation: mdi-spin 1s infinite linear;
  }

  @keyframes mdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }</style>
