<script>
  import {
    mdiHome,
    mdiLoading
  } from '@mdi/js';
  import { onDestroy, onMount, tick } from 'svelte';
  import '@zinobe-co/components/input';
  import '@zinobe-co/components/select';
  import '@zinobe-co/components/password';
  import '@zinobe-co/components/checkbox';
  import '@zinobe-co/components/breadcrumb';
  import '@zinobe-co/components/snackbar';
  import Icon from '../../components/Icon.svelte';
  import lineruIcons from '../../constants/lineru-icons.js';
  import RegisterService from './_services/register.js';
  import { environment } from '../../environment.js';
  import CookiesService from '../../services/cookies.js';
  import CalculatorService from '../../services/calculator.js';
  import Validate from '../../services/validate.js';
  import ErrorResponseService from '../../services/error-response.js';
  import GoogleTagManagerService from '../../services/tag-manager.js';
  import { metatags, goto, route } from '@roxi/routify';
  import LineruTagsService from '../../services/lineru-tags.js';
  import { load as loadRecaptcha } from 'recaptcha-v3';
  import CalculatorDetails from '../../components/CalculatorDetails.svelte';
  import { calculatoDataStore } from '../../stores/calculator-data-store';
  import { registerStore } from './../../stores/register-storage.js';

  let closeUrl = '/';
  const calService =  new CalculatorService();
  let calculatorDetails;

  const registerService = new RegisterService();
  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: '',
      url: '/',
    },
    {
      text: 'Registro',
    },
  ];

  let passwordFieldIsValid = true;
  let passwordErrorMessage;
  let passwordValue;
  let registerAlert = {
    show: false,
    type: 'error',
    message: ''
  }
  let disableSubmitBtn = false;

  let conditions = [
    {
      description: 'Mínimo 10 caracteres',
      regexp: /^.{10,}$/,
      valid: false
    },
    {
      description: 'Mínimo 6 caracteres alfabéticos',
      regexp: /.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*/,
      valid: false
    },
    {
      description:  'Mínimo 2 caracteres numéricos',
      regexp: /.*\d.*\d.*/,
      valid: false
    },
    {
      description: 'Mínimo 2 caracteres especiales como: *_ / .',
      regexp: /.*[!@#$%^&*()_+|~={}:'<>?,./\[\]-].*[!@#$%^&*()_+|~={}:'<>?,./\[\]-]/,
      valid: false
    },
    {
      description: 'Minúsculas y mayúsculas',
      regexp: /(?=.*[a-z])(?=.*[A-Z])|(?=.*[A-Z])(?=.*[a-z])/,
      valid: false
    }
  ];

  function handlerPasswordField(e) {
    passwordValue = e.target.value;

    if (!Validate.required(passwordValue)) {
      passwordFieldIsValid = false;
      passwordErrorMessage = 'Ingresa tu contraseña';
    } else {
      passwordFieldIsValid = true;
    }
  }

  let status = {
    name: true,
    t_doc: true,
    n_doc: true,
    n_phone: true,
    email: true,
  };

  let errorMessages = {
    name: 'Ingresa tu nombre',
    t_doc: 'Selecciona el tipo de documento',
    n_doc: 'Ingresa sólo números',
    n_phone: 'Ingresa sólo números',
    email: 'Ingresa un correo válido',
  };


  let fields = [
    {
      id: 'name',
      label: 'Primer Nombre',
      placeholder: 'Escribe tu primer nombre',
      type: 'input',
      handleStatus: function(e) {
        if (!Validate.required(e.target.value)) {
          errorMessages[this.id] = 'Ingresa tu nombre';
          status[this.id] = false;
        } else if (!Validate.noSpaces(e.target.value)) {
          errorMessages[this.id] = 'Ingresa sólo tu primer nombre, sin espacios';
          status[this.id] = false;
        } else if (!Validate.onlyLetters(e.target.value)) {
          errorMessages[this.id] = 'Ingresa sólo letras';
          status[this.id] = false;
        } else {
          status[this.id] = true;
        }

        this.value = e.target.value;
        registerStore.update(n => {
          return{
            ...n,
            name:this.value
          }
        })
      },
      value: '',
      inline: false
    },
    {
      id: 't_doc',
      label: 'Tipo de documento',
      placeholder: 'Selecciona una opción',
      type: 'select',
      options: [
        {
          key: 'Cédula de ciudadanía',
          value: 'C.C.',
        },
        {
          key: 'Cédula de extranjería',
          value: 'C.E.',
        },
      ],
      handleStatus: function(e) {
        if (!Validate.required(e.target.value)) {
          errorMessages[this.id] = 'Selecciona el tipo de documento';
          status[this.id] = false;
        } else {
          status[this.id] = true;
        }

        this.value = e.target.value;
        registerStore.update(n => {
          return{
            ...n,
            t_doc:this.value
          }
        })
      },
      value: '',
      inline: true
    },
    {
      id: 'n_doc',
      label: 'Número de documento',
      placeholder: 'Ingresa tu documento',
      type: 'input',
      handleStatus: function(e) {
        if (!Validate.required(e.target.value)) {
          errorMessages[this.id] = 'Ingresa tu número de documento';
          status[this.id] = false;
        } else if (!Validate.onlyNumbers(e.target.value)) {
          errorMessages[this.id] = 'Ingresa sólo números';
          status[this.id] = false;
        } else if (!Validate.maxLength(e.target.value, 15)) {
          errorMessages[this.id] = 'Sólo se admiten hasta 15 caracteres';
          status[this.id] = false;
        } else {
          status[this.id] = true;
        }

        this.value = e.target.value;
        registerStore.update(n => {
          return{
            ...n,
            n_doc:this.value
          }
        })
      },
      value: '',
      inline: true
    },
    {
      id: 'n_phone',
      label: 'Número de celular',
      maxlength: 10,
      placeholder: 'Escribe tu teléfono celular',
      type: 'input',
      handleStatus: function(e) {
        if (!Validate.required(e.target.value)) {
          errorMessages[this.id] = 'Ingresa tu teléfono celular';
          status[this.id] = false;
        } else if (!Validate.onlyNumbers(e.target.value)) {
          errorMessages[this.id] = 'Ingresa sólo números';
          status[this.id] = false;
        } else if (!Validate.minLength(e.target.value, 10)) {
          errorMessages[this.id] = 'Se admiten mínimo 10 caracteres';
          status[this.id] = false;
        } else if (!Validate.maxLength(e.target.value, 10)) {
           errorMessages[this.id] = 'Sólo se admiten hasta 10 caracteres';
          status[this.id] = false;
        } else {
          status[this.id] = true;
        }

        this.value = e.target.value;
        registerStore.update(n => {
          return{
            ...n,
            n_phone:this.value
          }
        })
      },
      value: '',
      inline: true
    },
    {
      id: 'email',
      label: 'Correo electrónico',
      placeholder: 'Ingresa tu correo',
      type: 'input',
      handleStatus: function(e) {
        if (!Validate.required(e.target.value)) {
          errorMessages[this.id] = 'Ingresa tu correo';
          status[this.id] = false;
        } else if (!Validate.email(e.target.value)) {
          errorMessages[this.id] = 'Ingresa un correo válido';
          status[this.id] = false;
        } else {
          status[this.id] = true;
        }

        this.value = e.target.value;
        registerStore.update(n => {
          return{
            ...n,
            email:this.value
          }
        })
      },
      value: '',
      inline: true
    },
  ];

  let fValuesUnsubscribe;

  let checkTermNCondStatus = false;
  let checkPermiStatus = false;
  let elCheckTermNCondStatus;
  let elCheckPermiStatus;

  let checkTermNCond = {
    status: true,
    errorMessage: 'Debes aceptar para continuar',
    handleStatus: e => {
      let result = e.detail.filter(el => el.checked);
      checkTermNCondStatus = result.length > 0 ? true : false;
    },
    checks: [
      {
        id: 'termNCond',
        label: 'Acepto <a href="/terminos-y-condiciones" target="_blank">los términos y condiciones</a>',
        value: 'termNCondChecked',
        checked: false
      },
    ],
  };

  let checkPermi = {
    status: true,
    errorMessage: 'Debes aceptar para continuar',
    handleStatus: e => {
      let result = e.detail.filter(el => el.checked);
      checkPermiStatus = result.length > 0 ? true : false;
    },
    checks: [
      {
        id: 'checkPermi',
        label: 'Autorizo <a href="/tratamiento-de-datos-personales" target="_blank">el tratamiento de datos personales</a>',
        value: 'checkPermiChecked',
        checked: false
      },
    ],
  };

  onMount(async () => {

    closeUrl = calService.getCloseUrl($route.last.shortPath);

    if(closeUrl === "/calcula-tu-credito"){
      fValuesUnsubscribe = registerStore.subscribe(x => {
        fields[0].value = x.name,
        fields[1].value = x.t_doc,
        fields[2].value = x.n_doc,
        fields[3].value = x.n_phone,
        fields[4].value = x.email
      })
    }else {
      registerStore.set({
        name:'',
        t_doc:'',
        n_doc:'',
        n_phone:'',
        email:''
      })
    }

    elCheckTermNCondStatus.addEventListener('check-change', e => {
      checkTermNCond.handleStatus(e);
    });

    elCheckPermiStatus.addEventListener('check-change', e => {
      checkPermi.handleStatus(e);
    });

    document.addEventListener('keypress', e => {
      if(e.charCode === 13) {
        handlerFormRegister();
      }
    });

    loadRecaptcha(environment.recaptchaSiteKey, {
      explicitRenderParameters: {
        badge: 'bottomleft'
      }
    });

    await tick();
    metatags.title = 'Registro | Lineru';
    metatags.description = 'Registrarte en Lineru es gratis. Si no tienes una cuenta, acá puedes crearla y pedir tu cupo de crédito. Cambiemos tu vida financiera juntos.';
  });

  const unsubscribe = calculatoDataStore.subscribe(calculatorData => {
    calculatorDetails = calculatorData;
  })

  onDestroy(unsubscribe, fValuesUnsubscribe);

  function formValidate() {
    touched = true;

    fields.forEach(field => {
      let e = {
        target: {
          value: field.value,
        },
      };

      field.handleStatus(e);
    });

    if (!checkTermNCondStatus && !checkPermiStatus) {
      checkTermNCondStatus = false;
      checkPermiStatus = false;
    }

    handlerPasswordField({ target: { value: passwordValue } });

    const valFields = fields.filter(field => status[field.id] === true);

    const validateAll =
      valFields.length === fields.length &&
      passwordFieldIsValid &&
      checkTermNCondStatus &&
      checkPermiStatus;

    // Scroll to invalid field - BEGIN
    if(valFields.length !== fields.length) {
      for(let field of fields) {
        if(!status[field.id]) {
          navigateTo(field.id);
          break;
        }
      }
    } else {
      switch (true) {
        case !passwordFieldIsValid:
          navigateTo('password');
          break;

        case !checkTermNCondStatus:
          navigateTo('terms_conditions');
          break;

        case !checkPermiStatus:
          navigateTo('personal_treatment');
          break;
      }
    }
    // Scroll to invalid field - END

    return validateAll;
  }

  function navigateTo(classTarget) {
    const target = document.querySelector('.' + classTarget) || document.body;
    const topPos = target.getBoundingClientRect().top + window.pageYOffset - 110;

    scroll({
      top: topPos,
      behavior: 'smooth'
    });
  }

  function getGAClientIdFromCookie() {
    const gaCookie = CookiesService.getItem('_ga');

    if (gaCookie) {
      const gaParts = gaCookie.split('.');
      const clientId = gaParts[2] + '.' + gaParts[3];
      return clientId;
    } else {
      return null;
    }
  }

  function handlerFormRegister() {
    let validate = formValidate();

    if (!validate) {
      return;
    }

    disableSubmitBtn = true;

    let data = {
      first_name: fields.find(field => field.id === 'name').value,
      doc_type: fields.find(field => field.id === 't_doc').value,
      document: fields.find(field => field.id === 'n_doc').value,
      cellphone: fields.find(field => field.id === 'n_phone').value,
      email: fields.find(field => field.id === 'email').value,
      password: passwordValue,
      terms_conditions: checkTermNCondStatus,
      personal_data_auth: checkPermiStatus,
      recaptcha_token: '',
      recaptcha_event: 'register_event',
      clever_token: '',
      client_id: getGAClientIdFromCookie()
    };

    GoogleTagManagerService.setTag(LineruTagsService.register().submitForm);

    loadRecaptcha(environment.recaptchaSiteKey).then((recaptcha) => {
      recaptcha.execute('submit').then((token) => {
        const cleverToken = CookiesService.getItem('WZRK_G');
        registerService.register({
          ...data,
          recaptcha_token: token,
          clever_token: cleverToken
        })
          .then(() => {
            GoogleTagManagerService.setTag(LineruTagsService.register(data.document).submitFormSuccess);
            GoogleTagManagerService.setTag(LineruTagsService.register().submitFormSuccessv2({
              amount: calculatorDetails.amount ? calculatorDetails.amount : undefined,
              first_name: data.first_name,
              phone: data.cellphone,
              mail: data.email,
              zinobe_product_name: 'Lineru Tradicional'
            }));
            if(window.clevertap) {
              clevertap.onUserLogin.push({
                'Site': {
                  'Name': data.first_name,
                  'Identity': data.document,
                  'Email': data.email,
                  'Phone': '+57' + data.cellphone
                }
              });
            }
            disableSubmitBtn = false;
            window.open('/mi-cuenta', '_parent');
          })
          .catch(err => {
            const slug = err.message;
            //const slug = err && err.message && err.message.code_slug;
            registerAlert.show = true;
            disableSubmitBtn = false;
            registerAlert.message = ErrorResponseService.register(slug);
          });
      });
    });
  }

  function goToRegister() {
    $goto('/iniciar-sesion');
    GoogleTagManagerService.setTag(LineruTagsService.register().registerBtn)
  }

  let touched = false;

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<style>
  .register {
    position: relative;
  }

  .register__breadcrumbs {
    margin: 0 auto;
    position: relative;
  }

  .register__background {
    position: absolute;
    left: 0;
    top: 0;
  }

  .register__container {
    position: relative;
  }

  .register__background__left {
    position: absolute;
    width: calc(50% - 95px);
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position-x: 50%, left;
    background-position-y: 15%, top;
    background-size: 76.5%, 100%;
  }

  .register__background__left__content {
    width: 257px;
  }

  .register__background__right {
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (min-width: 768px) {
    .register__background__right {
      width: calc(50% + 71px);
      left: calc(50% - 71px);
    }

    .register__background__left {
      width: calc(50% - 71px);
    }
  }

  @media (min-width: 992px) {
    .register__background__right {
      width: calc(50% + 91px);
      left: calc(50% - 91px);
    }

    .register__background__left {
      width: calc(50% - 91px);
    }

    .register__background__left__content {
      width: 353px;
    }
  }

  @media (min-width: 1200px) {
    .register__background__right {
      width: calc(50% + 105px);
      left: calc(50% - 105px);
    }

    .register__background__left {
      width: calc(50% - 105px);
    }

    .register__background__left__content {
      width: 430px;
    }
  }

  .loading-icon {
    animation: mdi-spin 1s infinite linear;
  }

  @keyframes mdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }</style>

<div
  class="register z-0 pt-28 -mt-28 flex-grow d-flex align-items-center d-md-block">
  <div
    class="register__breadcrumbs z-10 w-11/12 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2
    t-size-1 t-black d-none d-md-block">
    <znb-breadcrumb data={breadcrumbData} />
  </div>

  <div class="register__background w-100 h-100 d-none d-sm-block">
    <div
      class="justify-content-end px-4 px-lg-5 register__background__left d-none  d-md-flex h-100 pt-28"
      style="background: url('{resolveRoute("/images/patrones/patron-login-registro.png")}') repeat, linear-gradient(90deg, #abd4f4 30%, #a8e1ee 100%)"
    >
      <div class="register__background__left__content mt-26">
        <h3 class="mb-10 mb-xl-14">
          Démosle juntos nueva vida a
          <br class="d-none d-xl-inline" />
          tu bolsillo.
        </h3>
        <ul class="mb-0 t-size-4 pl-7 t-primary-600">
          <li class="pl-2 mb-6">
            <span class="t-brand-5">
              Cada desembolso y cada pago a tiempo que hagas
            <br class="d-none d-lg-inline" />
            va a <strong>mejorar tu perfil crediticio.</strong>
            </span>
          </li>
          <li class="pl-2 mb-6">
            <span class="t-brand-5">
              <strong>A medida que nos conozcamos</strong> tu cupo aprobado
              <br class="d-none d-lg-inline" />
              puede subir y tus costos bajar.
            </span>
          </li>
          <li class="pl-2">
            <span class="t-brand-5">
              En el camino vamos a <strong>enseñarte cómo tener más
              <br class="d-none d-lg-inline" />
              dinero</strong> y acceder a créditos más grandes.
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="register__background__right w-100 h-100" />
  </div>

  <div class="container register__container z-10 d-grid grid-12 gap-6">
    <div
      class="col-start-1 col-md-start-7 col-12 col-md-6 pb-8 pb-md-30"
      class:pt-md-16 = {closeUrl !== "/calcula-tu-credito"}
      class:pt-8 = {closeUrl !== "/calcula-tu-credito"}
    >
      {#if closeUrl === "/calcula-tu-credito"}
        <CalculatorDetails {calculatorDetails}/>
      {/if}
      <h2 class="mb-10 mb-sm-14">
        <span class="t-h2 d-none d-md-block">
          Crear tu cuenta en Lineru
          <br class="d-none d-xl-block">
          es gratis
        </span>
        <span class="t-h3 d-block d-md-none">Crear tu cuenta en Lineru es gratis</span>
      </h2>
      <div class="d-grid grid-2 gap-4">
        {#each fields as field}
        {#if field.type === 'select'}
          <znb-select
            id={field.id}
            value={field.value}
            onblur={e => field.handleStatus(e)}
            label={field.label}
            valid={status[field.id]}
            errormessage={errorMessages[field.id]}
            placeholder={field.placeholder}
            options={field.options}
            {touched}
            class="d-block mb-4 {field.id} {field.inline ? 'col-2 col-md-1' : 'col-2 col-md-2'}" />
        {:else}
          <znb-input
            id={field.id}
            value={field.value}
            oninput={e => field.handleStatus(e)}
            label={field.label}
            valid={status[field.id]}
            errormessage={errorMessages[field.id]}
            placeholder={field.placeholder}
            maxlength={field.maxlength}
            {touched}
            class="d-block mb-4 {field.id} {field.inline ? 'col-2 col-md-1' : ' col-2 col-md-2'}" />
        {/if}
        {/each}
          <znb-password
            id="password"
            oninput={handlerPasswordField}
            label="Crea tu contraseña"
            valid={passwordFieldIsValid}
            errormessage={passwordErrorMessage}
            helpmessage=""
            placeholder="Ingresa tu contraseña"
            type="set"
            rules={conditions}
            {touched}
            class="d-block mb-2 password col-2"/>

          <znb-checkbox
            bind:this={elCheckTermNCondStatus}
            valid={checkTermNCondStatus}
            errormessage={checkTermNCond.errorMessage}
            {touched}
            options={checkTermNCond.checks}
            class="terms_conditions col-2 -mb-5"/>

          <znb-checkbox
            bind:this={elCheckPermiStatus}
            valid={checkPermiStatus}
            errormessage={checkPermi.errorMessage}
            options={checkPermi.checks}
            {touched}
            class="d-block mb-4 personal_treatment col-2"/>
      </div>

      <button
        class="btn btn--shadow bgr-primary-500 bgr-brand-2:active t-white btn-lg w-100 w-sm-auto d-flex t-left align-items-center"
        class:disabled={disableSubmitBtn}
        role="button"
        on:click={handlerFormRegister}
        aria-labelledby>
        <span class="flex-grow">Crear mi cuenta</span>
        {#if !disableSubmitBtn}
          <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
        {:else}
          <svg class="w-6 h-auto t-yellow ml-3 -mr-3 loading-icon" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiLoading} />
          </svg>
        {/if}
      </button>

      <div class="d-flex mt-4 d-inline-block d-md-none">
        <p class="pt-1">¿Ya tienes cuenta?</p> <a
        class="d-block btn btn-sm bgr-white t-primary-500 t-brand-2:hover mx-2 px-1 d-inline-block d-lg-none"
        on:click|preventDefault={goToRegister}
        href="/"
        role="button"
      >
        Inicia sesión
      </a>
      </div>

      <znb-snackbar
        class="mt-12 mt-md-20 d-block"
        dismissible="true"
        show={registerAlert.show}
        type={registerAlert.type}
        on:close={() => registerAlert.show = false }
      >
        {registerAlert.message}
      </znb-snackbar>
    </div>
  </div>
</div>
