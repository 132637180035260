<script>
  import { fade } from 'svelte/transition';
  import { onMount, onDestroy } from 'svelte';
  import CustomTransition from '../../components/CustomTransition.svelte';

  const configs = [
    {
      condition: (c) => {
        return c.toDescendant;
      },
      transition: fade,
      inParams: { duration: 500},
      outParams: { duration: 500 }
    },
    {
      condition: (c) => {
        return true;
      },
      transition: fade,
      inParams: { duration: 500 },
      outParams: { duration: 500 }
    },
    {
      // No matching config. We don't want a transition
      condition: () => true,
      transition: () => {}
    }
  ];

  let show;
  let routifyInBrowser;

  onMount(()=>{
    show = true;
    routifyInBrowser = routify.inBrowser;
  });

  onDestroy(()=> {
    show = false;
  })

</script>

<style>
  .container{
    transition: height .8s ease-in-out, opacity .7s ease-in-out, visibility .6s ease-in-out;
    opacity: 0;
    height: 0%;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
  }

  .container-show{
    opacity: 1;
    visibility: visible;
    height: 100%;
  }</style>
{#if routifyInBrowser}
  <CustomTransition {configs} >
    <div
      class="container d-block w-100 bgr-brand-5"
      class:container-show={show}
    >
        <slot />
    </div>
  </CustomTransition>
{/if}
