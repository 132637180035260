<script>
  import Images from './../../../../components/Images.svelte';
  import { mdiChevronRight } from '@mdi/js';
  import { goto } from '@roxi/routify';
  import GoogleTagManagerService from '../../../../services/tag-manager.js';
  import LineruTagsService from '../../../../services/lineru-tags.js';
  import { environment } from '../../../../environment.js';
  import '@zinobe-co/components/swiper';

  const swipperItems = [
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/user.svg')}" />
        <p>
          <strong>
            Comienza en Lineru
          </strong>
        </p>
        <p>
          Inicia sesión en tu cuenta. Y haz clic en “Pagar en línea”.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/computador.svg')}" />
        <p>
          <strong>
            Pasa por PayU
          </strong>
        </p>
        <p>
          Completa allí tus datos. Y haz lo mismo en PSE.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/clave.svg')}" />
        <p>
          <strong>
            Entra a tu banco
          </strong>
        </p>
        <p>
          Inicia tu sesión allí. Y completa la transacción.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/ok-hand.svg')}" />
        <p>
          <strong>
            Confirma el pago
          </strong>
        </p>
        <p>
          En Lineru lo vas a ver actualizado de inmediato.
        </p>
        <p>
          <a class="btn" href="/iniciar-sesion/?redirect='/mi-cuenta/pagar-credito'">
            Ir a pagar por PSE
          </a>
        </p>
      `,
    }
  ];

  function goToPayLoan() {
    $goto('/iniciar-sesion/?redirect=\'/mi-cuenta/pagar-credito\'')
    GoogleTagManagerService.setTag(LineruTagsService.howToPay().payOutOfHome);
  }

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-2 mb-2">
  <div class="container fold-2__container pt-10 pt-md-16 pt-lg-26">
    <h2 class="mb-12">
      Pago por PSE:
      <br>
      Paga rápido sin salir de casa
    </h2>

    <div class="d-none d-sm-grid grid-12 gap-6">
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5"  data-aos="fade-right" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/user.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Comienza en Lineru
        </h3>
        <p class="mb-0">
          Inicia sesión en tu cuenta. Y haz clic en “Pagar en línea”.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5"  data-aos="fade-down" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/computador.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Pasa por PayU
        </h3>
        <p class="mb-0">
          Completa allí tus datos. Y haz lo mismo en PSE.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5"  data-aos="fade-up" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/clave.svg')aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Entra a tu banco
        </h3>
        <p class="mb-0">
          Inicia tu sesión allí. Y completa la transacción.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5"  data-aos="fade-left" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/ok-hand.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Confirma el pago
        </h3>
        <p class="mb-3">
          En Lineru lo vas a ver actualizado de inmediato.
        </p>
        <a
          class="btn bgr-transparent t-primary-500 t-brand-2:hover pl-0 pr-5 t-left"
          href="/"
          on:click|preventDefault={goToPayLoan}
          role="button"
        >
          Ir a pagar por PSE
          <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiChevronRight} />
          </svg>
        </a>
      </div>
    </div>

    <znb-swiper class="d-block d-sm-none" items={swipperItems} />
  </div>
  <div class="fold-2__bg-patron"></div>
</div>

<style>
  .fold-2 {
    position: relative;
    z-index: 0;
  }

  .fold-2__container {
    position: relative;
    z-index: 1;
  }

  .fold-2__bg-patron {
    width: 15%;
    height: 84%;
    top: 8%;
    position: absolute;
    right: 0;
    background-image: url('/images/patrones/patron-marca.png');
  }</style>
