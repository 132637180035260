<script>
  import { mdiChevronRight } from '@mdi/js';
  import { environment } from '../../../environment.js';

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-6">
  <div class="container fold-6__container py-10 py-md-26 d-grid grid-12 gap-6 align-items-center">
    <div class="col-12 col-md-6 order-1 order-md-2" data-aos="fade-left">
      <picture aria-hidden="true">
        <source srcset="/images/mundo-lineru/educacion.webp" type="image/webp">
        <img loading="lazy" class="img-fluid d-block lazyload" data-src={resolveRoute('/images/mundo-lineru/educacion.png')} alt="">
      </picture>
    </div>
    <div class="col-12 col-md-6 order-2 order-md-1">
      <p class="mb-12">
        <strong>
          Educación
        </strong>
      </p>
      <h3 class="t-h2 t-gold mb-6">
        Mejora tu perfil de crédito
      </h3>
      <p class="t-size-5 mb-5">
        Nuestros créditos son hasta $1.200.000 de pesos. Pero seguro tú tienes sueños más grandes, como comprar tu carro, tener tu propia casa o viajar por el mundo. Acá vas a encontrar contenido exclusivo para aprender cómo mejorar tu perfil y tu puntaje de crédito. Y de esa forma ser aprobado para créditos más grandes.
      </p>
    </div>
  </div>

  <div
    class="fold-6__background"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
  <div
    class="fold-6__shape d-none d-md-block"
    style="background-image: url('{resolveRoute("/images/formas/ola-circulo.svg")}')"
  ></div>
</div>

<style>
  .fold-6 {
    position: relative;
  }

  .fold-6__container {
    position: relative;
    z-index: 1;
  }

  .fold-6__background {
    position: absolute;
    top: 21%;
    right: 0;
    width: 39%;
    height: 58%;
    z-index: 0;
  }

  .fold-6__shape {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 11%;
    width: 97px;
    height: 81px;
    z-index: 0;
  }

  @media (min-width: 992px) {
    .fold-6__shape {
      top: 1%;
    }
  }</style>
