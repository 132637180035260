<script>
    export let open = false;

    import {
        goto
    } from '@roxi/routify'

    import {
        createEventDispatcher,
        onMount
    } from 'svelte';

    import Icon from './Icon.svelte';
    import lineruIcons from '../constants/lineru-icons.js';
    import GoogleTagManagerService from '../services/tag-manager.js';
    import LineruTagsService from '../services/lineru-tags.js';
    import { environment } from '../environment';


    const dispatch = createEventDispatcher();

    const handlerNavigation = (url) => {
        $goto(url);
        dispatch('close');

        switch (url) {
            case '/':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().init);
                break;
            case '/calcula-tu-credito':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().goToCalculate);
                break;
            case '/acerca-de-tu-credito/nuestros-requisitos':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().requisits);
                break;
            case '/acerca-de-tu-credito':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().ourCredits);
                break;
            case '/acerca-de-tu-credito/cuanto-cuesta':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().howMuchItCost);
                break;
            case '/acerca-de-tu-credito/como-pagar':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().howToPay);
                break
            case '/mundo-lineru':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().lineroWorld);
                break
            case '/iniciar-sesion':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().initSession);
                break
            case '/registro':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().register);
                break
            case '/olvide-contrasena':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().forgotPass);
                break
            case '/preguntas-frecuentes':
                GoogleTagManagerService.setTag(LineruTagsService.menuHomePage().help);
                break
        }

        if (botmakerChat) {
            botmakerChat.style.display = 'flex';
        }
    }

    const menu = {
        init: undefined,
        requisits: undefined,
        aboutCredits: undefined,
        howMuchItCost: undefined,
        howToPay: undefined,
        help: undefined
    }

    let botmakerChat;

    onMount(() => {

        setTimeout(() => {
            botmakerChat = document.querySelector('[name="Botmaker"]');

        },800);
    });


    $:if(open) {
        if (botmakerChat) {
            botmakerChat.style.display = 'none';
        }
    }

    function resolveRoute(url) {
        return environment.assets + url;
    }
</script>

<style>
    .nav {
        position: fixed;
        z-index: 2;
        left: 0;
        top: 0;
        overflow: hidden;
        transition: 0.5s;
        width: 100%;
        visibility: hidden;
        transition: height .8s ease-in-out, opacity .7s ease-in-out, visibility .6s ease-in-out;
        opacity: 0;
    }

    .nav__header {
        position: relative;
        box-sizing: border-box;
        padding: 32px;
    }

    .nav__list {
        position: relative;
        display: flex;
        height: calc(100% - 90px - 60px);
    }

    .nav__list-item {
        position: relative;
        padding: 0px;
        box-sizing: border-box;
        text-align: center;
        border-bottom: 1px solid rgba(var(--white), .3);
        align-items: center;
        flex-direction: column;
        justify-content: center;
        line-height: 34px;
        font-weight: bold;
        font-size: 1.5em;
        cursor: pointer;
        margin: 0;
        visibility: hidden;
        transition: opacity 2s ease-in-out;
        opacity: 0;
    }

    .nav_open {
        height: 100%;
        opacity: 1;
        visibility: visible;
        height: 100%;
    }

    .nav_open .nav__list-item {
        animation: fadeInRight .45s ease forwards;
        animation-delay: 0s;
        visibility: visible;
        opacity: 1;
        height: 100%;
    }

    .nav_open .nav__list-item:hover {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .nav_open .nav__list-item:nth-child(1) {
        animation-delay: .15s;
    }

    .nav_open .nav__list-item:nth-child(2) {
        animation-delay: .25s;
    }

    .nav_open .nav__list-item:nth-child(3) {
        animation-delay: .35s;
    }

    .nav_open .nav__list-item:nth-child(4) {
        animation-delay: .45s;
    }

    .nav_open .nav__list-item:nth-child(5) {
        animation-delay: .55s;
    }

    .nav_open .nav__list-item:nth-child(6) {
        animation-delay: .65s;
    }

    .nav__list-img {
        transform: rotate(90deg);
        position: absolute;
        width: 48px;
        height: auto;
        right: -24px;
        z-index: 2;
        display: none;

    }

    .nav__footer {
        position: relative;
        box-sizing: border-box;
        padding: 6px 16px;

    }

    .nav__footer-items {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        list-style: none;
        width: 100%;

    }

    .nav__footer-item {
        padding: 0;
        margin: 0;
        font-size: .875em;
    }

    @keyframes fadeInRight {
        0% {
            opacity: 0;
            left: 20%;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    @media (min-width:992px) {

        .nav__header {
            position: absolute;
            max-width: 1200px;
            width: 0 auto;
            width: 100%;
        }

        .nav__list {
            flex-direction: row;
            height: calc(100vh - 120px);
        }

        .nav__list-item {
            border-bottom: none;
            border-right: 1px solid rgba(var(--white), .3);
            width: calc(100vw / 4);
            font-size: 1.8em;
            transition: .4s all ease-in-out;
            background-image: transparent;
        }

        .nav__list-item:last-child {
            border-right: none;
        }

        .nav__list-img {
            display: block;
        }

        .nav__footer {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 60px;
            height: 120px;
        }

        .nav__footer-items {
            max-width: 500px;
            margin: 0;
            padding: 0;
        }

        .nav__footer-item {
            display: block;
            font-size: 1.125em;
            cursor: pointer;
        }
    }</style>

<div class="nav bgr-brand-1 w-100" class:nav_open={open==true}>
    <div class="nav__header d-flex flex-row justify-content-between align-items-center">
    </div>
    <ul class="nav__list p-0 m-0 d-flex flex-col">
        <li
            class="t-white nav__list-item d-flex"
            on:click="{()=>{handlerNavigation('/calcula-tu-credito')}}"
            bind:this={menu.init}
            on:mouseover={() => {menu.init.style.backgroundImage=`url(${resolveRoute("/images/menu/inicio.jpg")})`;}}
            on:mouseout={() => {menu.init.style.backgroundImage='none';}}
        >
            Pedir mi crédito ya
            <img loading="lazy" class="nav__list-img lazyload" data-src={resolveRoute("/images/gif/zigzag1.gif")} alt="" />
        </li>
        <li
            class="t-white nav__list-item d-flex"
            on:click="{()=>{handlerNavigation('/acerca-de-tu-credito/nuestros-requisitos')}}"
            bind:this={menu.requisits}
            on:mouseover={() => {menu.requisits.style.backgroundImage=`url(${resolveRoute("/images/menu/requisitos.jpg")})`;}}
            on:mouseout={() => {menu.requisits.style.backgroundImage='none';}}
        >
            Requisitos
            <img loading="lazy" class="nav__list-img lazyload" data-src={resolveRoute("/images/gif/zigzag1.gif")} alt="" />
        </li>
        <li
            class="t-white nav__list-item d-flex"
            on:click="{()=>{handlerNavigation('/registro')}}"
            bind:this={menu.aboutCredits}
            on:mouseover={() => {menu.aboutCredits.style.backgroundImage=`url(${resolveRoute("/images/menu/acerca_del_credito.jpg")})`;}}
            on:mouseout={() => {menu.aboutCredits.style.backgroundImage='none';}}
        >
            Registrarme
            <img loading="lazy" class="nav__list-img lazyload" data-src={resolveRoute("/images/gif/zigzag1.gif")} alt="" />
        </li>
        <li
            class="t-white nav__list-item d-flex"
            on:click="{()=>{handlerNavigation('/acerca-de-tu-credito/como-pagar')}}"
            bind:this={menu.howToPay}
            on:mouseover={() => {menu.howToPay.style.backgroundImage=`url(${resolveRoute("/images/menu/como_pagar.jpg")})`;}}
            on:mouseout={() => {menu.howToPay.style.backgroundImage='none';}}
        >
            ¿Cómo pagar?
            <img loading="lazy" class="nav__list-img lazyload" data-src={resolveRoute("/images/gif/zigzag1.gif")} alt="" />
        </li>
        <li
            class="t-white nav__list-item d-flex"
            on:click="{()=>{handlerNavigation('/acerca-de-tu-credito/cuanto-cuesta')}}"
            bind:this={menu.howMuchItCost}
            on:mouseover={() => {menu.howMuchItCost.style.backgroundImage=`url(${resolveRoute("/images/menu/cuanto_vale.jpg")})`;}}
            on:mouseout={() => {menu.howMuchItCost.style.backgroundImage='none';}}
        >
            ¿Cuánto cuesta?
        </li>
        <li
            class="t-white nav__list-item d-flex d-block d-md-none"
            on:click="{()=>{handlerNavigation('/preguntas-frecuentes')}}"
            bind:this={menu.help}
            on:mouseover={() => {menu.help.style.backgroundImage=`url(${resolveRoute("/images/menu/como_pagar.jpg")})`;}}
            on:mouseout={() => {menu.help.style.backgroundImage='none';}}
        >
            Ayuda
        </li>
    </ul>
    <div class="nav__footer t-white bgr-primary-700 t-center d-flex flex-col mb-0 w-100">
        <ul class="nav__footer-items mt-1 p-6">
            <li class="t-white nav__footer-item t-weight-medium cursor-pointer">
                <a class="t-white" href="/" on:click|preventDefault="{()=>{handlerNavigation('/')}}">Inicio</a>
            </li>
            <li class="t-white t-weight-medium d-block">
               <span> | </span>
            </li>
            <li class="t-white nav__footer-item t-weight-medium cursor-pointer">
                <a class="t-white" href="/" on:click|preventDefault="{()=>{handlerNavigation('/olvide-contrasena')}}">
                    Olvidé mi contraseña
                </a>
            </li>
        </ul>
    </div>

    <span class="d-none qwerty"></span>
</div>
