<script>
  import {
    mdiHome,
    mdiLoading
  } from '@mdi/js';
  import '@zinobe-co/components/input';
  import '@zinobe-co/components/breadcrumb';
  import '@zinobe-co/components/snackbar';
  import Icon from '../../components/Icon.svelte';
  import lineruIcons from '../../constants/lineru-icons.js';
  import ForgotPassService from './_services/forgot-pass.js';
  import NewPassService from '../cambiar-contrasena/_services/new-pass.js';
  import { environment } from '../../environment.js';
  import GoogleTagManagerService from '../../services/tag-manager.js';
  import LineruTagsService from '../../services/lineru-tags.js';
  import Validate from '../../services/validate.js';
  import { load as loadRecaptcha } from 'recaptcha-v3';
  import { metatags } from '@roxi/routify';
  import { onMount, tick } from 'svelte';

  const forgotPassService = new ForgotPassService();
  const newPassService = new NewPassService();
  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: '',
      url: '/',
    },
    {
      text: 'Olvidé mi contraseña',
    },
  ];

  let numDocFieldIsValid = true;
  let numDocErrorMessage;
  let numDocValue;
  let touched;
  let validHash = true;
  let forgotPassAlert = {
    show: false,
    type: 'success',
    message: ''
  }
  let disabledForgotPassBtn = false;

  function handlerNumDocField(e) {
    numDocValue = e.target.value;
    if (!Validate.required(numDocValue)) {
      numDocFieldIsValid = false;
      numDocErrorMessage = 'Ingresa tu número de documento';
    } else if (!Validate.onlyNumbers(numDocValue)) {
      numDocFieldIsValid = false;
      numDocErrorMessage = 'Ingresa sólo números';
    } else if (!Validate.maxLength(numDocValue, 15)) {
      numDocFieldIsValid = false;
      numDocErrorMessage = 'Sólo se admiten hasta 15 caracteres';
    } else {
      numDocFieldIsValid = true;
    }
  }

  function handlerSendForgotPass() {
    touched = true;
    let e = { target: { value: numDocValue } };

    handlerNumDocField(e);

    if (!numDocFieldIsValid) {
      return;
    }

    disabledForgotPassBtn = true;

    const data = {
      document: numDocValue,
      recaptcha_token: '',
      recaptcha_event: "forgot_password_event"
    };

    loadRecaptcha(environment.recaptchaSiteKey).then((recaptcha) => {
      recaptcha.execute('submit').then((token) => {
        forgotPassService.forgotPass({
          ...data,
          recaptcha_token: token
        })
          .then(() => {
            forgotPassAlert.show = true;
            forgotPassAlert.message = '¡Listo! Si encontramos una cuenta asociada a tu cédula, te enviaremos las instrucciones a tu correo electrónico';

            GoogleTagManagerService.setTag(LineruTagsService.forgotPass().submitForm);
            disabledForgotPassBtn = false;
          });
      });
    });
  }

  onMount(async () => {
    validHash = newPassService.getValidHash();

    document.addEventListener('keypress', e => {
      if(e.charCode === 13) {
        handlerSendForgotPass();
      }
    });

    loadRecaptcha(environment.recaptchaSiteKey, {
      explicitRenderParameters: {
        badge: 'bottomleft'
      }
    });

    await tick();
    metatags.title = 'Olvidé mi contraseña | Lineru';
    metatags.description = '¿Olvidaste tu contraseña Lineru? No te preocupes, a todos nos ha pasado. Acá puedes recuperarla de forma segura y sencilla en 5 minutos. ';
  });
</script>

<style>
  .forgot-pass__breadcrumbs {
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .loading-icon {
    animation: mdi-spin 1s infinite linear;
  }

  @keyframes mdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }</style>

<div
  class="forgot-pass__breadcrumbs w-11/12 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2
  t-size-1 d-none d-md-block">
  <znb-breadcrumb data={breadcrumbData} />
</div>

<div class="pt-8 pt-md-1 pb-8 flex-grow d-flex align-items-center">
  <div class="container d-grid grid-12">
    {#if !validHash}
      <div class="col-12 pt-0 pt-md-6">
        <h2 class="t-h4 mb-4">
          Tu enlace caducó.
        </h2>

        <p class="t-size-4 mb-10 mb-md-18">
          Parece que llevaba un tiempo en tu buzón, pero no te preocupes. Podemos enviártelo de nuevo. Solo completa esta información.
        </p>
      </div>
    {/if}

    <div
      class="col-start-1 col-sm-start-2 col-md-start-3 col-lg-start-4 col-12
      col-sm-10 col-md-8 col-lg-6">

      <h1 class="t-h2 mb-6">Olvidé mi contraseña</h1>

      <p class="mb-6 t-size-4">
        Escribe tu número de documento. Así podemos enviarte un enlace a tu correo para reestablecer tu contraseña.
      </p>

      <znb-input
        id="num-doc"
        oninput={handlerNumDocField}
        label="Número de documento"
        valid={numDocFieldIsValid}
        errormessage={numDocErrorMessage}
        helpmessage="
        "
        placeholder="Escribe tu número de documento"
        {touched}
        class="d-block mb-6" />

      <button
        class="btn btn--shadow bgr-primary-500 bgr-brand-2:active t-white btn-lg w-100 w-sm-auto d-flex t-left align-items-center"
        role="button"
        class:disabled={disabledForgotPassBtn}
        on:click={handlerSendForgotPass}
        aria-labelledby
        >
        <span class="flex-grow">Enviar enlace</span>
        {#if !disabledForgotPassBtn}
          <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
        {:else}
          <svg class="w-6 h-auto t-yellow ml-3 -mr-3 loading-icon" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiLoading} />
          </svg>
        {/if}
      </button>

      <znb-snackbar
        class="mt-12 d-block"
        dismissible="true"
        show={forgotPassAlert.show}
        type={forgotPassAlert.type}
        on:close={() => forgotPassAlert.show = false }
      >
        {forgotPassAlert.message}
      </znb-snackbar>
    </div>
  </div>
</div>
