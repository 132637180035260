<script>
	import Images from './Images.svelte';
  import { mdiClose } from '@mdi/js';


  export let closeUrl = '/';

</script>

<div class="modal-video w-100 h-100 relative">
  <Images imgClass="d-block lazyload" src='/images/formas/ola-circulo.svg' alt=""/>
  <div class="modal-video__wrapper container flex-col py-10">
    <div class="d-flex justify-content-end align-items-center mb-10 mb-lg-18 w-100">
      <a
        href={closeUrl}
        class="p-2 bdr-2 bdr-solid bdr-primary-500 bdr-radius-1"
        role="button"
      >
        <svg class="w-6 t-white d-block" height="100%" viewBox="0 0 24 24">
          <path fill="currentColor" d={mdiClose} />
        </svg>
      </a>
    </div>
    <slot />
  </div>
</div>

<style>
  .modal-video__wrapper {
    min-height: 100%;
    position: relative;
  }

  .modal-video :global(img) {
    position: absolute;
    top: 32px;
    left: 50%;
    z-index: 0;
    margin-left: -45px;
    width: 90px;
  }</style>
