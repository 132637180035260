<script>
  import Icon from '../../components/Icon.svelte';
  import lineruIcons from '../../constants/lineru-icons.js';
  import { goto } from '@roxi/routify';
  import GoogleTagManagerService from '../../services/tag-manager.js';
  import LineruTagsService from '../../services/lineru-tags.js';
  import { environment } from '../../environment';

  function resolveRoute(url) {
    return environment.assets + url;
  }

  function goToCalculator() {
    $goto('/calcula-tu-credito');
    GoogleTagManagerService.setTag(LineruTagsService.home().calculateCost);
  }

  function goToHowItWorks() {
    $goto('/acerca-de-tu-credito');
    GoogleTagManagerService.setTag(LineruTagsService.home().howItWorks);
  }
</script>

<div class="fold-6">
  <div class="container pt-10 pt-md-16 pt-lg-22 pb-10 pb-md-14 pb-lg-20">
    <h2 class="mb-12">
      Pide tu crédito 100% en línea en
      <br class="d-none d-xl-inline">
      cuatro pasos.
    </h2>
    <div class="d-grid grid-12 gap-6 mb-6 mb-sm-8 mb-md-14">
      <div class="col-12 col-sm-6 col-md-3 mb-1 mb-md-0" data-aos="fade-down" data-aos-duration="500">
        <div
          class="w-12 h-12 bgr-warning-300 t-weight-bold d-flex align-items-center justify-content-center mb-6 bdr-radius-6 t-h3 t-primary-800">
          1
        </div>
        <h3 class="t-h6 mb-4 d-none d-md-block">
          Calcula tu
          <br>
          crédito
        </h3>
        <!-- mobile -->
        <h3 class="t-h6 mb-4 d-block d-md-none">
          Calcula
        </h3>
        <p class="mb-3 flex-grow">
          Sin ningún compromiso. Dinos cuánto dinero necesitas. Cuándo podrías pagarlo. Y mira cómo se ven los números.
          <strong>Toma 1 minuto</strong>.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 mb-1 mb-md-0" data-aos="fade-down" data-aos-duration="1000">
        <div
          class="w-12 h-12 bgr-warning-300 t-weight-bold d-flex align-items-center justify-content-center mb-6 bdr-radius-6 t-h3 t-primary-800">
          2
        </div>
        <h3 class="t-h6 mb-4 d-none d-md-block">
          Regístrate y
          <br>
          pídelo
        </h3>
        <!-- mobile -->
        <h3 class="t-h6 mb-4 d-block d-md-none">
          Pídelo
        </h3>
        <p class="mb-3 flex-grow">
          ¿Estás de acuerdo con los números? Perfecto. Ahora crea tu cuenta en Lineru y completar el formulario.<br
            class="d-none d-xl-inline"><strong>Toma 10 minutos</strong>.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 mb-1 mb-md-0" data-aos="fade-down" data-aos-duration="1500">
        <div
          class="w-12 h-12 bgr-warning-300 t-weight-bold d-flex align-items-center justify-content-center mb-6 bdr-radius-6 t-h3 t-primary-800">
          3
        </div>
        <h3 class="t-h6 mb-4 d-none d-md-block">
          Firma tu
          <br>
          contrato
        </h3>
         <!-- mobile -->
        <h3 class="t-h6 mb-4 d-block d-md-none">
          Firma
        </h3>
        <p class="mb-3 flex-grow">
          ¡Solicitud aprobada! solo te queda firmar. Para eso te envíamos dos códigos. Y con ellos haces tu firma
          digital. <strong>Toma 2 minutos</strong>.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 mb-1 mb-md-0" data-aos="fade-down" data-aos-duration="2000">
        <div
          class="w-12 h-12 bgr-warning-300 t-weight-bold d-flex align-items-center justify-content-center mb-6 bdr-radius-6 t-h3 t-primary-800">
          4
        </div>
        <h3 class="t-h6 mb-4 d-none d-md-block">
          Recibe el
          <br>
          dinero
        </h3>
         <!-- mobile -->
        <h3 class="t-h6 mb-4 d-block d-md-none">
          Recíbelo
        </h3>
        <p class="mb-3 flex-grow">
          Una vez firmes ponemos el dinero en tu cuenta. Normalmente lo hacemos en 4 horas. <strong>Y máximo nos toma
            un<br class="d-none d-md-inline">día hábil</strong>.
        </p>
      </div>
    </div>
    <div class="t-center">
      <!-- mobile -->
      <a
        class="d-inline-block d-md-none btn btn-lg btn--shadow bgr-primary-500 t-white:hover bgr-brand-2:active t-white mb-3"
        href="/"
        on:click|preventDefault={goToCalculator}
        role="button"
      >
        Calcular mi crédito
        <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
      </a>
      <a
        class="d-inline-block btn btn-lg bgr-primary-500-alpha-1 t-primary-500 t-brand-2:hover mb-3 mr-0 mr-sm-3"
        href="/"
        on:click|preventDefault={goToHowItWorks}
        role="button"
      >
        ¿Cómo funciona?
      </a>
      <br class="d-inline d-sm-none">
      <a
        class="d-none d-md-inline-block btn btn-lg btn--shadow bgr-primary-500 t-white:hover bgr-brand-2:active t-white"
        href="/"
        on:click|preventDefault={goToCalculator}
        role="button"
      >
        Calcula tu crédito
        <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
      </a>
    </div>
  </div>
  <div class="fold-6__bg-patron h-100" style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}');"></div>
</div>

<style>
  .fold-6 {
    position: relative;
  }

  .fold-6__bg-patron {
    width: 12%;
    bottom: 0;
    position: absolute;
    right: 0;
  }</style>
