<script>
  import { mdiHome } from '@mdi/js';
  import '@zinobe-co/components/breadcrumb';
  import { environment } from '../../../environment.js';

  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: '',
      url: '/',
    },
    {
      text: 'Preguntas frecuentes',
    },
  ];

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div
  class="fold-1 bgr-brand-2 pt-28 -mt-28 pt-lg-24 -mt-lg-24 pb-30"
  style="background-image: url('{resolveRoute("/images/preguntas-frecuentes/imagen-fold-1.jpg")}')"
>
  <div class="t-size-2 py-2 px-5 px-lg-8 px-xl-14 py-lg-2 w-11/12 t-white d-none d-md-block mx-auto">
    <znb-breadcrumb data={breadcrumbData} />
  </div>

  <div class="container">
    <div class="d-grid grid-12 gap-5 pt-10 pt-lg-16 pt-xl-30">
      <div class="col-12 col-lg-8 col-xl-7 pb-10 pb-md-12 pb-lg-16 t-white">
        <h1 class="mb-5 mb-md-8" data-aos="fade-right">
          ¿Tienes dudas?
          <br class="d-none d-xl-inline">
          Levanta la mano.
        </h1>
        <div class="d-grid grid-7 gap-5">
          <p class="col-12 col-md-5 col-xl-4 t-size-3 t-sm-size-5 mb-0">
            Si eres nuevo en Lineru entendemos que puedas tener preguntas. Encuentra acá las respuestas. O ponte en contacto con nosotros.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .fold-1 {
    background-image: none;
    background-size: cover;
  }</style>
