<script>
  import { mdiHome } from '@mdi/js';
  import '@zinobe-co/components/breadcrumb';
  import { environment } from '../../../environment.js';

  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: '',
      url: '/',
    },
    {
      text: 'Mundo Lineru',
    },
  ];

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-1-1 pt-28 -mt-28 pt-lg-24 -mt-lg-24">
  <div class="t-size-2 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2 fold-1-1__breadcrumbs w-11/12 d-none d-md-block mx-auto t-white">
    <znb-breadcrumb data={breadcrumbData} />
  </div>

  <div class="container fold-1-1__container">
    <div class="d-grid grid-12 gap-5 align-items-start">
      <div class="col-12 col-lg-8 col-xl-7 pb-10 pb-lg-14 pt-10 pt-lg-16 pt-xl-20">
        <h1 class="mb-14 mb-md-16 t-gold">
          Mundo Lineru
          <br class="d-inline d-sm-none d-xl-inline">
          Acelera tu vida financiera
        </h1>
        <div class="d-grid grid-7 gap-5">
          <p class="col-12 col-md-5 t-size-3 t-sm-size-5">
            Con tu cupo en Lineru pide dinero en minutos cada vez que lo necesites.
            Y acumula puntos que puedes cambiar por beneficios en tus próximos desembolsos.
          </p>
        </div>
      </div>

      <div class="col-4 col-xl-5 align-self-end d-none d-lg-block" data-aos-offset="0" data-aos="fade-up">
        <picture aria-hidden="true">
          <source srcset="/images/mundo-lineru/chica-mundo.webp" type="image/webp">
          <img loading="lazy" class="img-fluid d-block lazyload" data-src={resolveRoute('/images/mundo-lineru/chica-mundo.png')} alt="">
        </picture>
      </div>
    </div>
  </div>

  <div class="fold-1-1__background-1" style="background-image: url({resolveRoute('/images/patrones/patron-6.png')})"></div>
  <div class="fold-1-1__background-2" style="background-image: url({resolveRoute('/images/patrones/patron-marca.png')})"></div>
</div>

<div class="py-0 py-sm-8 py-md-10 py-lg-20 fold-1-2">
  <div class="container">
    <h2 class="t-center t-gold fold-1-2__container">
      Con tu cupo de crédito abres todas las
      <br class="d-none d-lg-inline">
      puertas de Mundo Lineru.
    </h2>
  </div>

  <div
    class="fold-1-2__background"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
</div>

<style>
  .fold-1-1 {
    position: relative;
    z-index: 0;
  }

  .fold-1-1__container {
    position: relative;
    z-index: 1;
  }

  .fold-1-1__background-1 {
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58%;
    z-index: 0;
  }

  .fold-1-1__background-2 {
    position: absolute;
    top: 58%;
    left: 0;
    width: 100%;
    height: 15%;
    z-index: 0;
  }

  .fold-1-1__breadcrumbs {
    z-index: 1;
    position: relative;
  }

  .fold-1-2 {
    position: relative;
  }

  .fold-1-2__container {
    position: relative;
    z-index: 1;
  }

  .fold-1-2__background {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 25%;
    width: 50%;
    height: 100px;
    z-index: 0;
  }

  @media (min-width: 352px) {
    .fold-1-1__background-1 {
      height: 57%;
    }

    .fold-1-1__background-2 {
      top: 57%;
    }
  }

  @media (min-width: 576px) {
    .fold-1-1__background-1 {
      height: 66%;
    }

    .fold-1-1__background-2 {
      top: 66%;
    }
  }

  @media (min-width: 576px) {
    .fold-1-1__background-1 {
      height: 66%;
    }

    .fold-1-1__background-2 {
      top: 66%;
    }
  }

  @media (min-width: 768px) {
    .fold-1-1__background-1 {
      height: 63%;
    }

    .fold-1-1__background-2 {
      top: 63%;
    }
  }

  @media (min-width: 1200px) {
    .fold-1-1__background-1 {
      height: 64%;
    }

    .fold-1-1__background-2 {
      top: 64%;
    }
  }</style>
