<script>
  import { onDestroy, onMount, tick } from 'svelte';
  import { route, goto } from '@roxi/routify';
  import {
    mdiInformationOutline,
    mdiClose,
    mdiGift
  } from '@mdi/js';
  import LogoLineruMobile from './../../components/logos/LineruMobile.svelte';
  import LogoLineru from './../../components/logos/Lineru.svelte';
  import CalculatorService from '../../services/calculator.js';
  import WithoutLineruModal from './_components/WithoutLineruModal.svelte';
  import Icon from '../../components/Icon.svelte';
  import LineruDatePicker from '../../components/LineruDatePicker.svelte';
  import DateTasksService from '../../services/date-tasks.js';
  import lineruIcons from '../../constants/lineru-icons.js';
  import Validate from '../../services/validate.js';
  import '@zinobe-co/components/tooltip';
  import '@zinobe-co/components/input';
  import '@zinobe-co/components/datepicker';
  import '@zinobe-co/components/range';
  import GoogleTagManagerService from '../../services/tag-manager.js';
  import LineruTagsService from '../../services/lineru-tags.js';
  import ClevertapService from '../../services/clevertap.js';
  import clevertapEvents from './../../enums/clevertap-events.enum.js';
  import LineruClevertapTagsService from '../../services/clevertap-tags';
  import { calculatoDataStore } from './../../stores/calculator-data-store.js';
  import tooltipsCalculator from '../../enums/calculator-tooltips.enum.js';
  import Images from '../../components/Images.svelte';
  import CurrentRatesService from '../../services/current-rates.js';

  const calService =  new CalculatorService();
  const currentRatesService = new CurrentRatesService();

  let calculatorDetails;

  // UI vars
  let closeUrl = '/';
  let showNoLineruWorld;

  // Calculator vars
  let max = 1200000;
  let min = 150000;
  let multiple = 10000;
  let valueFieldIsValid = true;
  let valueErrorMessage = '';
  let warningValue;
  let helpMessageValue = 'Ingresa multiplos de $10.000';
  let rangeIsValid;
  let datepickerIsValid;
  let value = 0;
  let touched;
  let datePickerTouched;
  let days = 30;
  let datepickerValue = DateTasksService.formatDate(DateTasksService.addDays(new Date(), days));
  let datepickerStatus = false;
  let calcValues = {};
  let elAmount;
  let elSlider;
  let elDatePicker;
  let originalDate = DateTasksService.addDays(new Date(), days);
  let labelComplement1 = '¿Cuándo puedes pagar? Una cuota <strong>máx de ';
  let labelComplement2 = ' días</strong>';
  let labelDatePicker = (label1, days, label2) => `${label1}${days}${label2}`;
  let elCalculatorContainer;
  let interestPercent;

  onMount(async () => {
    calculatoDataStore.subscribe(calculatorData =>{
      calculatorDetails = calculatorData;
    });

    /* UI features - BEGIN */
    closeUrl = calService.getCloseUrl($route.last.shortPath, $route.last.params);
    value = calculatorDetails.amount > 0 ? calculatorDetails.amount : calService.getAmount();
    /* UI features - END */

    /* Calculator features - BEGIN */
    // Callback amount input
    elAmount.addEventListener('input', e => {
      onChangeAmount(e);
    })

    // Callback amount slider for desktop
    elSlider.addEventListener('mouseup', e => {
      onChangeAmount(e);
    });

    // Callback amount slider for desktop
    elSlider.addEventListener('touchend', e => {
      onChangeAmount(e);
    });

    // Callback datepicker
    elDatePicker.value = datepickerValue;
    elDatePicker.addEventListener('datepicker-input', e => {
      onChangeDate(e);
    });

    // Get initial values for calculator();
    onChangeAmount({ target: { value } });
    onChangeDate({
      detail: {
        value: DateTasksService.formatDate(DateTasksService.addDays(new Date(), days)),
        originalVal: DateTasksService.addDays(new Date(), days)
      }
    });
    /* Calculator features - END */
    // Set date calculator data
    if(calculatorDetails.pay_date !== '') {
      onChangeDate({
        detail: {
          value: DateTasksService.formatDate(getOriginalDate(calculatorDetails.pay_date)),
          originalVal: DateTasksService.addDays(new Date(), days)
        }
      })
    }

    // Get rates for taxes
    await tick();
    currentRatesService.subscribe(setAdminRate);
  });

  onDestroy(() => {
    currentRatesService.unsubscribe();
  });

  function onChangeAmount(e) {
    touched = true;
    let valueToString = e.target.value.toString();
    let v = Number(valueToString.replace(/[^0-9]/g, ''));

    if (v > max) {
      rangeIsValid = false;
      warningValue = false;
      valueErrorMessage = 'Recuerda el valor máximo que puedes aplicar es de $1.200.000';
    } else if (v < min) {
      rangeIsValid = false;
      warningValue = false;
      valueErrorMessage = 'Recuerda el valor mínimo que puedes aplicar es de $150.000';
    } else if (v % multiple !== 0) {
      rangeIsValid = false;
      warningValue = false;
      valueErrorMessage = 'Ingresa múltiplos de $10.000';
    } else {
      rangeIsValid = true;
      warningValue = false;
      valueErrorMessage = 'Ingresa múltiplos de $10.000';
    }

    if (v > 700000 && rangeIsValid) {
      warningValue = true;
      valueErrorMessage = 'Si es tu primera vez, sólo puedes pedir $700.000';
    }

    value = v;

    // Only for test porpouses
    if (rangeIsValid) {
      getCalculatorValues(value, days);
    }
  }

  function getOriginalDate(dateString) {
    var dateParts = dateString.split('/');
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }

  function onChangeDate(e) {
    datePickerTouched = true;
    const today = new Date();
    datepickerValue = e.detail && e.detail.value;

    if(!Validate.required(datepickerValue)) {
      datepickerStatus = false;
      elDatePicker.errormessage = 'La fecha de pago es requerida';
      originalDate = new Date();
    } else if(!Validate.dateFormat(datepickerValue)) {
      datepickerStatus = false;
      elDatePicker.errormessage = 'El formato de fecha no es válido';
      originalDate = new Date();
    } else {
      originalDate = getOriginalDate(datepickerValue);
      days = Math.abs(Math.ceil((originalDate - today) / 1000 / 60 / 60 / 24));

      if (days < 4 || days > 30) {
        originalDate = new Date();
        datepickerStatus = false;
        days = '';
        labelComplement1 = '¿Cuándo puedes pagar?';
        labelComplement2 = '';
        elDatePicker.errormessage = 'Elije entre 4 y 30 días para pagar tu cupo de crédito';
      } else {
        elDatePicker.value = datepickerValue;
        datepickerStatus = true;
        if (days == 30) {
          if(window.innerWidth < 576) {
            labelComplement1 = '¿Cuándo puedes pagar? <strong>máx en ';
          } else {
            labelComplement1 = '¿Cuándo puedes pagar? Una cuota <strong>máx de ';
          }
        } else {
          labelComplement1 = '¿Cuándo puedes pagar? <strong>en ';
        }
        labelComplement2 = ' días</strong>';
        getCalculatorValues(value, days);
      }
    }
  }

  function getCalculatorValues(amount, days){
    calService.getCalculatorValues({ amount, days }).then(res => {
      calcValues = res;

      if(calcValues){
        calculatoDataStore.update(calculatorData => {
          return {
            ...calculatorData,
            amount,
            pay_date: datepickerValue,
            disbursement: calcValues.amount,
            total: calcValues.total
          }
        })
      }
    })

  }

  function submitData() {
    if (!datepickerStatus || !rangeIsValid) {
      validateScroll([
        elAmount,
        elSlider,
        elDatePicker
      ]);
      return;
    }

    calService.setCalculator({
      amount: value,
      date: originalDate.toISOString(),
      days
    });

    GoogleTagManagerService.setTag(LineruTagsService.calculator().submitForm(value));

    GoogleTagManagerService.setTag(LineruTagsService.calculator().submitFormv2({ value, paydays: days }));

    ClevertapService.eventPush(clevertapEvents.calculalatorHome, {
      amount: value,
      days: days,
      zinobe_product_name: 'Lineru'
    });

    $goto('/registro');
  }

  function goToHome() {
    $goto('/');
    GoogleTagManagerService.setTag(LineruTagsService.calculator().home);
  }

  function numberFormat(value) {
    let v = value || 0;
    return `$${new Intl.NumberFormat('de-DE').format(parseFloat(v))}`
  }

  function handleDate(e) {
    onChangeDate({
      detail: {
        value: e.detail.value,
        originalVal: e.detail.originalVal
      }
    });
  }

  function validateScroll(fields = []) {
    const viewWidth = window.innerWidth;
    let invalidField = null;

    for(let field of fields) {
      if(!field.valid) {
        invalidField = field;
        break;
      }
    }

    invalidField.focus();

    if (viewWidth < 768) {
      const topPos = invalidField.getBoundingClientRect().top + elCalculatorContainer.scrollTop - 20;
      elCalculatorContainer.scroll({
        top: topPos,
        behavior: 'smooth'
      });
    }
  }

  function setAdminRate(rates) {
    interestPercent = rates.interest;
  }
</script>

<style>
  .calculator {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .tooltip-calc {
    z-index: 1;
  }

  @media (max-height: 870px) {
    .calculator {
      overflow-y: auto;
    }
  }

  .calculator__wrapper {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .calculator__bg-image :global(img) {
    position: absolute;
    top: 50px;
    left: 50%;
    z-index: 0;
    margin-left: -45px;
    width: 90px;
    height: auto;
  }

  .calculator__content__form {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    background-color: transparent;
  }

  .calculator__content__costs {
    border-radius: 0.25rem;
  }

  @media (min-width: 768px) {
    .calculator__content__form {
      background-color: rgb(var(--info-900));
    }

    .calculator__content__costs {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .datepicker-btn {
    position: absolute;
    right: 6px;
    top: 39px;
    z-index: 1;
  }

  .calculator-mobile-btn {
    position: fixed;
    bottom: 0;
    left: 0;
  }</style>

<div
  bind:this={elCalculatorContainer}
  class="calculator bgr-brand-5">
  <div class="calculator__bg-image">
    <Images imgClass="d-block w-18 d-none d-lg-block lazyload"
    src='/images/formas/ola-circulo.svg'
    alt="" />
  </div>
  <div class="calculator__wrapper container flex-col pt-10 pb-20 py-md-16">
    <div
      class="d-flex justify-content-between align-items-center mb-6 mb-sm-10 mb-lg-18
      w-100">
      <a
        on:click|preventDefault={goToHome}
        href="/">
        <div class="d-none d-lg-block">
          <LogoLineru fillColorVar="--white" width="128px" height="100%" />
        </div>

        <div class="d-block d-lg-none">
          <LogoLineruMobile fillColorVar="--white" width="23px" height="100%" />
        </div>
      </a>

      <a
        href={closeUrl}
        class="p-2 bdr-2 bdr-solid bdr-primary-500 bdr-radius-1">
        <svg class="w-6 t-white d-block" height="100%" viewBox="0 0 24 24">
          <path fill="currentColor" d={mdiClose} />
        </svg>
      </a>
    </div>

    <div class="d-grid grid-2 mb-8 w-100">
      <div
        class="py-4 py-sm-8 mb-0 px-0 px-md-6 px-lg-8 t-center t-white col-2 col-md-1 calculator__content__form">
        <h2 class="t-h4 mt-2">
          Solicita tu cupo de crédito en 10 minutos
        </h2>

        <p class="t-size-5 mt-6 mb-10">
          Recibe el dinero en 1 día hábil
        </p>

        <znb-input
          id="value"
          label="¿Cuánto dinero necesitas?"
          valid={rangeIsValid}
          value={value}
          bind:this={elAmount}
          errormessage={valueErrorMessage}
          helpmessage="Ingresa multiplos de $10.000"
          placeholder=""
          darktheme="true"
          {touched}
          warning={warningValue}
          class="d-block mb-6 t-left" />

        <div class="d-flex align-items-center w-100 mb-6">
          <span>{numberFormat(min)}</span>
          <znb-range
            id="range-1"
            valid={rangeIsValid}
            bind:this={elSlider}
            value={value}
            errormessage=""
            placeholder="lala"
            {min}
            {max}
            darktheme="true"
            step="10000"
            class="flex-grow ml-2 mr-2" />
          <span>{numberFormat(max)}</span>
        </div>

        <LineruDatePicker
          on:date-choice={handleDate}
          dateVal={originalDate}
        >
          <div slot="datepickerBtn">
            <div class="datepicker-btn w-5 h-5 cursor-pointer"></div>
          </div>
          <div slot="datepickerInput">
            <znb-datepicker
              label={labelDatePicker(labelComplement1, days, labelComplement2)}
              valid={datepickerStatus}
              bind:this={elDatePicker}
              errormessage=""
              placeholder="Día/Mes/Año"
              helpmessage="Si pagas los primeros 10 días tendrás el 50% de descuento en cargos seleccionados"
              touched={datePickerTouched}
              darktheme="true"
              class="d-block mb-6 t-left" />
          </div>
        </LineruDatePicker>

        <div class="t-left d-none d-md-block mt-10">
          <button
            class="btn btn--shadow bgr-primary-500 bgr-brand-2:active t-white btn-lg"
            role="button"
            on:click={submitData}
            aria-labelledby>
            Pedir mi crédito ya
            <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
          </button>
        </div>
      </div>

      <div class="py-8 col-2 col-md-1 bgr-white calculator__content__costs">
        <h2 class="px-6 px-lg-16 t-h3 mt-0 mt-lg-4 mb-6 mb-lg-8 t-center">
          Estos son los valores a pagar
        </h2>

        <div class="px-6 px-lg-20 bgr-gray-100 t-size-4 py-4 d-grid grid-2 gap-2">
          <p class="mb-0 my-lg-4">Valor solicitado</p>
          <p class="mb-0 my-lg-4 pr-8 d-flex justify-content-end">
            <span class="-mr-1">{numberFormat(calcValues.amount)}</span>
          </p>

          <p class="mb-0 mb-lg-4">
            Interés ({interestPercent}% E.A)
          </p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">{numberFormat(calcValues.interest)}</span>

              <znb-tooltip
                class="mt-1"
                datatitle={calcValues.tooltips && calcValues.tooltips.interest ? calcValues.tooltips.interest : tooltipsCalculator.interest}
                defaultposition="right"
              >
                <svg class="d-block w-5 h-auto t-primary-500" height="100%" viewBox="0 0 24 24">
                  <path fill="currentColor" d={mdiInformationOutline} />
                </svg>
              </znb-tooltip>
          </p>

          <p class="mb-0 mb-lg-4">Seguro</p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">{numberFormat(calcValues.insurance)}</span>
            <znb-tooltip
              class="mt-1"
              datatitle={calcValues.tooltips && calcValues.tooltips.insurance ? calcValues.tooltips.insurance : tooltipsCalculator.insurance}
              defaultposition="right"
            >
              <svg class="d-block w-5 h-auto t-primary-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiInformationOutline} />
              </svg>
            </znb-tooltip>
          </p>

          <p class="mb-0 mb-lg-4">
            Fianza FGA + IVA
          </p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">{numberFormat(calcValues.fgaTotal)}</span>
            <znb-tooltip
              class="mt-1"
              datatitle={calcValues.tooltips && calcValues.tooltips.fga ? calcValues.tooltips.fga : tooltipsCalculator.fga}
              defaultposition="right"
            >
              <svg class="d-block w-5 h-auto t-primary-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiInformationOutline} />
              </svg>
            </znb-tooltip>
          </p>

          <p class="mb-0 mb-lg-4">Administración</p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">{numberFormat(calcValues.admin)}</span>
            <znb-tooltip
              class="mt-1"
              datatitle={calcValues.tooltips && calcValues.tooltips.admin ? calcValues.tooltips.admin : tooltipsCalculator.admin}
              defaultposition="right"
            >
              <svg class="d-block w-5 h-auto t-primary-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiInformationOutline} />
              </svg>
            </znb-tooltip>
          </p>

          <p class="mb-0 mb-lg-4">IVA</p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">{numberFormat(calcValues.iva)}</span>
            <znb-tooltip
              class="mt-1"
              datatitle={calcValues.tooltips && calcValues.tooltips.iva ? calcValues.tooltips.iva : tooltipsCalculator.iva}
              defaultposition="right"
            >
              <svg class="d-block w-5 h-auto t-primary-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiInformationOutline} />
              </svg>
            </znb-tooltip>
          </p>
          {#if calcValues.discountAmount && calcValues.discountPercent}
            <p class="mb-0 d-flex">
              <svg class="d-inline-block w-5 h-auto t-gray-900 pb-2 pb-lg-5" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiGift} />
              </svg>
              <span class="ml-1 pb-1 t-size-4 t-weight-bold d-inline-block d-lg-none">
                Dcto. {calcValues.discountPercent}%
              </span>
              <span class="ml-1 pb-1 t-size-4 t-weight-bold d-none d-lg-inline-block">
                Descuento {calcValues.discountPercent}%
              </span>
            </p>
            <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2 t-weight-bold">
              -{numberFormat(calcValues.discountAmount)}
            </span>
            <znb-tooltip
              class="mt-1"
              datatitle={calcValues.tooltips && calcValues.tooltips.discount ? calcValues.tooltips.discount : tooltipsCalculator.discount}
              defaultposition="right"
            >
              <svg class="d-block w-5 h-auto t-primary-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiInformationOutline} />
              </svg>
            </znb-tooltip>
            </p>
          {/if}
        </div>

        <div
          class="py-2">
        </div>

        <div
          class="px-6 px-lg-20 bgr-gray-100 t-size-5 py-2 t-weight-bold d-grid
          grid-2">
          <p class="mb-0 d-flex">Total a pagar</p>
          <p class="mb-0 mr-6 d-flex justify-content-end">{numberFormat(calcValues.total)}</p>
        </div>
      </div>
    </div>

    <button
      class="btn btn-lg bgr-primary-500 bgr-brand-2:active t-white w-100 t-left d-flex d-md-none align-items-center bdr-radius-0 calculator-mobile-btn"
      role="button"
      on:click={submitData}
      aria-labelledby
      >
      <span class="flex-grow">Pedir mi crédito ya</span>
      <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
    </button>
    <p class="mb-0 t-center t-white">
      Nuestro proceso de aplicación es 100% en línea, fácil y toma menos de 15 minutos.
      <br>
      Si lo prefieres puedes hacer tu <a class="t-brand-2" href="/" on:click|preventDefault={() => showNoLineruWorld.noLineruWorld(true)}>solicitud de forma física</a>, pero te perderás de los beneficios
      de hacer la solicitud en línea.
    </p>
  </div>
</div>

<WithoutLineruModal
  bind:this={showNoLineruWorld}
  urlContext={closeUrl}
></WithoutLineruModal>
