<script>
  import Header from '../../components/Header.svelte';
  import Footer from '../../components/Footer.svelte';
  import { environment } from '../../environment.js';

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="forgot-pass-container flex-grow d-flex flex-col">
  <Header simpleHeader={true}></Header>
  <slot />
  <Footer simpleFooter={true}></Footer>
  <div
    class="forgot-pass-container__background d-none d-sm-block"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
</div>

<style>
  .forgot-pass-container__background {
    width: 18%;
    height: 75%;
    top: 0;
    position: absolute;
    right: 0;
  }</style>
