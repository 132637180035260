<script>
  import { onMount } from 'svelte';
  import '@zinobe-co/components/input';
  import '@zinobe-co/components/modal';
  import CalculatorService from '../../services/calculator.js';
  import Icon from "../../components/Icon.svelte";
  import lineruIcons from "../../constants/lineru-icons.js";
  import { goto } from "@roxi/routify";
  import GoogleTagManagerService from "../../services/tag-manager.js";
  import LineruTagsService from "../../services/lineru-tags.js";
  import { environment } from "../../environment";
  import { mdiClose, mdiChevronRight } from '@mdi/js';

  export let usuraRate, usuraMonthRate, usuraYearRate;

  let scrollPosY;
  let calculatorBtnFixed;
  let calculatorBtnPosY;
  let body;
  let modal;
  const calService =  new CalculatorService();

  export let showLookAnExample = false;

  let valueSanitized = calService.getAmount();
  let value = `$${new Intl.NumberFormat('de-DE').format(parseFloat(valueSanitized))}`;
  let touched = false;
  let label = '¿Cuánto dinero necesitas?';
  let helpText = 'Ingresa múltiplos de $10.000';
  let errorMessages = "";
  let inputValid = true;

  function handlerInput(value) {
    touched = true;
    let v = Number(value.replace(/[^0-9]/g, ''));

    if (v > 1200000) {
      inputValid = false;
      helpText = 'Recuerda el valor máximo que puedes aplicar es de $1.200.000';
      errorMessages = 'Recuerda el valor máximo que puedes aplicar es de $1.200.000';
    } else if(v < 150000){
      inputValid = false;
      helpText = 'Recuerda el valor mínimo que puedes aplicar es de $150.000';
      errorMessages = 'Recuerda el valor mínimo que puedes aplicar es de $150.000';
    } else if (v % 10000 !== 0){
      inputValid = false;
      helpText = 'Ingresa múltiplos de $10.000';
      errorMessages = 'Ingresa múltiplos de $10.000';
    } else {
      inputValid = true;
      helpText = 'Ingresa múltiplos de $10.000';
    }

    valueSanitized = v;
    value = v;
  }

  function handlerFormat(value) {
    touched = true;
    let v = Number(String(value).replace(/[^0-9]/g, ''));
    valueSanitized = v;
    value = `$${new Intl.NumberFormat('de-DE').format(parseFloat(v))}`;
  }

  function calculateCost() {
    calService.setAmount(valueSanitized);
    $goto('/calcula-tu-credito');

    GoogleTagManagerService.setTag(LineruTagsService.home().calculateLoan(valueSanitized));
  }

  function resolveRoute(url) {
    return environment.assets + url;
  }

  function goToOurCredits() {
    window.open('/acerca-de-tu-credito/', '_blank');
    GoogleTagManagerService.setTag(LineruTagsService.home().howItWorks);
  }

  onMount(() => {
    setTimeout(()=> {
      calculatorBtnPosY = calculatorBtnFixed.getBoundingClientRect().top + window.pageYOffset;
    })
  });

  function lookAnExample(state) {
    showLookAnExample = state;
    body = document.querySelector('body');
    modal = document.getElementById('modal')
    if(showLookAnExample){
      body.classList.add('overlay');
      modal.classList.remove('d-none');
    }else {
      body.classList.remove('overlay');
    }
  }

</script>

<svelte:window bind:scrollY={scrollPosY} />

<div
  class="fold-1 d-flex -mt-28 -mt-lg-24 pt-28 pt-lg-14 pb-8 pb-md-0"
  style="background: url('{resolveRoute(
    "/images/patrones/patrones-1.png"
  )}') repeat, linear-gradient(90deg, #96c1c4 60%, #b6d3d8 100%); background-attachment: fixed;"
>
  <div class="container">
    <div class="d-grid grid-12 pt-10 pt-lg-6 position-relative">
      <div class="col-12 col-lg-7 ml-0 pb-6 pb-lg-16 pb-xl-24 pt-lg-8">
        <h1 class="mb-5 mb-md-4 mb-xl-8 pt-12 pt-md-4 pt-lg-2 pt-xl-12 d-none d-md-block" data-aos="fade-left">
          Lineru es dinero
          <br class="d-none d-md-block" />
          sin enredos.
        </h1>
        <!-- Mobile -->
        <h1 class="mb-5 mb-md-8 d-block d-md-none" data-aos="fade-left">
          Lineru es dinero sin enredos.
        </h1>
        <p class="col-12 col-md-10 col-xl-12 mb-2 pr-sm-12 pr-xl-10 t-size-3 t-sm-size-5">
          Pide tu cupo de crédito en 15 minutos y recibe el dinero en <strong>1 día hábil
          </strong>. <br class="d-none d-md-block d-lg-none">
          Desde <strong>$150.000</strong> hasta <strong>$1.200.000.
          </strong> <span class="d-none d-md-block">Sin trámites, filas, anticipos ni cargos ocultos.</span>
        </p>
        <a
          class="mb-2 t-size-3 t-sm-size-5"
          href="/"
          on:click|preventDefault={goToOurCredits}
          rel="noopener"
          target="_blank">
          <strong>¿Cómo funciona tu cupo de crédito?</strong>
        </a>
      </div>
      <div class="col-5 col-lg-5 align-self-end d-none d-lg-block mt-14">
        <div
          class="d-flex justify-content-end pr-0"
          data-aos="zoom-in"
          data-aos-delay="200"
          data-aos-offset="0"
        >
          <picture aria-hidden="true">
            <source
              srcset={resolveRoute("/images/inicio/img-fold-1.webp")}
              type="image/webp"
            />
            <img
              loading="lazy"
              class="img-fluid d-block lazyload mw-100 mt-5"
              data-src={resolveRoute("/images/inicio/img-fold-1.png")}
              alt=""
            />
          </picture>
        </div>
      </div>
      <div class="col-12 pt-2 pt-lg-0">
        <div class="container__card w-100 d-grid grid-12 bdr-radius-2 px-4 px-md-6 px-xl-10 py-4 mb-2 mb-lg-6 bgr-white">
          <div class="container__card__input col-12 col-md-7 col-lg-8 col-xl-9">
            <znb-input
              oninput={(e) => handlerInput(e.target.value)}
              onblur={(e) => handlerFormat(e.target.value)}
              type="number"
              id="vaueCalc"
              value={value}
              label={label}
              valid={inputValid}
              helpmessage={helpText}
              errormessage={errorMessages}
              class="h-3"
            />
          </div>
          <div class="col-12 col-md-5 col-lg-4 col-xl-3 pt-4 pt-lg-8 pr-md-4 pr-lg-4">
            <button
              bind:this={calculatorBtnFixed}
              class="d-flex btn btn-lg btn--shadow bgr-brand-2:active t-white ml-0 ml-md-6 ml-lg-6 t-left align-items-center w-100 w-lg-auto w-xl-100"
              on:click={calculateCost}
              class:bgr-primary-500={inputValid}
              class:bgr-primary-100={!inputValid}
              class:disabled={!inputValid}
              role="button"
            >
              <span class="flex-grow">
                Pedir mi crédito ya
              </span>
              <Icon width="23" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
            </button>

            {#if scrollPosY >= calculatorBtnPosY}
            <div class="d-block d-md-none calculator-btn-fixed py-2 px-5 bgr-white w-100"
            >
              <button
                class="d-flex btn btn-lg bgr-primary-500 bgr-brand-2:active t-white ml-0 ml-md-6 ml-lg-6 t-left align-items-center w-100 w-auto"
                on:click={calculateCost}
                role="button"
              >
                <span class="flex-grow">
                  Pedir mi crédito ya
                </span>
                <Icon width="23" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
              </button>
            </div>
            {/if}
          </div>
          <div class="col-12 mt-4 mt-lg-2">
            <a
              class="t-brand-1 t-size-4 t-weight-bold t-decoration-none"
              href="/"
              on:click="{() => lookAnExample(true)}"
            >
               <span>Mira un ejemplo</span>

               <svg class="w-6 h-auto svg-position" viewBox="0 0 24 24">
                <path fill="currentColor" height="100%" d={mdiChevronRight} />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<znb-modal show={showLookAnExample} dismissible="true" size="xl">
  <div class="d-grid grid-12 -m-4 d-none" id="modal">
    <div class="col-12 col-lg-6 d-none d-lg-block align-self-center bgr-primary-100 modal-border-box">
      <div  style="background: url('{resolveRoute("/images/patrones/patrones-simbolo-lineru.png")}') repeat; background-attachment: fixed;">
        <div class="d-flex justify-content-center">
          <picture aria-hidden="true">
            <img
              loading="lazy"
              class="img-fluid d-block lazyload mw-100"
              data-src={resolveRoute("/images/inicio/img-modal-fold-1.png")}
              alt=""
            />
          </picture>
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-6 px-0 px-lg-8 justify-content-center">
      <div class="d-flex -mr-lg-5 -mb-6 mt-3 mt-lg-4 pr-2 pr-lg-0 align-content-start justify-content-end">
        <button
          on:click="{() => {lookAnExample(false)}}"
          class="p-0 bgr-transparent bdr-none t-primary-500 t-primary-500:hover"
          aria-label="Cerrar modal"
        >
          <svg class="w-6 h-auto" viewBox="0 0 24 24">
            <path fill="currentColor" height="100%" d={mdiClose} />
          </svg>
        </button>
      </div>
      <div class="mb-3">
        <h3 class="t-center mt-1 mt-lg-0">Ejemplo representativo</h3>
        <p class="t-center t-size-5">Crédito a 90 días</p>
      </div>
      <div class="col">
        <div class="p-4 px-lg-16 mt-2 bgr-gray-100 t-size-4 d-grid grid-2 gap-1">
          <p class="mb-0 my-lg-4"><strong>Valor solicitado</strong></p>
          <p class="mb-0 my-lg-4 d-flex justify-content-end">
            <span class="mr-2"><strong>$300,000</strong></span>
          </p>
          <p class="mb-0 mb-lg-4">Interés (25,42%)</p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">$17,478</span>
          </p>
          <p class="mb-0 mb-lg-4">Seguro</p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">$1,347</span>
          </p>
          <p class="mb-0 mb-lg-4">FGA + IVA</p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">$36,000</span>
          </p>
          <p class="mb-0 mb-lg-4">Administración</p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">$34,900</span>
          </p>
          <p class="mb-0 mb-lg-4">IVA
            <span class="t-size-1 d-none d-lg-inline-block">(sobre la tarifa de administración)</span>
            <span class="t-size-1 d-inline-block d-lg-none">(sobre administración)</span>
          </p>
          <p class="mb-0 mb-lg-4 d-flex justify-content-end">
            <span class="mr-2">$6,631</span>
          </p>
          <p class="mb-0 mb-lg-3">Total costos</p>
          <p class="mb-0 mb-lg-3 d-flex justify-content-end">
            <span class="mr-2">$96,356</span>
          </p>
        </div>
        <div class="row py-2 bgr-white"></div>
        <div class="py-2 px-4 py-lg-0 px-lg-16 mb-4 bgr-gray-100 t-size-4 d-grid grid-2 gap-2">
          <p class="mb-0 my-lg-2"><strong>Total a pagar</strong></p>
          <p class="mb-0 my-lg-2 d-flex justify-content-end">
            <span class="mr-2"><strong>$396,356</strong></span>
          </p>
        </div>
      </div>
      <p class="t-size-1 mt-3 px-4 px-lg-0">
        Tasa máxima de interés efectiva anual vigente en Colombia para {usuraMonthRate} de {usuraYearRate}: {usuraRate}% EA.
        Lo descrito corresponde a un ejemplo representativo, las condiciones específicas del
        crédito dependerán de las políticas vigentes de Lineru al momento de la solicitud.
      </p>
    </div>
  </div>
</znb-modal>

<style>

  .calculator-btn-fixed {
    position: fixed;
    border-top: 1px solid rgb(var(--gray-100));
    top: 60px;
    left: 0;
    z-index: 1;
  }

  .position-relative {
    position: relative;
  }

  @media screen and (min-width: 992px){
    .container__card {
      position: absolute;
      bottom: 6px;
    }
  }

  @media screen and (min-width: 1200px){
    .container__card {
      position: absolute;
      bottom: 6px;
    }
  }

  .mw-100{
    max-width: 100%;
  }

  .container__card__input {
    --input-text-font-size: 1.7rem;
    --input-text-background-color: none;
    --input-text-font-weight: 700;
  }

  .modal-border-box{
    border-radius: 4px 0px 0px 4px;
  }

  .t-decoration-none {
    text-decoration: none;
  }

  .svg-position{
    position: relative;
    top: 7px;
  }</style>
