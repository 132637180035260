<script>
	import Images from './../../../../components/Images.svelte';
  import { mdiChevronRight } from '@mdi/js';
  import '@zinobe-co/components/swiper';
  import { goto } from '@roxi/routify';
  import GoogleTagManagerService from '../../../../services/tag-manager';
  import LineruTagsService from '../../../../services/lineru-tags';
  import { environment } from '../../../../environment.js';

  function goToCreateAccount() {
    $goto('/acerca-de-tu-credito/nuestros-requisitos/crear-una-cuenta');
    GoogleTagManagerService.setTag(LineruTagsService.ourRequisits().createAccount);
  };

  const swipperItems = [
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/edad.svg')}" />
        <p>
          <strong>
            Edad
          </strong>
        </p>
        <p>
          ebes ser mayor de 18 años. Hazlo todo a nombre pues eres tú quien se va a hacer cargo.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/id.svg')}" />
        <p>
          <strong>
            Residencia
          </strong>
        </p>
        <p>
          Debes vivir en Colombia. Nosotros podemos llegar a ti en cualquier rincón del país.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/clave.svg')}" />
        <p>
          <strong>
            Correo y celular
          </strong>
        </p>
        <p>
          Ambos deben ser propios y de uso personal. Son claves para todo el proceso.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/contrasena.svg')}" />
        <p>
          <strong>
            Cuenta bancaria
          </strong>
        </p>
        <p>
          Si la tienes, perfecto. Si no, te ayudamos a abrirla fácil junto al Banco de Bogotá.
        </p>
        <p>
          <a href="/acerca-de-tu-credito/nuestros-requisitos/crear-una-cuenta">
            Crear una cuenta
          </a>
        </p>
      `,
    }
  ];

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="fold-2">
  <div class="container fold-2__container pt-10 pt-md-16 pt-lg-26 pb-10 pb-md-14 pb-lg-24">
    <h2 class="mb-12">
      Nuestros requisitos son
      <br class="d-none d-xl-inline">
      muy sencillos
    </h2>

    <div class="d-none d-sm-grid grid-12 gap-6">
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-right" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/edad.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Edad
        </h3>
        <p class="mb-0">
          Debes ser mayor de 18 años. Hazlo todo a nombre pues eres tú quien se va a hacer cargo.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-down" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/id.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Residencia
        </h3>
        <p class="mb-0">
          Debes vivir en Colombia. Nosotros podemos llegar a ti en cualquier rincón del país.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-up" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/celular.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Correo y celular
        </h3>
        <p class="mb-0">
          Ambos deben ser propios y de uso personal. Son claves para todo el proceso.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-left" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/contrasena.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Cuenta bancaria
        </h3>
        <p class="mb-3">
          Si la tienes, perfecto. Si no, te ayudamos a abrirla fácil junto al Banco de Bogotá.
        </p>
        <a
          class="btn bgr-white t-primary-500 t-brand-2:hover pl-0 pr-5 t-left"
          on:click|preventDefault={goToCreateAccount}
          href="/"
          role="button"
        >
          Crear una cuenta
          <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiChevronRight} />
          </svg>
        </a>
      </div>
    </div>

    {#if swipperItems}
      <znb-swiper class="d-block d-sm-none" items={swipperItems} />
    {/if}
  </div>
  <div class="fold-2__bg-patron" style="background-image: url({resolveRoute("/images/patrones/patron-marca.png")})"></div>
</div>

<style>
  .fold-2 {
    position: relative;
    z-index: 0;
  }

  .fold-2__container {
    position: relative;
    z-index: 1;
  }

  .fold-2__bg-patron {
    width: 15%;
    height: 84%;
    top: 8%;
    position: absolute;
    right: 0;
  }</style>
