<script>
  import {
    mdiClose,
    mdiCheckCircle,
    mdiAlertCircle
  } from '@mdi/js';
  import '@zinobe-co/components/modal';
  import Icon from '../../../components/Icon.svelte';
  import lineruIcons from '../../../constants/lineru-icons.js';
  import GoogleTagManagerService from '../../../services/tag-manager.js';
  import LineruTagsService from '../../../services/lineru-tags.js';
  import { environment } from '../../../environment.js';

  let showNoLineruWorld = false;

  export let urlContext;

  export function noLineruWorld(state) {
    showNoLineruWorld = undefined;
    showNoLineruWorld = state;

    if(state){
      GoogleTagManagerService.setTag(LineruTagsService.calculator().selectLineruWorld);
    }

  }

  function openDocument() {
    window.open(resolveRoute('/docs/solicitud-fisica.pdf'), '_blank');

    switch (urlContext)  {
      case '/acerca-de-tu-credito/cuanto-cuesta':
        GoogleTagManagerService.setTag(LineruTagsService.howMuchItCost().physicalLoan);
        break;
      default:
        GoogleTagManagerService.setTag(LineruTagsService.calculator().physicalLoan);
        break;
    }
  }

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<znb-modal show={showNoLineruWorld} dismissible="true" size="sm">
  <div class="d-flex align-items-start">
    <h3 class="t-center mb-5 flex-grow-1 w-100 pr-8 -mr-6">
      Espera ¿estás seguro?
    </h3>
    <button
      on:click="{() => {noLineruWorld(false)}}"
      class="p-0 bgr-transparent bdr-none -mr-6 t-primary-500 t-primary-500:hover"
      aria-label="Cerrar modal"

    >
      <svg class="w-6 h-auto" viewBox="0 0 24 24">
        <path fill="currentColor" height="100%" d={mdiClose} />
      </svg>
    </button>
  </div>
  <p class="t-center mb-5 t-size-4">
    Haciendo tu solicitud en línea obtienes beneficios
    <br>
    que no te quieres perder
  </p>

  <!-- Table in desktop -->
  <table class="bgr-gray-100 mb-6 w-100 table-no-lineru">
    <thead>
      <tr class="t-size-4 t-primary-500">
        <td class="p-3 bdr-none table-no-lineru__left-hr">
          Solicitud en línea
        </td>
        <td class="p-3 bdr-none">
          Solicitud física
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="w-6/12 p-4 t-left bdr-none table-no-lineru__left-td">
          <ul class="mb-0 pl-0">
            <li class="d-flex align-items-start">
              <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiCheckCircle} />
              </svg>
              <span>
                Proceso 100% en línea
              </span>
            </li>
            <li class="d-flex align-items-start">
              <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiCheckCircle} />
              </svg>
              <span>
                Respuesta en 15 minutos
              </span>
            </li>
            <li class="d-flex align-items-start">
              <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiCheckCircle} />
              </svg>
              <span>
                Desembolso en 1 dia hábil
              </span>
            </li>
            <li class="d-flex align-items-start mb-0">
              <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiCheckCircle} />
              </svg>
              <span>
                Acceso a beneficios
              </span>
            </li>
          </ul>
        </td>
        <td class="w-6/12 p-4 t-left bdr-none table-no-lineru__right-td">
          <ul class="mb-0 pl-0">
            <li class="d-flex align-items-start">
              <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiAlertCircle} />
              </svg>
              <span>
                Desplazamiento a oficina
              </span>
            </li>
            <li class="d-flex align-items-start">
              <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiAlertCircle} />
              </svg>
              <span>
                Radicación de documentos
              </span>
            </li>
            <li class="d-flex align-items-start">
              <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiAlertCircle} />
              </svg>
              <span>
                Respuesta en 5 días hábiles
              </span>
            </li>
            <li class="d-flex align-items-start mb-0">
              <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiAlertCircle} />
              </svg>
              <span>
                Sin acceso a beneficios
              </span>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Table in mobile -->
  <div class="bgr-gray-100 mb-6 w-100 d-block d-sm-none">
    <div class="py-6 px-8">
      <h6 class="t-center mb-4 t-primary-500 t-weight-light">
        Solicitud en línea
      </h6>
      <ul class="mb-0 pl-0">
        <li class="d-flex align-items-start">
          <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiCheckCircle} />
          </svg>
          <span>
            Proceso 100% en línea
          </span>
        </li>
        <li class="d-flex align-items-start">
          <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiCheckCircle} />
          </svg>
          <span>
            Respuesta en 15 minutos
          </span>
        </li>
        <li class="d-flex align-items-start">
          <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiCheckCircle} />
          </svg>
          <span>
            Desembolso en 1 dia hábil
          </span>
        </li>
        <li class="d-flex align-items-start mb-0">
          <svg class="w-4 h-4 mt-1 mr-2 t-success-500" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiCheckCircle} />
          </svg>
          <span>
            Acceso a beneficios
          </span>
        </li>
      </ul>
    </div>
    <hr class="mobile-hr-no-lineru m-0">
    <div class="py-6 px-8">
      <h6 class="t-center mb-4 t-primary-500 t-weight-light">
        Solicitud física
      </h6>
      <ul class="mb-0 pl-0">
        <li class="d-flex align-items-start">
          <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiAlertCircle} />
          </svg>
          <span>
            Desplazamiento a oficina
          </span>
        </li>
        <li class="d-flex align-items-start">
          <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiAlertCircle} />
          </svg>
          <span>
            Radicación de documentos
          </span>
        </li>
        <li class="d-flex align-items-start">
          <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiAlertCircle} />
          </svg>
          <span>
            Respuesta en 5 días hábiles
          </span>
        </li>
        <li class="d-flex align-items-start mb-0">
          <svg class="w-4 h-4 mt-1 mr-2 t-error-300" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiAlertCircle} />
          </svg>
          <span>
            Sin acceso a beneficios
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="t-center mb-3">
    <button
      on:click="{() => {noLineruWorld(false)}}"
      class="btn btn--shadow bgr-primary-500 bgr-brand-2:active t-white btn-lg"
      role="button"
      aria-labelledby
    >
      Continuar con solicitud en línea
      <Icon width="16" height="11" classList="ml-3 -mr-3 t-yellow" path={lineruIcons.arrow}></Icon>
    </button>
  </div>

  <div class="t-center">
    <a
      class="d-block btn bgr-white t-primary-500 t-brand-2:hover btn-lg mx-0 mx-lg-1 px-5 px-xl-8"
      href="/"
      on:click|preventDefault={openDocument}
      role="button"
    >
      Realizar solicitud en forma física
    </a>
  </div>
</znb-modal>

<style>
  .table-no-lineru {
    display: none;
  }

  .table-no-lineru__left-hr {
    border-right: 1px solid rgb(var(--gray-400));
  }

  .table-no-lineru__left-td {
    border-right: 1px solid rgb(var(--gray-400));
  }

  .table-no-lineru__left-td,
  .table-no-lineru__right-td {
    border-top: 1px solid rgb(var(--gray-400));
  }

  .mobile-hr-no-lineru {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgb(var(--gray-400));
  }

  @media (min-width: 576px) {
    .table-no-lineru {
      display: table;
    }
  }</style>
