<script>
  export let valid = true;
  export let value = "";
  export let id = "input-1";
  export let label = "";
  export let errormessage = "";
  export let helpmessage = "";
  export let placeholder = "";
  export let darktheme = false;
  export let progressbarvalue = 0;
  export let type = "set";
  export let touched = false;

  let inputSelf;
  let showPassword = false;
  let barColor = "";
  let level = "";
  let element;

  function toggleShow() {
    showPassword = !showPassword;
    inputSelf.setAttribute("type", showPassword ? "text" : "password");
  }

  $: if (progressbarvalue <= 33) {
    barColor = "error";
  } else if (progressbarvalue > 33 && progressbarvalue < 66) {
    barColor = "warning";
  } else {
    barColor = "success";
  }

  $: if (progressbarvalue < 33) {
    level = "Débil";
  } else if (progressbarvalue >= 33 && progressbarvalue <= 66) {
    level = "Media";
  } else if (progressbarvalue > 66) {
    level = "Alta";
  } else {
    level = "";
  }

  function handlerFocus(e) {
    setTimeout(() => {
      element.scrollIntoView(true);
    }, 300);
  }
</script>

<div
  class="input-password-container mb-2"
  bind:this={element}
  class:input-password-container--dark={darktheme}
>
  <input
    class:invalid={!valid && touched}
    bind:this={inputSelf}
    on:input
    type="password"
    {placeholder}
    bind:value
    required
    {id}
    on:input={() => (touched = true)}
    on:focus={handlerFocus}
  />
  <span class="bar" />
  <label for={id}>{label}</label>
  {#if !valid}
    <svg class="invalid-icon" fill="currentColor" viewBox="0 0 24 24"
      ><path
        d="M13 13h-2V7h2m0 10h-2v-2h2M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z"
      /></svg
    >
  {/if}
  <div class="msg">
    {#if helpmessage}
      <span class="help-text">{helpmessage}</span>
    {/if}
    {#if errormessage && !valid && touched}
      <span class="error-msg">{errormessage}</span>
    {/if}
  </div>
  <span class="eye-icon" on:click={toggleShow} class:active={!showPassword}>
    <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m12 9a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3m0 8a5 5
          0 0 1-5-5 5 5 0 0 1 5-5 5 5 0 0 1 5 5 5 5 0 0 1-5 5m0-12.5c-5 0-9.27
          3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11
          11-7.5c-1.73-4.39-6-7.5-11-7.5z"
      />
    </svg>
  </span>
  <span class="eye-off-icon" on:click={toggleShow} class:active={showPassword}>
    <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m11.83 9 3.17 3.16v-0.16a3 3 0 0 0-3-3h-0.17m-4.3 0.8 1.55 1.55c-0.05
          0.21-0.08 0.42-0.08 0.65a3 3 0 0 0 3 3c0.22 0 0.44-0.03 0.65-0.08l1.55
          1.55c-0.67 0.33-1.41 0.53-2.2 0.53a5 5 0 0 1-5-5c0-0.79 0.2-1.53
          0.53-2.2m-5.53-5.53 2.73 2.73c-1.65 1.3-2.95 3-3.73 5 1.73 4.39 6 7.5 11
          7.5 1.55 0 3.03-0.3 4.38-0.84l0.43 0.42 2.92 2.92
          1.27-1.27-17.73-17.73m8.73 4a5 5 0 0 1 5 5c0 0.64-0.13 1.26-0.36
          1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4
          0-2.74 0.25-4 0.7l2.17 2.15c0.57-0.22 1.18-0.35 1.83-0.35z"
      />
    </svg>
  </span>
  {#if type === "set" && value}
    <div class="password-validation">
      <span class="p password-validation__message"
        >Nivel de seguridad: <span class={barColor}
          ><strong>{level}</strong></span
        ></span
      >
    </div>
  {/if}
</div>
{#if false}
  <span class="success error warning info" />
{/if}

<style lang="css">
 .input-password-container {
  position: relative;
  padding-top: 23px;
}
.input-password-container input {
  font-size: var(--input-password-font-size);
  padding: 15px 30px 15px 10px;
  display: block;
  width: 100%;
  border: 0;
  font-family: var(--input-password-font-family);
  border-bottom: 1px solid var(--input-password-color);
  background-color: var(--input-password-background-color);
  outline: none;
  font-weight: 400;
  color: var(--input-password-color);
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 0;
  margin: 0;
}
.input-password-container input::placeholder {
  color: var(--input-password-placeholder-color);
}
.input-password-container input:invalid ~ label {
  color: var(--input-password-color);
}
.input-password-container input:focus ~ label, .input-password-container input:valid ~ label {
  color: var(--input-password-focus-color);
}
.input-password-container input.invalid {
  padding-right: 55px;
}
.input-password-container input.valid ~ label {
  color: var(--input-password-valid-color);
}
.input-password-container input.invalid ~ label {
  color: var(--input-password-invalid-color);
}
.input-password-container input.disabled, .input-password-container input:disabled, .input-password-container input[readonly] {
  pointer-events: none;
  color: var(--input-text-disabled-color);
}
.input-password-container input.disabled ~ label, .input-password-container input:disabled ~ label, .input-password-container input[readonly] ~ label, .input-password-container input.disabled ~ .msg .help-text, .input-password-container input:disabled ~ .msg .help-text, .input-password-container input[readonly] ~ .msg .help-text {
  color: var(--input-text-disabled-color);
}
.input-password-container input.disabled ~ .eye-icon svg path, .input-password-container input:disabled ~ .eye-icon svg path, .input-password-container input[readonly] ~ .eye-icon svg path, .input-password-container input.disabled ~ .eye-off-icon svg path, .input-password-container input:disabled ~ .eye-off-icon svg path, .input-password-container input[readonly] ~ .eye-off-icon svg path {
  fill: var(--input-text-disabled-color);
}
.input-password-container label {
  position: absolute;
  font-size: var(--input-password-label-font-size);
  font-family: var(--input-password-font-family);
  transition: 0.2s ease color;
  left: 0;
  top: 0;
  display: inline-block;
}
.input-password-container .bar {
  position: relative;
  display: block;
  width: 100%;
}
.input-password-container .bar:before, .input-password-container .bar:after {
  content: '';
  height: 1px;
  bottom: 0;
  width: 0;
  position: absolute;
  background: var(--input-password-focus-color);
  transition: 0.2s ease width;
}
.input-password-container .bar:before {
  left: 50%;
}
.input-password-container .bar:after {
  right: 50%;
}
.input-password-container input:focus ~ .bar:before, .input-password-container input:focus ~ .bar:after, .input-password-container input:valid ~ .bar:before, .input-password-container input:valid ~ .bar:after {
  width: 50%;
}
.input-password-container input.valid ~ .bar:before, .input-password-container input.valid ~ .bar:after {
  width: 50%;
  background-color: var(--input-password-valid-color);
}
.input-password-container input.invalid ~ .bar:before, .input-password-container input.invalid ~ .bar:after {
  width: 50%;
  background-color: var(--input-password-invalid-color);
}
.input-password-container .msg {
  height: auto;
  min-height: 1.5rem;
}
.input-password-container .error-msg, .input-password-container .help-text {
  display: inline-block;
  padding-top: 5px;
  font-family: var(--input-password-font-family);
  font-size: var(--input-password-helper-font-size);
}
.input-password-container .help-text {
  color: var(--input-password-color);
}
.input-password-container input.invalid ~ .msg .help-text {
  display: none;
}
.input-password-container input.valid ~ .msg .help-text {
  color: var(--input-password-valid-color);
}
.input-password-container .error-msg {
  display: none;
  color: var(--input-password-invalid-color);
}
.input-password-container input.invalid ~ .msg .error-msg {
  display: inline-block;
}
.input-password-container input.invalid ~ .invalid-icon {
  display: block;
}
.input-password-container .eye-icon, .input-password-container .eye-off-icon {
  position: absolute;
  right: 8px;
  height: 16px;
  top: 39px;
  line-height: initial;
  display: none;
  cursor: pointer;
}
.input-password-container .eye-icon svg, .input-password-container .eye-off-icon svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  contain: strict;
  box-sizing: content-box;
}
.input-password-container .eye-icon svg path, .input-password-container .eye-off-icon svg path {
  fill: var(--input-password-icon-color);
}
.input-password-container .eye-icon.active, .input-password-container .eye-off-icon.active {
  display: block;
}
.input-password-container .password-validation progress {
  display: block;
  width: 100%;
  margin-bottom: 3px;
  border: none;
  background-color: rgb(var(--gray-200));
}
.input-password-container .password-validation progress::-webkit-progress-bar {
  background-color: rgb(var(--gray-200));
}
.input-password-container .password-validation progress[value] {
  appearance: none;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.input-password-container .password-validation progress[value].error::-moz-progress-bar, .input-password-container .password-validation progress[value].error::-webkit-progress-value {
  background-color: var(--input-password-error-color);
}
.input-password-container .password-validation progress[value].warning::-moz-progress-bar, .input-password-container .password-validation progress[value].warning::-webkit-progress-value {
  background-color: var(--input-password-warning-color);
}
.input-password-container .password-validation progress[value].success::-moz-progress-bar, .input-password-container .password-validation progress[value].success::-webkit-progress-value {
  background-color: var(--input-password-success-color);
}
.input-password-container .password-validation progress.error::-webkit-progress-value {
  background-color: var(--input-password-error-color);
}
.input-password-container .password-validation progress.warning::-webkit-progress-value {
  background-color: var(--input-password-warning-color);
}
.input-password-container .password-validation progress.success::-webkit-progress-value {
  background-color: var(--input-password-success-color);
}
.input-password-container .password-validation__message {
  font-family: var(--input-password-font-family);
  font-size: var(--input-password-helper-font-size);
}
.input-password-container .password-validation__message .error {
  color: var(--input-password-error-color);
}
.input-password-container .password-validation__message .warning {
  color: var(--input-password-warning-color);
}
.input-password-container .password-validation__message .success {
  color: var(--input-password-success-color);
}
.input-password-container .password-conditions ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.input-password-container .password-conditions ul li {
  font-family: var(--input-password-font-family);
  font-size: 0.875rem;
  color: #8B8B8B;
  margin-bottom: 12px;
}
.input-password-container .password-conditions ul li:last-child {
  margin-bottom: 0;
}
.input-password-container .password-conditions__icon {
  float: right;
  margin-left: 20px;
}
.input-password-container .password-conditions__icon svg {
  display: none;
  width: 1rem;
  height: 1rem;
  contain: strict;
  box-sizing: content-box;
}
.input-password-container .password-conditions__icon svg path {
  fill: var(--input-password-valid-color);
}
.input-password-container .password-conditions__icon.active svg {
  display: inline-block;
}
.input-password-container--dark input {
  background-color: var(--input-password-dark-background-color);
  border-bottom: 1px solid var(--input-password-dark-color);
  color: var(--input-password-dark-color);
}
.input-password-container--dark input:invalid ~ label {
  color: var(--input-password-dark-color);
}
.input-password-container--dark input:focus ~ label, .input-password-container--dark input:valid ~ label {
  color: var(--input-password-dark-focus-color);
}
.input-password-container--dark input.valid ~ label {
  color: var(--input-password-valid-color);
}
.input-password-container--dark input.invalid ~ label {
  color: var(--input-password-invalid-color);
}
.input-password-container--dark .bar:before, .input-password-container--dark .bar:after {
  background: var(--input-password-dark-focus-color);
}
.input-password-container--dark .help-text {
  color: var(--input-password-dark-color);
}
.input-password-container--dark .eye-icon svg path, .input-password-container--dark .eye-off-icon svg path {
  fill: var(--input-password-dark-focus-color);
}
.input-password-container--dark .password-validation__message {
  color: var(--input-password-dark-color);
}
.input-password-container .invalid-icon {
  display: none;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 38px;
  right: 30px;
  color: var(--input-text-invalid-color);
}</style>
