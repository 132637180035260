<script>
  import Menu from './Menu.svelte';
  import LogoLineruMobile from './logos/LineruMobile.svelte';
  import LogoLineru from './logos/Lineru.svelte';
  import Icon from './Icon.svelte';
  import { goto } from '@roxi/routify';
  import lineruIcons from '../constants/lineru-icons.js';
  import GoogleTagManagerService from '../services/tag-manager.js';
  import LineruTagsService from '../services/lineru-tags.js';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let simpleHeader = false;
  export let showCalculatorMobileBtn = true;

  let openMenu = false;

  let fillLogoVar = '--brand-1';

  const toggleMenu = (open) => {
    openMenu = open;

    dispatch('open-menu', {
			openMenu
		});

    if (openMenu) {
      fillLogoVar = '--white';
      document.body.style.overflow = 'hidden';
    } else {
      fillLogoVar = '--brand-1';
      document.body.style.overflow = 'auto';
    }
  };

  function goToInit() {
    $goto('/');
    GoogleTagManagerService.setTag(LineruTagsService.header().lineruLogo);
    toggleMenu(false);
  }

  function goToHelp() {
    $goto('/preguntas-frecuentes');
    GoogleTagManagerService.setTag(LineruTagsService.header().help);
  }

  function goToLogin() {
    $goto('/iniciar-sesion');
    GoogleTagManagerService.setTag(LineruTagsService.header().initSession);
  }

  function goToRegister() {
    $goto('/registro');
    GoogleTagManagerService.setTag(LineruTagsService.header().register);
  }

  function goToCalculator() {
    $goto('/calcula-tu-credito');
    GoogleTagManagerService.setTag(
      LineruTagsService.header().calculateYourLoan
    );
  }
</script>

<div
  class:header-container__z-initial={openMenu}
  class:mt-0={simpleHeader}
  class:pt-3={simpleHeader}
  class="header-container w-12/12 w-sm-11/12 bgr-white mx-auto"
>
  {#if !simpleHeader}
    <div
      class="bgr-gray-100 px-5 px-lg-14 py-1 d-none d-lg-flex align-items-center"
    >
      <p class="mb-0 d-inline-block mr-8">
        <a class="t-size-2" href="/" on:click|preventDefault={goToHelp}>
          ¿Necesitas ayuda?
        </a>
      </p>
    </div>
  {/if}
  <div class="d-flex align-items-center py-3 px-5 px-lg-8 px-xl-14 py-lg-2">
    <a
      class:open-menu={openMenu}
      on:click|preventDefault={goToInit}
      class="d-flex align-items-center"
      href="/"
      title="Lineru">
      <div class="d-none d-lg-inline-block">
        <LogoLineru fillColorVar={fillLogoVar} width="128px" height="100%" />
      </div>

      <div class="d-inline-block d-lg-none">
        <LogoLineruMobile
          fillColorVar={fillLogoVar}
          width="23px"
          height="100%"
        />
      </div>
    </a>

    <div class="flex-1 d-flex justify-content-end align-items-center">
      {#if !simpleHeader}
        <a
          class="btn btn--shadow bgr-primary-500 t-white:hover bgr-brand-2:active t-white btn-lg mx-1 d-none d-md-inline-block"
          href="/"
          on:click|preventDefault={goToCalculator}
          role="button">
          Pedir mi crédito ya
          <Icon
            width="16"
            height="11"
            classList="ml-3 -mr-3 t-yellow"
            path={lineruIcons.arrow}
          />
        </a>
        <a
          id="btnSignInMobile"
          class:d-block={!openMenu}
          class:d-none={openMenu}
          class="btn btn-sm bgr-white btn-z-index t-primary-500 t-brand-2:hover mx-2 px-2 d-inline-block d-md-none"
          on:click|preventDefault={goToRegister}
          href="/"
          role="button"> Regístrate </a>

        <a
          id="btnSignInDesktop"
          class="d-block btn bgr-white t-primary-500 t-brand-2:hover btn-lg mx-0 ml-lg-3 px-5 px-xl-8 d-none d-md-inline-block"
          href="/"
          on:click|preventDefault={goToRegister}
          role="button"> Regístrate </a>
        <a
          id="btnSignInMobile"
          class:d-block={!openMenu}
          class:d-none={openMenu}
          class="btn btn-sm bgr-white btn-z-index t-primary-500 t-brand-2:hover mx-2 px-2 d-inline-block d-md-none"
          on:click|preventDefault={goToLogin}
          href="/"
          role="button"> Inicia sesión </a>

        <a
          id="btnSignInDesktop"
          class="d-block btn bgr-white t-primary-500 t-brand-2:hover btn-lg mx-0 mr-lg-2 px-5 px-xl-8 d-none d-md-inline-block"
          href="/"
          on:click|preventDefault={goToLogin}
          role="button"> Inicia sesión </a>

        <button
          class="desktop-menu-btn pl-4 btn bgr-primary-500-alpha-1 t-primary-500 py-0 pr-0 t-brand-2:hover btn-lg ml-0 ml-lg-1 d-none d-lg-flex align-items-center"
          on:click={toggleMenu(!openMenu)}
          role="button"
          aria-label="Menú">
          <span class="mr-1"> Menú </span>

          <div class="button_container bdr-radius-1" class:active={openMenu}>
            <div class="button_close w-4 h-3">
              <span class="button_close--top" />
              <span class="button_close--middle" />
              <span class="button_close--bottom" />
            </div>
          </div>
        </button>
      {:else}
        <slot />
      {/if}

      <button
        on:click={toggleMenu(!openMenu)}
        class="mobile-menu-btn py-1 px-2 bgr-primary-500-alpha-1 bgr-primary-500:active bgr-primary-500-alpha-1:hover bdr-none t-brand-5 t-white:active  bdr-radius-1 d-flex justify-content-center align-items-center d-lg-none"
        class:bgr-primary-500:hover={!openMenu}
        class:active={openMenu}
        aria-label="Abrir menu">
        <div class="button_close w-4 h-3" class:active={openMenu}>
          <span class="button_close--top" />
          <span class="button_close--middle" />
          <span class="button_close--bottom" />
        </div>
      </button>
    </div>
  </div>

  {#if !simpleHeader && showCalculatorMobileBtn}
    <div
      class:d-block={!openMenu}
      class:d-none={openMenu}
      class="d-md-none header-section-3 py-2 px-5">
      <a
        class="d-flex btn btn--shadow bgr-primary-500 bgr-brand-2:active t-white t-white:hover w-100 w-md-auto align-items-center"
        href="/"
        on:click|preventDefault={() => {
          goToCalculator();
        }}
        role="button">
        <span class="flex-1 t-left"> Pedir mi crédito ya </span>
        <Icon
          width="16"
          height="11"
          classList="ml-3 -mr-3 t-yellow"
          path={lineruIcons.arrow}
        />
      </a>
    </div>
  {/if}
</div>

<Menu open={openMenu} on:close={() => toggleMenu(!openMenu)} />

<span class="d-none qwerty" />

<style>
  .open-menu {
    position: fixed;
    z-index: 3;
    transition: all .5s ease-in-out;
    top: 19px;
  }

  .header-container {
    z-index: 2;
    position: sticky;
    top: 0;
    left: 0;
  }

  .header-container__z-initial {
    position: relative;
    z-index: initial;
  }

  .header-section-3 {
    border-top: 1px solid rgb(var(--gray-100));
  }

  .mobile-menu-btn {
    width: 38px;
    height: 37px;
  }

  .mobile-menu-btn.active {
    z-index: 3;
    border: 2px solid rgb(var(--primary-200));
  }

  .desktop-menu-btn {
    width: 110px;
    height: 47px;
  }

  .button_close {
    position: relative;
    top: 0;
    cursor: pointer;
    transition: opacity .25s ease;
  }

  .button_close--top,
  .button_close--middle,
  .button_close--bottom {
    background: rgb(var(--black));
    display: block;
    border: none;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .35s ease;
    cursor: pointer;
    width: 100%;
  }

  .button_close--middle {
    top: 5px;
  }

  .button_close--bottom {
    top: 10px;
  }

  .active .button_close--top,
  .active .button_close--middle,
  .active .button_close--bottom {
    background: rgba(var(--white), 1);
  }

  .button_container {
    padding: 12px 10px;
  }

  .button_container.active {
    z-index: 3;
    position: fixed;
    top: 41px;
    border: 2px solid rgb(var(--primary-200));
  }

  .active .button_close--top {
    transform: translateY(5px) translateX(0) rotate(45deg);
  }

  .active .button_close--middle {
    opacity: 0;
  }

  .active .button_close--bottom {
    transform: translateY(-5px) translateX(0) rotate(-45deg);
  }

  @media (min-width: 992px) {
    .open-menu {
      top: 42px;
    }

    .button_close {
      width: 22px;
      height: 17px;
    }

    .button_container.active {
      right: calc(4.16665% + 34px);
    }

    .button_close--top,
    .button_close--middle,
    .button_close--bottom {
      height: 3px;
    }

    .button_close--middle {
      top: 7px;
    }

    .button_close--bottom {
      top: 14px;
    }

    .active .button_close--top {
      transform: translateY(7px) translateX(0) rotate(45deg);
    }

    .active .button_close--bottom {
      transform: translateY(-7px) translateX(0) rotate(-45deg);
    }
  }

  @media (max-width: 992px) {
    .header-container__z-initial {
      position: relative;
      z-index: 4;
      background: #6916E6;
      position: fixed;
    }
  }

  @media (min-width: 1200px) {
    .button_container.active {
      right: calc(4.16665% + 58px);
    }
  }</style>
