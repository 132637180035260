<script>
	import Images from './../../../components/Images.svelte';
  import Video from '../../../components/Video.svelte';
  import { mdiChevronRight } from '@mdi/js';
  import { goto } from '@roxi/routify';
  import GoogleTagManagerService from '../../../services/tag-manager.js';
  import LineruTagsService from '../../../services/lineru-tags.js';
  import { environment } from '../../../environment.js';

  export let verticalNavActive = false;

  function goToCalculator() {
    $goto('/calcula-tu-credito');
    GoogleTagManagerService.setTag(LineruTagsService.howItWorks().goToCalculate);
  }

  function playVideo() {
    GoogleTagManagerService.setTag(LineruTagsService.howItWorks().video);
  }

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div
  class="fold-2 z-0"
  class:pt-12 = {verticalNavActive}
  class:-mt-12 = {verticalNavActive}
>
  <div class="container fold-2__container z-10 pt-12 pt-md-20 pb-12 pb-md-0">
    <h2 class="t-center mb-10">
      Los créditos rotativos
      <br class="d-none d-sm-inline">
      en un minuto
    </h2>
    <div class="d-grid grid-12 gap-6">
      <div class="col-start-1 col-md-start-2 col-12 col-md-10 mb-6 mb-sm-8">
        <Video
          paths={[
            'https://zinobe-assets.s3.amazonaws.com/new_lineru/videos/home/creditos-rotativos.mp4'
          ]}
          on:play-video={playVideo}
        >
        </Video>
      </div>
      <div class="col-start-1 col-md-start-2 col-12 col-sm-6 col-md-5 t-left mb-2 mb-sm-0 d-flex flex-col">
        <div class="d-inline-block mb-5">
          <img loading="lazy" class="w-12 h-12 lazyload" data-src={resolveRoute('/images/iconos/marca/calculadora.svg')} aria-hidden="true" alt="" data-aos="zoom-in">
        </div>
        <h3 class="t-h6 mb-3">
          Cupo de crédito
        </h3>
        <p class="mb-3 flex-grow">
          Es el límite de dinero que te aprobamos. Puede ser hasta de $1.200.000 de pesos. Lo usas a tu antojo pidiendo desembolsos cada vez que los necesites.
        </p>
        <a
          class="btn bgr-white t-primary-500 t-brand-2:hover pl-0 pr-5 t-left align-self-start"
          href="/"
          on:click|preventDefault={goToCalculator}
          role="button"
        >
          Calcular mi cupo
          <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiChevronRight} />
          </svg>
        </a>
      </div>
      <div class="col-12 col-sm-6 col-md-5 t-left d-flex flex-col">
        <div class="d-inline-block mb-5" data-aos="zoom-in">
          <Images imgClass="w-12 h-12 lazyload" width="48" height="48" src='/images/iconos/marca/pagos.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Desembolsos
        </h3>
        <p class="mb-0 flex-grow">
          Son los montos del cupo de crédito que vas pidiendo. Puedes pedir el valor que quieras de tu cupo disponible. Y cuando pagas, liberas ese cupo nuevamente.
        </p>
      </div>
    </div>
  </div>
  <div
    class="fold-2__bg-patron z-0 mt-2"
    style="background-image: url('{resolveRoute("/images/patrones/patron-marca.png")}')"
  ></div>
</div>

<style>
  .fold-2 {
    position: relative;
  }

  .fold-2__container {
    position: relative;
  }

  .fold-2__bg-patron {
    width: 45%;
    height: 38%;
    top: 0;
    position: absolute;
    right: 0;
  }</style>
