<script>
	import Images from './../../../components/Images.svelte';
  import { mdiHome } from '@mdi/js';
  import '@zinobe-co/components/breadcrumb';
  import { environment } from '../../../environment.js';

  const breadcrumbData = [
    {
      iconPath: mdiHome,
      text: '',
      url: '/',
    },
    {
      text: 'Acerca de tu crédito',
    },
  ];

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div
  class="pt-28 -mt-28 pt-lg-24 -mt-lg-24 fold-1 bgr-pink-light-1"
  style="background-image: url('{resolveRoute("/images/patrones/patron-3.jpg")}')"
>
  <div class="t-size-2 pt-1 pb-2 px-5 px-lg-8 px-xl-14 py-lg-2 w-11/12 d-none d-md-block mx-auto">
    <znb-breadcrumb data={breadcrumbData} />
  </div>

  <div class="container">
    <div class="d-grid grid-12 gap-5 pt-10 pt-lg-16 pt-xl-28">
      <div class="col-4 col-xl-5 align-self-end d-none d-lg-block">
        <div class="d-grid grid-5 gap-5">
          <div class="col-5 col-xl-4 col-start-1 col-xl-start-2" data-aos-offset="0" data-aos="fade-right" >
            <picture aria-hidden="true">
              <source srcset="/images/acerca-de-tu-credito/imagen-fold-1.webp" type="image/webp">
              <Images imgClass="img-fluid d-block lazyload" src='/images/acerca-de-tu-credito/imagen-fold-1.png' alt=""/>
            </picture>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-8 col-xl-7 pb-10 pb-lg-16 pb-xl-20">
        <h1 class="mb-5 mb-md-8" data-aos="fade-left">
          Tu crédito simple,
          <br class="d-inline d-sm-none d-xl-inline">
          rápido y siempre
          <br class="d-inline d-sm-none d-xl-inline">
          disponible.
        </h1>
        <div class="d-grid grid-7 gap-5">
          <p class="col-12 col-md-6 col-xl-5 mb-2 t-size-3 t-sm-size-5">
            Tener un cupo de crédito rotativo es igual a tener dinero extra siempre a la mano. Tú aplicas. Nosotros te aprobamos. Y a partir de ahí tienes dinero cada vez que lo necesites.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .fold-1 {
    background-attachment: fixed;
  }</style>
