<script>
  import Images from '../../../../components/Images.svelte';
  import '@zinobe-co/components/swiper';
  import { goto } from '@roxi/routify';
  import { mdiChevronRight, mdiCardsHeart } from '@mdi/js';
  import { environment } from '../../../../environment.js';

  const swipperItems = [
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/user.svg')}" />
        <p>
          <strong>
            Comienza en Lineru
          </strong>
        </p>
        <p>
          Inicia sesión para ver tu monto <br>a pagar.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/computador.svg')}" />
        <p>
          <strong>
            Elige “Pagar con Nequi”
          </strong>
        </p>
        <p>
          Completa tu pago en línea con <br>Nequi usando Cobre.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/qr.svg')}" />
        <p>
          <strong>
            Escanea el código QR desde Nequi
          </strong>
        </p>
        <p>
          Recibirás una notificación en <br>tu app para pagar.
        </p>
      `,
    },
    {
      content: `
        <img width="48" height="48" src="${resolveRoute('/images/iconos/marca/ok-hand.svg')}" />
        <p>
          <strong>
            Realiza y confirma el pago.
          </strong>
        </p>
        <p>
          ¡Listo! Verás tu transacción <br>exitosa.
        </p>
        <p>
          <a class="btn" href="/iniciar-sesion/?redirect='/mi-cuenta/pagar-credito'">
            Ir a pagar por Nequi
          </a>
        </p>
      `,
    }
  ];

  function goToPayLoanBancolombia() {
    $goto('/iniciar-sesion/?redirect=\'/mi-cuenta/pagar-credito\'')
  }

  function resolveRoute(url) {
    return environment.assets + url;
  }
</script>

<div class="d-flex justify-content-center">
  <div class="w-12/12 w-sm-11/12 pt-12 pb-6 py-md-18 mt-8">
  <div class="container">
    <div class="d-flex mb-4">
      <div class="tag">
        <svg class="w-5 h-auto -mr-3 t-error-300" height="100%" viewBox="0 0 24 24">
          <path fill="currentColor" d={mdiCardsHeart} />
        </svg>
        <p class="t-size-2 mb-0 ml-2">Nuevo en Lineru</p>
      </div>
    </div>
    <h2 class="mb-12">
      Pago por Nequi:
      <br class="d-xl-inline">
      Paga escaneando el código QR desde tu app
    </h2>

    <div class="d-none d-sm-grid grid-12 gap-6">
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-right" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/user.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Comienza en <br>
          Lineru
        </h3>
        <p class="mb-0">
          Inicia sesión para ver tu monto <br>a pagar.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-down" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/computador.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Elige “Pagar con <br>
          Nequi
        </h3>
        <p class="mb-0">
          Completa tu pago en línea con <br>
          Nequi usando Cobre.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-up" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/qr.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Escanea el código <br>
          QR desde Nequi
        </h3>
        <p class="mb-0">
          Recibirás una notificación en <br>tu app para pagar.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-3 t-left mb-2 mb-sm-0">
        <div class="d-inline-block mb-5" data-aos="fade-left" data-aos-duration="500">
          <Images imgClass="w-12 h-12 lazyload" src='/images/iconos/marca/ok-hand.svg' aria-hidden="true" alt=""/>
        </div>
        <h3 class="t-h6 mb-3">
          Realiza y <br>
          confirma el pago.
        </h3>
        <p class="mb-2">
          ¡Listo! Verás tu transacción <br>exitosa.
        </p>
        <a
          class="btn bgr-transparent t-primary-500 t-brand-2:hover pl-0 pr-5 t-left"
          href="/iniciar-sesion/?redirect='/mi-cuenta/pagar-credito'"
          on:click|preventDefault={goToPayLoanBancolombia}
          role="button"
        >
          Ir a pagar por Nequi
          <svg class="w-5 h-auto -mr-3 t-primary-500 -mb-1" height="100%" viewBox="0 0 24 24">
            <path fill="currentColor" d={mdiChevronRight} />
          </svg>
        </a>
      </div>
    </div>

    <znb-swiper class="d-block d-sm-none" items={swipperItems} />
    </div>
  </div>
</div>

<style>
  .tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 10px;
    width: 146px;
    height: 28px;
    background: #FFE079;
    border-radius: 4px;
  }</style>
