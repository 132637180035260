<script>
  import { onMount } from 'svelte';
  import LineruTagsService from '../../services/lineru-tags';
  import GoogleTagManagerService from '../../services/tag-manager';
  import { goto } from '@roxi/routify';


  let seoArticles = [];

  onMount(async () => {
    if (routify.inBrowser && window.innerWidth > 768) {
      const result = await fetch('/data/seo-articles.json', {
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      });
      seoArticles = await result.json();
    }
  });

  function goToSeoArticles(url, pageName) {
    $goto('/' + url);
    GoogleTagManagerService.setTag(LineruTagsService.home().seoLinks(pageName))
  };

  function getPageNameFromUrl(url) {
    return url.replace(/-/g, ' ');
  }

</script>

<div class="fold-8 z-0 d-none d-lg-block">
  <div class="container fold-8__container z-10 t-center py-10 py-md-14 py-lg-24">
    <h2 class="mb-6">
      Todo sobre los créditos
      <br>
      en línea.
    </h2>
    <p class="mb-8 mb-sm-14">
      Despeja tus dudas sobre pedir dinero de inmediato
      <br>
      desde tu computador o tu celular.
    </p>
    <ul class="d-grid grid-12 gap-5 t-left t-size-2 mb-0 p-0">
      {#each seoArticles as seoArticle, i}
        <li class="col-12 col-sm-6 col-md-3 d-block mb-0" data-aos="fade-down" data-aos-duration="500">
          <a
            on:click|preventDefault={() => goToSeoArticles(seoArticle.urlPath, getPageNameFromUrl(seoArticle.urlPath))}
            href="/"
            class="d-inline-block t-weight-bold">
            {seoArticle.title}
          </a>
        </li>
      {/each}
    </ul>
  </div>
</div>

<style>
  .fold-8 {
    position: relative;
  }

  .fold-8__container {
    position: relative;
  }</style>
